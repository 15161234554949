import ApiService from "./api.service";

const StaffService = {
  async staffOverview(id) {
    try {
      let response = await ApiService.get(`staff/users/${id}/overview`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async staffPoints(id) {
    try {
      let response = await ApiService.get(`staff/users/${id}/points`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async staffKickbacks(id) {
    try {
      let response = await ApiService.get(`staff/users/${id}/kickbacks`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async staffLastImport(id) {
    try {
      let response = await ApiService.get(`staff/users/${id}/lastImport`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async staffSearch(query, page = 0) {
    try {
      let search = query ? query : "no-query";
      let response = await ApiService.get(
        `staff/users/search/${search}?page=${page}`
      );
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async staffTyres(id) {
    try {
      let response = await ApiService.get(`staff/users/${id}/tyres`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async vouchers() {
    try {
      let response = await ApiService.get(`staff/vouchers`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async voucherInfo(code) {
    try {
      let response = await ApiService.get(`voucher-info?code=${code}`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },
};

export { StaffService };
