import ApiService from "./api.service";

const BTrainingService = {
  async trainings() {
    try {
      return await ApiService.get("btrainings?withDetail=1");
    } catch (error) {
      console.log(error);
    }
  },

  async training(id) {
    try {
      return await ApiService.get("btrainings/" + id + "/show/langs");
    } catch (error) {
      console.log(error);
    }
  },

  async bTrainingTypes() {
    try {
      return await ApiService.get("bTrainingTypes");
    } catch (error) {
      return error.response;
    }
  },

  async bTrainingCosts() {
    try {
      return await ApiService.get("bTrainingCosts");
    } catch (error) {
      return error.response;
    }
  },

  async bTrainingDetails(trainingId) {
    try {
      return await ApiService.get(`btrainings/details/langs/${trainingId}`);
    } catch (error) {
      return error.response;
    }
  },

  async storeResult(data) {
    try {
      return await ApiService.post("btrainings/result", data);
    } catch (e) {
      console.log(e);
    }
  },

  async doneTraining(data) {
    try {
      return await ApiService.post("btrainings/done", data);
    } catch (error) {
      console.log(error);
    }
  },
};

export { BTrainingService };
