<template>
  <MDBRow>
    <MDBCol cols="12" class="mb-4">
      {{ $t("externeTraining.additionalInfo") }}
    </MDBCol>
  </MDBRow>
  <MDBRow>
    <MDBCol cols="12" class="mb-4">{{ $t("externeTraining.itsEasy") }}</MDBCol>
  </MDBRow>
  <MDBRow>
    <MDBCol cols="12" md="5"
      ><p>
        {{ $t("externeTraining.info.paragraph1") }}
      </p>
      <p>{{ $t("externeTraining.info.paragraph2") }}</p>
      <p>{{ $t("externeTraining.info.paragraph3") }}:</p>
      <ul>
        <li>{{ $t("externeTraining.info.elements.first") }}</li>
        <li>
          {{ $t("externeTraining.info.elements.second") }}
        </li>
        <li>
          {{ $t("externeTraining.info.elements.third") }}
        </li>
        <li>{{ $t("externeTraining.info.elements.fourth") }}</li>
        <li>{{ $t("externeTraining.info.elements.fifth") }}.</li>
      </ul>
      <p class="fw-bold">
        {{ $t("externeTraining.excludeText") }}
      </p></MDBCol
    >
    <Register v-if="!isWholesaler && !isWholesalerStaff" />
    <MDBCol cols="12" md="2"
      ><p class="fw-bold">
        {{ $t("externeTraining.downloadSection.title") }}
      </p>
      <p>
        {{ $t("externeTraining.downloadSection.description1") }}
      </p>
      <p>{{ $t("externeTraining.downloadSection.description2") }}</p>
      <div class="pt-3">
        <button
          type="button"
          class="btn btn-danger text-uppercase"
          @click.prevent="openExampleSheet()"
        >
          {{ $t("externeTraining.downloadSection.title") }}
        </button>
      </div></MDBCol
    >
  </MDBRow>
</template>

<script setup>
import Register from "@/components/Trainings/Externe/Form/Register.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();

store.dispatch("me");

const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const user = computed(() => store.getters.getMe);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
});

const openExampleSheet = () => {
  window.open(`${window.location.origin}/files/Rechnungsvorlage.pdf`, "_blank");
};
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
</style>
