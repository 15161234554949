<template>
  <div class="p-4" :class="`${props.border ? 'wrapper' : ''}  `">
    <h4 class="mb-3" v-if="props.title">{{ props.title }}</h4>
    <template v-if="hasSlot('search')">
      <MDBRow>
        <MDBCol cols="12" md="6">
          <div
            v-if="props.description"
            class="description"
            v-html="props.description"
          ></div>
        </MDBCol>
        <MDBCol cols="12" md="6">
          <slot name="search"></slot>
        </MDBCol>
      </MDBRow>
    </template>
    <template v-else>
      <div
        v-if="props.description"
        class="description"
        v-html="props.description"
      ></div>
    </template>
    <template v-if="hasSlot('table')">
      <div class="table">
        <slot name="table"></slot>
      </div>
    </template>
    <template v-if="hasSlot('content')">
      <div class="content">
        <slot name="content"></slot>
      </div>
    </template>
  </div>
</template>

<script setup>
import { MDBCol, MDBRow } from "mdb-vue-ui-kit";

import { defineProps, useSlots } from "vue";

const slots = useSlots();

const props = defineProps({
  title: String,
  description: String,
  border: {
    type: Boolean,
    default: true,
  },
});

const hasSlot = (name) => {
  return !!slots[name];
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/dashboard_card.scss";
</style>
