import ApiService from "./api.service";
import TokenService from "./storage.service";

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UserService = {
  async me() {
    try {
      const response = await ApiService.get("auth/me");
      TokenService.saveUserId(response.data.data.plainId);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  async userData(hashId) {
    try {
      return await ApiService.get(`users/${hashId}?with=profile,address`);
    } catch (e) {
      console.log(e);
    }
  },

  async getUserAddresses(id) {
    try {
      return await ApiService.get(`addresses/${id}`);
    } catch (e) {
      console.error(e);
    }
  },

  async updateUserData(hashId, data) {
    try {
      return await ApiService.put(`users/${hashId}`, data);
    } catch (e) {
      return e.response;
    }
  },

  async bankStatement(data) {
    try {
      return await ApiService.get(
        `user/bank_statement/${data.page ?? 0}?${data.query ?? ""}`
      );
    } catch (e) {
      console.log(e);
    }
  },

  async lastBankStatement() {
    try {
      return await ApiService.get("user/bank/last");
    } catch (e) {
      console.log(e);
    }
  },

  async logout() {
    try {
      const response = await ApiService.post("users/logout");
      TokenService.removeToken();
      TokenService.removeRefreshToken();
      TokenService.removeUserHashId();
      ApiService.removeHeader();

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async userPromotions() {
    try {
      return await ApiService.get("users-promotions/show");
    } catch (e) {
      console.error(e);
    }
  },

  async saveUserPromotions(data) {
    try {
      return await ApiService.post("users-promotions", data);
    } catch (e) {
      console.error(e);
    }
  },

  async updateUserIntro(hashId, data) {
    try {
      return await ApiService.put(`update/intro/${hashId}`, data);
    } catch (e) {
      return e.response;
    }
  },

  async cooperations() {
    try {
      let response = await ApiService.get(`groups`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async saveCustomerNumber(data) {
    try {
      return await ApiService.post("dealer-save-customer-nr", data);
    } catch (error) {
      return error.response;
    }
  },

  async getMenu() {
    try {
      return await ApiService.get(`menus/frontend`);
    } catch (e) {
      console.log(e);
    }
  },

  async exchangeKickbacks(data) {
    try {
      return await ApiService.post("exchange-kickbacks", data);
    } catch (error) {
      return error.response;
    }
  },

  async voucherUse(data) {
    try {
      return await ApiService.post(`voucher-use`, data);
    } catch (error) {
      return error.response;
    }
  },
};

export { UserService, AuthenticationError };
