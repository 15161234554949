import ApiService from "./api.service";

const OrderService = {
  async orders(data) {
    try {
      return await ApiService.get(`user/orders${data ? data : ""}`);
    } catch (e) {
      console.log(e);
    }
  },
  async order(id) {
    try {
      return await ApiService.get(`user/orders/${id}`);
    } catch (error) {
      console.log(error);
    }
  },

  async orderProducts() {
    try {
      return await ApiService.get(`user/order/products`);
    } catch (e) {
      console.log(e);
    }
  },

  async storeAddress(data) {
    try {
      return await ApiService.post("addresses", data);
    } catch (error) {
      return error.response;
    }
  },

  async createOrder(data) {
    try {
      return await ApiService.post("orders", data);
    } catch (e) {
      return e.response;
    }
  },

  async addItem(productData) {
    try {
      if (productData?.token) {
        return await ApiService.put(
          "cart/item/" + productData.token,
          productData
        );
      }
      return await ApiService.put("cart/item", productData);
    } catch (e) {
      console.log(e);
    }
  },

  async deleteCartItem(token) {
    try {
      return await ApiService.delete("cart/item/" + token);
    } catch (e) {
      console.log(e);
    }
  },

  async updateItemQty(data) {
    try {
      return await ApiService.put("cart/quantity/update", data);
    } catch (e) {
      console.error(e);
    }
  },

  async storeUberall(data) {
    try {
      return await ApiService.post("uberall", data);
    } catch (error) {
      return error.response;
    }
  },

  async getProductBySlug(slug) {
    try {
      return await ApiService.get(`products/${slug}/slug`);
    } catch (error) {
      return error.response;
    }
  },

  async fetchUserOpenCart(userId) {
    try {
      return await ApiService.get(`cart/open/${userId}`);
    } catch (error) {
      return error;
    }
  },
};
export { OrderService };
