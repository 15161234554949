<template>
  <div>
    <MDBNavbar expand="lg" class="categories">
      <MDBNavbarNav id="mainCategoriesNavbar" collapse="mainCategoriesNavbar"
        class="mb-2 mb-lg-0 align-items-stretch flex-wrap">
        <template v-if="mainCategories.length > 0">
          <MDBNavbarItem v-for="(category, i) in mainCategories" :key="`main_category_${i}`"
            class="text-center categories-link-wrap mb-2">
            <span class="cursor--pointer font-12 categories-link d-flex align-items-center"
              :class="isActive(category.plainId)" @click="goToCategory(category)" v-html="category.name">
            </span>
          </MDBNavbarItem>
        </template>
      </MDBNavbarNav>
    </MDBNavbar>
    <div v-if="mainCategory" class="main-category fw-bold">
      <span v-html="mainCategory.name"></span>
    </div>
  </div>
</template>

<script setup>
import { MDBNavbar, MDBNavbarNav, MDBNavbarItem } from "mdb-vue-ui-kit";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import TokenService from "@/services/storage.service";

const store = useStore();
const route = useRoute();
const router = useRouter();

const mainCategories = computed(() => {
  return store.state.categories.filter((item) => !item.parent_id);
});

const isActive = (id) => {
  let category = store.state.categories.find(
    (item) => item.id == route.params.id
  );

  return category.parent_id == id || category.plainId == id ? "active" : "";
};

const mainCategory = computed(() => {
  let category = store.state.categories.find(
    (item) => item.id == route.params.id
  );

  let main = mainCategories.value.find(
    (item) => item.plainId == category.parent_id
  );

  return main ? main : category;
});

const goToCategory = (category) => {
  TokenService.setProductPage(1)

  if (category.link) {
    window.location.href = window.location.origin + category.link;
  } else {
    router.push({ name: 'shopCategory', params: { id: category.id }, query: { page: 1 } })
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";

.categories {
  padding-top: 0;
  box-shadow: 0 0 0 0 black;
}

.categories-link {
  background: $secondaryGray;
  padding: 10px;
  margin-right: 5px;
  min-height: 100%;
  line-height: 140%;
}

:deep(.categories-link.router-link-active),
.active {
  background: black;
  color: white;
  font-weight: bold;
}

.main-category {
  margin-top: 25px;
  margin-bottom: 23px;
  font-size: 20px;
}
</style>
