<template>
  <MDBContainer class="p-0">
    <Loader :show="loading" v-if="loading" />
    <MDBCard v-else>
      <MDBCardBody>
        <MDBCardTitle>{{ $t("pageName.warenkorb") }}</MDBCardTitle>
        <MDBCardText>
          <div class="mt-5" v-if="cart?.items?.length > 0">
            <MDBTable>
              <thead>
                <tr>
                  <th scope="col">{{ $t("product") }}</th>
                  <th scope="col">{{ $t("quantity") }}</th>
                  <th scope="col">{{ $t("attributes") }}</th>
                  <th scope="col">{{ $t("shop.cart.pointsPerUnit") }}</th>
                  <th scope="col">{{ $t("shop.cart.pointsSum") }}</th>
                  <th scope="col">{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in cart.items" :key="`product_${i}`">
                  <th scope="row">
                    <router-link :to="{
                      name: 'productDetails',
                      params: {
                        id: item.product.id,
                      },
                    }">
                      {{ item.product.name }}
                    </router-link>
                  </th>
                  <th scope="row">
                    <NumberInput label="" :step="1" :min="1" :max="maxQuantity(item)" v-model="item.quantity"
                      @update:modelValue="
                        changeQuantity(item.quantity, item.id)
                        ">
                    </NumberInput>
                  </th>
                  <th scope="row">
                    {{ showAttributes(item) }}
                  </th>
                  <th scope="row">{{ showPoints(item) }}</th>
                  <th scope="row">{{ item.points }}</th>
                  <th scope="row">
                    <MDBBtn outline="danger" rounded @click.prevent="deleteItem(item.token)">{{ $t("clear") }}</MDBBtn>
                  </th>
                </tr>
                <tr>
                  <th scope="row" colspan="4"></th>
                  <th scope="row" colspan="3">
                    <p>
                      <strong>{{ cart.total }}</strong>
                      {{ $t("shop.pointsTotal") }}
                    </p>
                    <p>
                      <strong>{{ user.points - cart.total }} </strong>
                      {{ $t("shop.cartRemainPoints") }}
                    </p>
                  </th>
                </tr>
              </tbody>
            </MDBTable>
          </div>
          <div v-else>
            {{ $t("shop.emptyCart") }}
            <router-link :to="{ name: 'userOrders' }">
              {{ $t("pageName.userOrders") }} </router-link>.
          </div>
        </MDBCardText>
      </MDBCardBody>
      <MDBCardFooter class="text-muted" v-if="cart?.items?.length > 0">
        <MDBBtn color="success" @click="toOrderDelivery" :disabled="!overPoints">{{ $t("shop.toOrderDelivery") }}
        </MDBBtn>
        <MDBBtn color="primary" @click="back()">{{ $t("back") }}</MDBBtn>
      </MDBCardFooter>
    </MDBCard>
  </MDBContainer>
</template>

<script setup>
import Loader from "@/components/General/Loader/index.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBCardFooter,
  MDBTable,
} from "mdb-vue-ui-kit";

import NumberInput from "@/components/Shared/NumberInput.vue";
import { onMounted, computed, ref } from "vue";

const store = useStore();
const router = useRouter();
const timeout = ref(null);
const loading = ref(true)

const cart = computed(() => store.getters.getCart);
const user = computed(() => store.getters.getMe);

const maxQuantity = (product) => {
  let points = product.product_variant
    ? product.product_variant.points
    : product.points;

  return Math.trunc(
    (user.value.points || 0 - (cart.value.total || 0)) / points
  );
};

const overPoints = computed(() => {
  return user.value.points >= cart.value.total;
});

const toOrderDelivery = () => {
  router.push({
    name: "orderDelivery",
  });
};

const showPoints = (product) => {
  return product.product_variant
    ? product.product_variant.points
    : product.product.points;
};

const back = () => {
  router.go(-1);
};

const showAttributes = (product) => {
  if (product.product_variant) {
    if (product.product_variant.title) {
      return product.product_variant.title
    } else {
      return JSON.parse(product.product_variant.attribute_option)
        .map((item) => `${item.label}: ${item.option}`)
        .join(", ")
    }
  } else {
    return "-"
  }
};

const deleteItem = async (token) => {
  await store.dispatch("deleteItem", token);
};

onMounted(async () => {
  loading.value = true
  await store.dispatch("fetchUserShopCart");
  loading.value = false
});

const changeQuantity = async (quantity, itemId) => {
  clearTimeout(timeout.value);

  timeout.value = setTimeout(async () => {
    const data = {
      qty: quantity,
      id: itemId,
    };

    await store.dispatch("updateQuantity", data);
    await store.dispatch("fetchUserShopCart");
  }, 1000);
};
</script>
