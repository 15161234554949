<template>
  <MDBCard class="container-height" :class="[
    `${user?.commitment_letter_accept && user?.intro == 1 ? 'intro__step' : ''
    }`,
  ]">
    <MDBCardBody>
      <MDBCardTitle class="title text-uppercase title-slash font-12 font-md-25">
        {{ $t("tyreBox.title") }} status
      </MDBCardTitle>
      <MDBCardText class="font-12">
        <div>
          <b>{{ $t("tyreBox.totalAmount") }}:</b>&nbsp;
          <span class="success-green fw-bold">
            {{ user?.commitment_letter_accept &&
              tyresAmount ? tyresAmount["13_16"] +
              tyresAmount["17_18"] +
            tyresAmount["19_"] : 0 }}
            <span class="text-lowercase">{{ $t("tyreBox.title") }}</span></span>
        </div>
        <div v-if="tyresAmount">
          <div>
            <b>13-16":</b>
            {{ user?.commitment_letter_accept ? tyresAmount["13_16"] : 0
            }}<br />
            <b>17-18":</b>
            {{ user?.commitment_letter_accept ? tyresAmount["17_18"] : 0
            }}<br />
            <b>19"+:</b>
            {{ user?.commitment_letter_accept ? tyresAmount["19_"] : 0 }}
          </div>
        </div>
        <div v-else>
          <div>
            <b>13-16":</b> 0<br />
            <b>17-18":</b> 0<br />
            <b>19"+:</b> 0
          </div>
        </div>
        <div class="pt-0">
          <b>{{ $t("tyreBox.target") }}:</b> {{ shopLimit?.year_target }}
        </div>
        <div class="pt-0">
          <b>{{ $t("tyreBox.entry") }}:</b> {{ shopLimit?.limit }}
          <span class="text-lowercase">{{ $t("tyreBox.title") }}</span>
        </div>
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>
</template>

<script setup>
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed, onMounted } from "vue";

const store = useStore();

const user = computed(() => store.getters.getMe);

const shopLimit = computed(() => store.getters.getTyreLimit);
const tyresAmount = computed(() => user?.value.specified_tyres_amount);

onMounted(async () => {
  const currentYear = new Date().getFullYear()
  await store.dispatch('fetchTyreLimit', currentYear)
})
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/welcome_box.scss";
@import "@/styles/mailbox.scss";

.title {
  position: relative;
}

.container-height {
  height: 50%;
  min-height: 200px;
  max-height: 300px;
}
</style>
