<template>
  <MDBRow v-if="newInvestment">
    <MDBCol cols="12" md="5"
      ><p>
        {{ $t("externeTraining.itsEasy") }}
      </p>
      <p>{{ $t("investment.info.paragraph2") }}</p>
      <p>{{ $t("investment.info.paragraph3") }}:</p>
      <ul>
        <li>{{ $t("externeTraining.info.elements.first") }}</li>
        <li>
          {{ $t("externeTraining.info.elements.second") }}
        </li>
        <li>
          {{ $t("externeTraining.info.elements.third") }}
        </li>
        <li>{{ $t("externeTraining.info.elements.fourth") }}</li>
        <li>
          {{ $t("externeTraining.info.elements.fifth") }}
          <router-link :to="{ name: 'pointsOverview' }">
            {{ $t("myAccount") }}.</router-link
          >
        </li>
      </ul>
      <p class="fw-bold">
        {{ $t("investment.excludeText") }}
      </p></MDBCol
    >
    <Register v-if="!isWholesaler" />
    <MDBCol cols="12" md="2"
      ><p class="fw-bold">
        {{ $t("externeTraining.downloadSection.title") }}
      </p>
      <p>
        {{ $t("externeTraining.downloadSection.description1") }}
      </p>
      <p>{{ $t("externeTraining.downloadSection.description2") }}</p>
      <div class="pt-3">
        <button
          type="button"
          class="btn btn-danger text-uppercase"
          @click.prevent="openExampleSheet()"
        >
          {{ $t("externeTraining.downloadSection.title") }}
        </button>
      </div></MDBCol
    >
  </MDBRow>
  <MDBRow v-else>
    <MDBCol cols="12" sm="12">
      <p>
        {{ $t("investment.info.paragraph2_old") }}
      </p>
      <p class="fw-bold">
        {{ $t("investment.info.paragraph3_old") }}
        <span
          @click.prevent="openExampleSheet()"
          class="cursor--pointer text-primary"
          >{{ $t("investment.here") }}</span
        >.
      </p>
      <p class="fw-bold text-danger">
        {{ $t("investment.info.paragraph4_old") }}
      </p>
      <p>
        {{ $t("productInfo.categories.contact") }}:<br />
        TAKTZEIT GmbH<br />
        {{ $t("investment.contactPerson") }} Kirsten Nelke <br />
        {{ $t("investment.email") }}:
        <a href="mailto:kirsten.nelke@taktzeit.com"
          >kirsten.nelke@taktzeit.com</a
        ><br />
        {{ $t("investment.phone") }}.: +49 (0) 211 36 11 789 10
      </p>
      <p class="fw-bold text-danger text-uppercase">
        {{ $t("investment.payInfo") }}
      </p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import Register from "@/components/InvestmentSupport/Form/Register.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();

store.dispatch("me");

const isWholesaler = ref(false);
const user = computed(() => store.getters.getMe);
const newInvestment = computed(() => store.getters.investmentSupportNew);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
});

const openExampleSheet = () => {
  if (newInvestment.value) {
    window.open(
      `${window.location.origin}/files/Rechnungsvorlage.pdf`,
      "_blank"
    );
  } else {
    window.open(
      `${window.location.origin}/files/Musterrechnung_IU_WKZ.pdf`,
      "_blank"
    );
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
</style>
