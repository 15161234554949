import { UserService } from "../services/user.service";
import { TrainingService } from "../services/training.service";
import { BTrainingService } from "../services/btraining.service";
import { ExternalTrainingService } from "../services/externalTraining.service";
import { TextPageService } from "../services/textPage.service";
import { ContactService } from "../services/contact.service";
import { OrderService } from "../services/order.service";
import TokenService from "../services/storage.service";
import { PriceService } from "../services/price.service";
import { ProfileService } from "../services/profile.service";
import { MessageService } from "../services/message.service";
import { MediaService } from "../services/media.service";
import { WholesalerService } from "../services/wholesaler.service";
import { ProductService } from "../services/product.service";
import { SliderService } from "../services/slider.service";
import { HunterService } from "../services/hunter.service";
import { PromotionService } from "../services/promotion.service";
import ApiService from "../services/api.service";
import router from "@/router";
import i18n from "@/plugins/i18n";

const actions = {
  async userB2CLogin(_, params) {
    await ApiService.csrfCookie();

    if (!params.token || !params.userId) {
      await router.push({ name: "login" });
    }

    TokenService.saveToken(params.token);
    TokenService.saveUserHashId(params.userId);
    ApiService.setHeader();
    await router.push({ name: "dashboard" });
  },

  async logout() {
    try {
      const response = await ApiService.post("users/logout");
      TokenService.removeToken();
      TokenService.removeRefreshToken();
      ApiService.removeHeader();

      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        router.push({ name: "login" });
      }
    } catch (error) {
      console.log(error);
      router.push({ name: "login" });
    }
  },

  updateUser(_, userData) {
    return new Promise((resolve, reject) => {
      UserService.updateUserData(userData.id, userData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  updateIntro(_, userData) {
    return new Promise((resolve, reject) => {
      UserService.updateUserIntro(userData.id, userData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  async me({ commit }) {
    try {
      const response = await UserService.me();
      commit("SET_USER", response.data.data);
      return response;
    } catch (err) {
      router.push("/");
    }
  },

  async fetchProfile({ commit }) {
    try {
      let hashId = TokenService.getUserHashId();
      const response = await ProfileService.userProfileData(hashId);
      commit("SET_PROFILE", response.data.data);
    } catch (err) {
      console.log(err);
      router.push("/");
    }
  },

  async bankStatement({ commit }, data) {
    try {
      const response = await UserService.bankStatement(data);

      commit("SET_BANK_STATEMENT", response.data);
    } catch (err) {
      router.push("/");
    }
  },

  async wshBankStatement({ commit }, data) {
    try {
      const response = await WholesalerService.wshBankStatement(data);

      commit("SET_BANK_STATEMENT", response.data);
    } catch (err) {
      router.push("/");
    }
  },

  async lastBankStatement({ commit }) {
    try {
      const response = await UserService.lastBankStatement();
      commit("SET_LAST_BANK_STATEMENT", response.data);
    } catch (err) {
      router.push("/");
    }
  },

  async loadUserAddresses({ commit, state }) {
    try {
      const response = await UserService.getUserAddresses(state.user.id);
      commit("SET_ADDRESSES", response.data);
    } catch (err) {
      console.error(err);
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeAddress(_, data) {
    try {
      const response = await OrderService.storeAddress(data);

      if (response.status === 200) {
        return true;
      }
    } catch (err) {
      router.push("/");
    }
  },

  async loadCategories({ commit }) {
    try {
      commit("SET_CATEGORIES_REQUEST_SENT", true);
      const response = await ProductService.categories();
      commit("SET_CATEGORIES", response.data.data);
      commit("SET_CATEGORIES_REQUEST_SENT", false);
    } catch (err) {
      router.push("/");
    }
  },

  async loadJobPositions({ commit }) {
    try {
      const response = await ProfileService.jobPositions();
      commit("SET_JOB_POSITIONS", response.data.data);
    } catch (error) {
      router.push("/");
    }
  },

  async loadTyrePositions({ commit }) {
    try {
      const response = await ContactService.tyrePositions();
      commit("SET_TYRE_POSITIONS", response.data.data);
    } catch (error) {
      router.push("/");
    }
  },

  async loadTrainings({ commit }) {
    try {
      const response = await TrainingService.trainings();
      commit("SET_TRAININGS", response.data.data);
    } catch (error) {
      router.push("/");
    }
  },

  async loadPrices({ commit }) {
    try {
      const response = await PriceService.prices();
      commit("SET_PRICES", response.data.data);
    } catch (error) {
      router.push("/");
    }
  },

  async loadSinglePriceList({ commit }, slug) {
    try {
      const response = await PriceService.singlePriceList(slug);
      commit("SET_SINGLE_PRICE_LIST", response.data.data);
    } catch (error) {
      router.push("/");
    }
  },

  async loadPriceCategories({ commit }) {
    try {
      const response = await PriceService.priceCategories();
      commit("SET_PRICE_CATEGORIES", response.data.data);
    } catch (error) {
      router.push("/");
    }
  },

  async loadProducts({ dispatch, commit }, page) {
    try {
      dispatch("addPending", true);
      commit("SET_PRODUCT_REQUEST_SENT", true);
      const response = await ProductService.products(page);
      commit("SET_PRODUCTS", response.data.data);
      commit("SET_PRODUCTS_META", response.data.meta);
      commit("SET_PRODUCT_REQUEST_SENT", false);
    } catch (err) {
      router.push("/");
    }
  },

  async loadBestSoldProducts({ commit }) {
    try {
      const response = await ProductService.bestSoldProducts();
      if (response.status == 200) {
        commit("SET_BEST_SOLD_PRODUCTS", response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async loadSeasonalProducts({ commit }) {
    try {
      const response = await ProductService.seasonalProducts();
      if (response.status == 200) {
        commit("SET_SEASONAL_PRODUCTS", response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async loadSoonAffordableProducts({ commit }) {
    try {
      const response = await ProductService.soonAffordableProducts();
      if (response.status == 200) {
        commit("SET_SOON_AFFORDABLE_PRODUCTS", response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async addItem({ getters }, data) {
    try {
      const token = getters.getCart?.token;
      const product = { ...data, token };

      await OrderService.addItem(product);
    } catch (err) {
      console.log(err);
    }
  },

  async updateQuantity(_, data) {
    try {
      await OrderService.updateItemQty(data);
    } catch (err) {
      console.log(err);
    }
  },

  async loadProductById({ commit }, id) {
    try {
      const response = await ProductService.product(id);
      commit("SET_PRODUCT", response.data.data);
    } catch (err) {
      router.push("/");
    }
  },

  async loadCategoryProduct({ commit }, data) {
    try {
      commit("SET_PRODUCT_REQUEST_SENT", true);
      const response = await ProductService.categoryProducts(
        data.id,
        data.pageNumber,
        data.sort,
        data.perPage
      );
      commit("SET_PRODUCTS", response.data.data);
      commit("SET_PRODUCTS_META", response.data.meta);
      commit("SET_PRODUCT_REQUEST_SENT", false);
    } catch (err) {
      router.push("/");
    }
  },

  setAttributeOption({ commit }, attributeOption) {
    commit("SET_ATTRIBUTE_OPTIONS", attributeOption);
  },

  setAddressSelected({ commit }, data) {
    commit("SET_ADDRESS_SELECTED", data);
  },

  async deleteItem({ dispatch }, token) {
    try {
      await OrderService.deleteCartItem(token);
      dispatch("me");
      dispatch("fetchUserShopCart");
    } catch (err) {
      router.push("/");
    }
  },

  // eslint-disable-next-line no-unused-vars
  async createOrder({ commit, dispatch }, data) {
    try {
      const response = await OrderService.createOrder(data);

      if (response.status === 200) {
        await dispatch("me");
        await dispatch("loadUnreadMessages");
        await dispatch("fetchUserShopCart");
      }
      return response;
    } catch (err) {
      return err.data;
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeResult(_, data) {
    try {
      const response = await TrainingService.storeResult(data);

      if (response.status === 200) {
        return true;
      }
    } catch (err) {
      router.push("/");
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeBResult(_, data) {
    try {
      const response = await BTrainingService.storeResult(data);

      if (response.status === 200) {
        return true;
      }
    } catch (err) {
      router.push("/");
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeExternalTraining({ dispatch }, data) {
    try {
      const response = await ExternalTrainingService.store(data);

      if (response.status === 200) {
        await dispatch("loadUnreadMessages");
        return response;
      } else {
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeHunterProduct(_, data) {
    try {
      const response = await HunterService.storeHunter(data);

      if (response.status === 200) {
        return response;
      } else {
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  },

  async loadHunterById({ commit }, id) {
    try {
      const response = await HunterService.hunter(id);
      commit("SET_SINGLE_HUNTER", response.data.data);
    } catch (err) {
      router.push("/");
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeWatchedVideo(_, data) {
    try {
      const response = await TrainingService.storeVideoWatched(data);

      if (response.status === 200) {
        return true;
      }
    } catch (err) {
      router.push("/");
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeProfilePercentage({ dispatch }, data) {
    try {
      const response = await ProfileService.storeProfilePercentage(data);

      if (response.status === 200) {
        await dispatch("loadUseProfilePercentage");
        return true;
      }
    } catch (err) {
      router.push("/");
    }
  },

  async loadWatchedVideo({ commit }, data) {
    try {
      const response = await TrainingService.fetchWatchedVideo(data);

      commit("SET_WATCHED_VIDEO", response.data.success);
    } catch (err) {
      router.push("/");
    }
  },

  async loadTrainingResult({ commit }, data) {
    try {
      data.userId = TokenService.getUserHashId();
      const response = await TrainingService.doneTraining(data);

      commit("SET_TRAINING_RESULT", response.data.success);
      commit("SET_END_QUIZ_DATE", response.data.created_at);
    } catch (err) {
      router.push("/");
    }
  },

  clearAttributes({ state }) {
    state.attribute_options = [];
  },

  async loadQuestions({ commit }, trainingId) {
    try {
      const response = await TrainingService.questions(trainingId);
      commit("SET_QUESTIONS", response.data);
    } catch (err) {
      console.log(err);
    }
  },

  async loadTrainingById({ commit }, id) {
    const response = await TrainingService.training(id);
    commit("SET_TRAINING", response.data.data);
  },

  // eslint-disable-next-line no-unused-vars
  async generateCertificate(_) {
    try {
      const response = await TrainingService.generateCertificate();

      if (response.status === 200) {
        return true;
      }
    } catch (err) {
      router.push("/");
    }
  },

  async loadBTrainings({ commit }) {
    try {
      const response = await BTrainingService.trainings();
      commit("SET_B_TRAININGS", response.data.data);
    } catch (error) {
      router.push("/");
    }
  },

  async loadBTrainingById({ commit }, id) {
    const response = await BTrainingService.training(id);
    commit("SET_B_TRAINING", response.data.data);
  },

  async loadBTrainingDetails({ commit }, id) {
    try {
      const response = await BTrainingService.bTrainingDetails(id);
      commit("SET_B_TRAINING_DETAILS", response.data.data);
    } catch (error) {
      router.push("/");
    }
  },

  async loadBTrainingCosts({ commit }) {
    try {
      const response = await BTrainingService.bTrainingCosts();
      commit("SET_B_TRAINING_COSTS", response.data.data);
    } catch (error) {
      router.push("/");
    }
  },
  async loadBTrainingTypes({ commit }) {
    try {
      const response = await BTrainingService.bTrainingTypes();
      commit("SET_B_TRAINING_TYPES", response.data.data);
    } catch (error) {
      router.push("/");
    }
  },
  async loadBTrainingResult({ commit }, data) {
    try {
      data.userId = TokenService.getUserHashId();
      const response = await BTrainingService.doneTraining(data);

      commit("SET_B_TRAINING_RESULT", response.data);
    } catch (err) {
      router.push("/");
    }
  },

  async loadUserSlides({ commit }) {
    try {
      const userId = TokenService.getUserHashId();
      const response = await SliderService.sliders(userId);

      commit("SET_USER_SLIDES", response.data.data);
    } catch (err) {
      router.push("/");
    }
  },

  async loadUserMainBoxes({ commit }) {
    try {
      const userId = TokenService.getUserHashId();
      const response = await SliderService.mainBoxes(userId);

      commit("SET_USER_MAIN_BOXES", response.data.data);
    } catch (err) {
      router.push("/");
    }
  },

  async loadUseProfilePercentage({ commit }) {
    try {
      const userId = TokenService.getUserHashId();
      const response = await ProfileService.profilePercentage(userId);
      commit("SET_PERCENTAGE", response.data.percentage);
      TokenService.saveProfilePercentage(response.data.percentage);
    } catch (err) {
      router.push("/");
    }
  },

  async loadUserTextPages({ commit }) {
    try {
      const response = await TextPageService.textPages();

      commit("SET_TEXT_PAGES", response.data.data);
    } catch (err) {
      router.push("/");
    }
  },

  async loadUserTextPageById({ commit }, id) {
    const response = await TextPageService.textPage(id);
    commit("SET_TEXT_PAGE", response.data.data);
  },

  async loadPickupLocations({ commit }) {
    const response = await ContactService.pickupLocations();
    commit("SET_PICKUP_LOCATIONS", response.data.data);
  },

  async loadDamageTypes({ commit }) {
    const response = await ContactService.damageTypes();
    commit("SET_DAMAGE_TYPES", response.data.data);
  },

  async loadVehicleTyres({ commit }) {
    const response = await ContactService.vehicleTyres();
    commit("SET_VEHICLE_TYRES", response.data.data);
  },

  async loadTyreWears({ commit }) {
    const response = await ContactService.tyreWears();
    commit("SET_TYRE_WEARS", response.data.data);
  },

  async loadSalutations({ commit }) {
    const response = await ContactService.salutations();
    commit("SET_SALUTATIONS", response.data.data);
  },

  async loadMessages({ commit }) {
    const response = await MessageService.loadMessages();
    commit("SET_MESSAGES", response.data);
  },

  async loadUnreadMessages({ commit }) {
    const response = await MessageService.loadUnreadMessages();
    commit("SET_UNREAD_MESSAGES", response.data);
  },

  async setMessageIsRead({ dispatch }, id) {
    await MessageService.setMessageIsRead(id);
    dispatch("loadMessages");
    dispatch("loadUnreadMessages");
  },

  async deleteMessages({ dispatch }, ids) {
    await MessageService.deleteMessages(ids);
    dispatch("loadMessages");
    dispatch("loadUnreadMessages");
  },

  // eslint-disable-next-line no-unused-vars
  async storeCompliant(_, data) {
    try {
      const response = await ContactService.store(data);

      if (response.status === 200) {
        return {
          ok: true,
          complaint_id: response.data.complaint_id,
          hashid: response.data.hashid,
        };
      }
    } catch (err) {
      router.push("/");
    }
  },

  // eslint-disable-next-line no-unused-vars
  addPending({ commit }, data) {
    commit("SET_PENDING", data);
  },

  // eslint-disable-next-line no-unused-vars
  addToast({ commit }, data) {
    commit("SET_TOAST", data);
  },

  // eslint-disable-next-line no-unused-vars
  async loadUserOrders({ commit }, data) {
    const response = await OrderService.orders(data);
    commit("SET_USER_ORDERS", response.data);
  },

  // eslint-disable-next-line no-unused-vars
  async loadUserOrderProducts({ commit }) {
    const response = await OrderService.orderProducts();
    commit("SET_USER_PRODUCTS", response.data);
  },

  // eslint-disable-next-line no-unused-vars
  async loadUserOrder({ commit }, data) {
    const response = await OrderService.order(data);
    commit("SET_USER_ORDER", response.data.data);
  },

  async loadUserPromotions({ commit }) {
    try {
      const response = await UserService.userPromotions();

      if (response.status === 200) {
        console.log(response.data);
        commit("SET_USER_PROMOTIONS", response.data);
      }
    } catch (err) {
      console.log(err);
    }
  },
  async loadNewsFeeds({ commit }) {
    const response = await TextPageService.newsFeeds();
    commit("SET_NEWS_FEEDS", response.data.data);
  },

  addCurrentNewsFeed({ commit }, index) {
    commit("SET_CURRENT_NEWS_FEED", index);
  },

  async loadWholeSalers({ commit }) {
    const response = await WholesalerService.wholesalers();

    commit("SET_WHOLESALERS", response.wholesalers);
    commit("SET_ALL_WHOLESALERS", response.allWholesalers);
  },

  async loadGroups({ commit }) {
    const response = await UserService.cooperations();
    commit("SET_COOPERATIONS", response.data);
  },

  async loadKickbackInfo({ commit }, groupId) {
    const response = await ProfileService.showKickbackInfo(groupId);
    if (response.status == 200) {
      commit("SET_KICKBACK_INFO", response.data);
    }
  },

  async loadPointsByCsNumber({ commit }, data) {
    const response = await ProfileService.showPointsByCustomerNumber(data);
    commit("SET_POINTS_BY_CUSTOMER_NUMBER", response.data);
  },

  async loadCertificateTexts({ commit }, id) {
    try {
      const response = await TrainingService.certificateTexts(id);

      if (response.status == 200) {
        commit("SET_CERTIFICATE_TEXTS", response.data.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async loadMenu({ commit }) {
    try {
      const response = await UserService.getMenu();

      if (response.status == 200) {
        commit("SET_MENU", response.data.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async fetchMediaCategories({ commit }) {
    try {
      const response = await MediaService.mediaCategories();

      if (response.status == 200) {
        commit("SET_MEDIA_CATEGORIES", response.data.data);

        return response.data.data;
      }
    } catch (e) {
      console.error(e);
    }
  },

  async fetchSingleMediaList({ commit }, data) {
    try {
      const response = await MediaService.singleMedia(data);

      if (response.status == 200) {
        commit("SET_SINGLE_MEDIA", response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async fetchSingleMediaCategory({ commit }, id) {
    try {
      const response = await MediaService.singleMediaCategory(id);

      if (response.status == 200) {
        commit("SET_SINGLE_MEDIA_CATEGORY", response.data.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async fetchUserPermissions({ commit }) {
    try {
      const response = await ProfileService.userPermissions();

      if (response.status == 200) {
        TokenService.savePermissions(response.data);
        commit("SET_PERMISSIONS", response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  addAllDealersNumber({ commit }, data) {
    commit("SET_ALL_DEALERS_NUMBER", data);
  },

  async fetchWshSelect({ commit }) {
    try {
      const response = await WholesalerService.wholesalerSelect();

      if (response.status == 200) {
        commit("SET_WSH_SELECT", response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async fetchDefaultBanners({ commit }) {
    try {
      const response = await SliderService.banners();

      if (response.status == 200) {
        commit("SET_DEFAULT_BANNERS", response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  checkUserPermission({ state }, name) {
    let data = sessionStorage.getItem("permissions")
      ? sessionStorage.getItem("permissions")
      : state.permissions;

    if (data.length > 0) {
      const search = data.search(name);
      return search != -1;
    }
    return false;
  },

  async filterMediaFiles({ commit }, data) {
    try {
      const response = await MediaService.fetchFilteredMedia(data);

      if (response.status == 200) {
        commit("SET_MEDIA_FILES", response.data.data);
        commit("SET_PAGINATION_LINKS", response.data.links);
        commit("SET_TOTAL_MEDIA_FILES", response.data.total);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async filterBrochureFiles({ commit }, data) {
    try {
      const response = await PriceService.fetchFilteredBrochure(data);

      if (response.status == 200) {
        commit("SET_BROCHURE_FILES", response.data.data);
        commit("SET_PAGINATION_LINKS", response.data.meta.links);
        commit("SET_TOTAL_MEDIA_FILES", response.data.meta.total);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async fetchTyreLimit({ commit }, data) {
    try {
      const response = await ProfileService.tyreLimitYearly(data);

      if (response.status == 200) {
        commit("SET_TYRE_LIMIT", response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async fetchPromotions({ commit }) {
    try {
      const response = await PromotionService.promotions();

      if (response.status == 200) {
        const mapData = response.data.data.map((item) => {
          return {
            name: `<span class="font-sm">${item.title}</span><br/><h3 class="fw-bold text-uppercase">${item.subtitle}</h3>`,
            description: item.description,
            link: item.file,
            image: item.image,
            linkText: i18n.global.t("buttons.dealerInfoDownload"),
            title: item.title,
            subtitle: item.subtitle,
            id: item.plainId,
          };
        });
        commit("SET_PROMOTIONS", mapData);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async fetchBrochureCategories({ commit }) {
    try {
      const response = await PriceService.brochureCategories();

      if (response.status == 200) {
        commit("SET_BROCHURE_CATEGORIES", response.data.data);

        return response.data.data;
      }
    } catch (e) {
      console.error(e);
    }
  },

  async fetchUserShopCart({ commit }) {
    const userId = TokenService.getUserId();
    if (userId) {
      const response = await OrderService.fetchUserOpenCart(userId);
      commit("SET_USER_CART", response.data.data);
    }
  },

  checkMenuFetched({ commit }) {
    commit("SET_FETCH_MENU", true);
  },
  checkLeftColumnFetched({ commit }) {
    commit("SET_FETCH_LEFT_COLUMN", true);
  },
};

export { actions };
