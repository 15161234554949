<template>
  <MDBContainer class="min-100vh d-flex flex-column">
    <Menu />
    <Breadcrumbs
      :parent-routes="['shop', 'warenkorb']"
      :routeName="$t(`pageName.${route.name}`)"
      class="mb-2"
    />    
      <router-view></router-view>
    <Footer class="mt-auto"/>
  </MDBContainer>    
</template>

<script setup>
import { 
  MDBContainer 
} from "mdb-vue-ui-kit";
import Menu from '@/components/Layout/Menu';
import Footer from "@/components/Layout/Footer.vue";
import Breadcrumbs from '@/components/General/Breadcrumbs/index';
import { useRoute } from "vue-router";

const route = useRoute();

</script>

<style scoped>

</style>
