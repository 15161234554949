import ApiService from "./api.service";

const ExternalTrainingService = {
  async store(data) {
    try {
      return await ApiService.post("externalTrainings", data);
    } catch (error) {
      return error.response;
    }
  },
};

export { ExternalTrainingService };
