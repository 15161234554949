<template>
  <Loader :show="loading" v-if="loading"></Loader>
  <MDBRow v-else>
    <MDBCol
      lg="4"
      md="6"
      class="mb-4"
      v-for="(training, i) in priceCategories"
      :key="i"
    >
      <a
        @click="getUrl(training.slug)"
        class="training text-center d-flex align-items-center justify-content-center"
        :style="{ backgroundImage: `url(${training.poster})` }"
      >
      </a>
      <p v-html="training.name" class="p-4 fw-bold font-16"></p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import Loader from "@/components/General/Loader/index";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";

import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import router from "@/router";

const store = useStore();
const loading = computed(() => store.getters.getPending);

const fetchPriceCategories = async () => {
  try {
    await store.dispatch("loadPriceCategories");
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  fetchPriceCategories();
});

const getUrl = (link) => {
  router.push("/product-info/price-list/" + link);
};

const priceCategories = computed(() => store.getters.getPriceCategories);
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";

.training {
  cursor: pointer;
  background-size: cover;
  min-height: 275px;

  @media screen and (max-width: $xxlBreakpoint) {
    min-height: 185px;
  }

  @media screen and (max-width: $xlBreakpoint) {
    min-height: 153px;
  }

  @media screen and (max-width: $lgBreakpoint) {
    min-height: 240px;
  }

  @media screen and (max-width: $mdBreakpoint) {
    min-height: 368px;
  }

  @media screen and (max-width: $smBreakpoint) {
    min-height: 67vw;
  }
}
</style>
