<template>
  <CategoryTop
    :title="$t('menu.productBrochures')"
    :breadcrumbs-parent-routes="isGermany ? ['productInfo'] : ''"
    :description="$t('productBrochures.desc')"
  />
</template>

<script setup>
import { computed } from "vue";
import CategoryTop from "../../General/CategoryTop/index.vue";

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
</script>

<style scoped lang="scss">
@import "../../../styles/_variables.scss";
</style>
