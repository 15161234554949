<template>
  <CategoryTop :title="$t('pageName.userOrders')" :description="$t('order.description')"
    :breadcrumbs-parent-routes="['account']">
    <template #right>
      <DashboardCard :border="false" class="d-flex justify-content-end">
        <template v-slot:content>
          <MDBBtn color="danger" @click="goToShop">{{
            $t("buttons.newOrder")
            }}</MDBBtn>
        </template>
      </DashboardCard>
    </template>
  </CategoryTop>
  <Loader :show="loading" v-if="loading"></Loader>
  <div v-else>
    <MDBRow>
      <MDBCol col="9" offsetLg="3">
        <div class="search-box p-4">
          <div class="w-100">
            <MDBRow>
              <MDBCol col="12" md="3">
                <label for="date" class="label px-3 text-uppercase">
                  {{ $t("order.date") }}
                </label>
                <VueDatePicker v-model="date" :locale="lang" :enable-time-picker="false"
                  :cancelText="$t('buttons.cancel')" :selectText="$t('buttons.select')" @update:model-value="search"
                  :format="formatDatePicker" id="date"></VueDatePicker>
              </MDBCol>
              <MDBCol col="12" md="5">
                <label for="products" class="label px-3 text-uppercase">
                  {{ $t("order.products") }}
                </label>
                <select v-if="productList.length" v-model="selectedProduct" class="form-select mb-1" @change="search"
                  id="products">
                  <option :value="null">
                    {{ $t("profile.choose") }}
                  </option>
                  <option :value="product.id" v-for="product in productList" :key="product.id">
                    {{ product.name }}
                  </option>
                </select>

                <select v-else v-model="selectedProduct" class="form-select mb-1" @change="search" id="products">
                  <option :value="null">
                    {{ $t("profile.choose") }}
                  </option>
                </select>
              </MDBCol>
              <MDBCol col="12" md="4">
                <label for="search" class="label px-3 text-uppercase">
                  {{ $t("order.number") }}
                </label>
                <MDBInput v-model="query" :label="$t('search')" id="search" />
              </MDBCol>
            </MDBRow>
          </div>
          <span>
            <MDBBtn color="danger" @click="search">{{
              $t("buttons.toSearch")
              }}</MDBBtn>
          </span>
        </div>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol col="12" lg="8">
        <div class="pt-2">
          <MDBTable borderless class="points-overview">
            <thead>
              <tr>
                <th>{{ $t("order.date") }}</th>
                <th>{{ $t("order.number") }}</th>
                <th>Status</th>
                <th>{{ $t("order.products") }}</th>
              </tr>
            </thead>
            <tbody v-if="userOrders.length > 0">
              <tr v-for="(order, index) in userOrders" :key="index">
                <td>{{ dates.parseDate(order.order_date) }}</td>
                <td>{{ order.order_number }}</td>
                <td>{{ setStatus(order.statuses.at(-1)) }}</td>
                <td>
                  <div v-for="(item, index) in order.items" :key="index">
                    <div class="fw-bold">
                      {{ item.product.name[lang] }}
                      <template v-if="item.product_variant">
                        <span>({{
                          item.product_variant.title ? item.product_variant.title[lang] : showAttributes(
                            item.product_variant.attribute_option
                          )
                        }})</span>
                      </template>
                    </div>
                    {{ item.quantity }} {{ $t("order.quantity_short") }}
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <div>{{ $t("order.anyOrder") }}</div>
            </tbody>
          </MDBTable>
        </div>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import DashboardCard from "@/components/User/Wholesaler/DashboardCard.vue";
import { MDBRow, MDBCol, MDBBtn, MDBInput, MDBTable } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onMounted, computed, ref, watch } from "vue";
import Loader from "@/components/General/Loader/index.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import i18n from "@/plugins/i18n";
import dates from "@/plugins/dates";

const store = useStore();
const router = useRouter();
const lang = computed(() => localStorage.getItem("lang"));

const userOrders = computed(() => store.getters.getUserOrders);
const loading = computed(() => store.getters.getPending);
const userProducts = computed(() => store.getters.getUserProducts);
const selectedProduct = ref(null);
const query = ref("");
const date = ref(null);

const productList = ref([
  { id: "hunter", name: i18n.global.t("hunter.title") },
  { id: "external", name: i18n.global.t("trainings.index.external.title") },
  { id: "investment", name: i18n.global.t("investment.title") },
]);

const fetchUserOrders = async () => {
  try {
    await store.dispatch("loadUserOrders");
    await store.dispatch("loadUserOrderProducts");
  } catch (error) {
    console.log(error);
  }
};

onMounted(async () => {
  await fetchUserOrders();

  if (userProducts.value.length > 0) {
    const mappedProducts = userProducts.value.map((value) => {
      return { id: value.id, name: value.name[lang.value] };
    });

    productList.value = [...productList.value, ...mappedProducts];
  }
});

const setStatus = (data) => {
  return i18n.global.t(`order.status.${data.label.toLowerCase()}`);
};

const goToShop = () => {
  router.push({ name: "shop" });
};

const showAttributes = (attribute_option) => {
  return JSON.parse(attribute_option)
    .map((item) => `${item.label}: ${item.option}`)
    .join(", ");
};

const search = async () => {
  const params = [];
  selectedProduct.value
    ? params.push(`productId=${selectedProduct.value}`)
    : "";
  query.value ? params.push(`search=${query.value}`) : "";
  date.value ? params.push(`date=${dates.parseDate(date.value)}`) : "";

  let data = "?";
  for (const element of params) {
    data += `${element}&`;
  }

  await store.dispatch("loadUserOrders", data.replace(/.$/, ""));
};

const formatDatePicker = (date) => {
  return dates.parseDate(date);
};

watch(query, async (newVal) => {
  if (newVal.length == 0) {
    await search();
  }
});
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";

.points-overview {
  width: 100%;
  border-spacing: 3px;
  border-collapse: separate;

  td,
  th {
    padding: 5px;
    text-align: left;
  }

  th {
    background: $gray;
    color: $backgroundGray;
  }

  &--small {
    padding: 0 30px 70px 30px;
  }
}

.points-overview>tbody>tr:nth-of-type(2n + 1)>* {
  background-color: $secondaryGray;
}

:deep(.search-box) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
