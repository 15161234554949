<template>
  <div class="background-wrap">
    <div class="background"></div>
  </div>

  <MDBRow class="login-container">
    <MDBCol sm="4" offset="sm-2">
      <div
        class="d-flex justify-content-center align-items-center"
        style="height: 100vh"
      >
        <div class="h-auto w-100 text-center text-white">
          <h1 class="title">403<span class="dot">.</span></h1>
          <p class="description">{{ $t("accessDenied") }}</p>
          <p>
            <MDBBtn color="info" rounded @click="logout()">{{
              $t("loginPageSteps.step3.actionBtn")
            }}</MDBBtn>
          </p>
        </div>
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBCol, MDBRow, MDBBtn } from "mdb-vue-ui-kit";
import { useStore } from "vuex";

const store = useStore();

const logout = async () => {
  await store.dispatch("logout");
};
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/error";
</style>
