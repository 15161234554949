<template>
  <CategoryTop :title="$t('productBrochures.BDriveTitle')"
    :breadcrumbs-parent-routes="isGermany ? ['medias'] : ['productInfo']"
    :description="$t('productBrochures.BDriveDesc')" />
</template>

<script setup>
import { computed } from "vue";
import CategoryTop from "../General/CategoryTop/index.vue";

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");

</script>
