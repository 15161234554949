<template>
  <MDBRow class="align-items-center">
    <MDBCol col="" class="back" v-if="showGoBack">
      <div @click="getBackLink()" class="font-12 fw-bold text-black cursor--pointer">
        <MDBIcon class="me-1 font-10" icon="chevron-left" size="1x" />
        {{ $t("buttons.back") }}
      </div>
    </MDBCol>
    <MDBCol col="" v-if="showBreadcrumbs">
      <p class="font-10 m-0 ps-lg-2 pt-2 pt-md-0 text-uppercase text-black">
        <router-link :to="{ name: 'dashboard' }" class="text-black" v-if="!isWholesaler">{{ $t("pageName.dashboard")
          }}</router-link>
        <router-link :to="{ name: 'wholesaler' }" class="text-black" v-if="isWholesaler">{{ $t("pageName.dashboard")
          }}</router-link>
        <span class="d-inline-block mx-1">/</span>

        <span v-for="route in parentRoutes" :key="route">
          <router-link :to="getLink(route)" class="text-black"
            v-html="typeof route === 'string' ? $t('pageName.' + route) : route.title"></router-link>
          <span class="d-inline-block mx-1">/</span>
        </span>
        <span v-html="setUpLastBreadCrumb()"></span>
      </p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { computed, defineProps, watch, ref } from "vue";
import { MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";
const mediaCategory = computed(() => store.getters.getSingleMediaCategory);
const files = computed(() => store.getters.getSinglePriceList);

const store = useStore();
const route = useRoute();
const router = useRouter();

const user = computed(() => store.getters.getMe);
const isWholesaler = ref(false);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
});

const props = defineProps({
  routeName: String,
  parentRoutes: Array,
  showBreadcrumbs: {
    type: Boolean,
    default: true,
  },
  showGoBack: {
    type: Boolean,
    default: true,
  },
});

const getLink = (route) => {
  let link = { name: route };

  if (typeof route !== "string") {
    link = route;
  }

  return link;
};

const getBackLink = () => {
  window.history.length > 1 ? router.back() : router.push("/");
};

const setUpLastBreadCrumb = () => {
  if (mediaCategory.value && useRoute().name == "single_media") {
    return mediaCategory.value.name;
  } else if (files.value && useRoute().name == "pkw") {
    return i18n.global.t(`productList.${route.params.slug}.title`);
  }
  return props.routeName
    ? props.routeName
    : i18n.global.t("pageName." + useRoute().name);
};
</script>

<style scoped lang="scss">
@import "../../../styles/variables";

.back {
  max-width: 100px;

  @media screen and (max-width: $mdBreakpoint) {
    max-width: auto;
    min-width: 100%;
  }
}
</style>
