<template>
  <div v-if="isGermany">
    <Germany />
  </div>
  <div v-else-if="isSwitzerland">
    <Switzerland />
  </div>
  <div v-else>
    <Netherland />
  </div>
</template>

<script setup>
import Germany from "@/components/About/Country/Germany.vue";
import Netherland from "@/components/About/Country/Netherland.vue";
import Switzerland from "@/components/About/Country/Switzerland.vue";
import { computed } from "vue";
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isSwitzerland = computed(() => LANG === "ch");
</script>
