import ApiService from "./api.service";

const ProfileService = {
  async updateOpeningHour(data) {
    try {
      return await ApiService.put(`profiles/open-hour/${data.id}`, data);
    } catch (e) {
      console.error(e);
    }
  },
  async showPointsByCustomerNumber(id) {
    try {
      return await ApiService.get(`profiles/${id}/points-by-customer-number`);
    } catch (e) {
      console.error(e);
    }
  },
  async updateMarketingOption(data) {
    try {
      return await ApiService.put(`profiles/marketing-option/${data.id}`, data);
    } catch (e) {
      console.error(e);
    }
  },
  async showKickbackInfo(groupId) {
    try {
      return await ApiService.get(`display-kickback-info?groupId=${groupId}`);
    } catch (e) {
      console.error(e);
    }
  },
  async storeStaffToDealer(data) {
    try {
      return await ApiService.post("sync-staff", data);
    } catch (error) {
      return error.response;
    }
  },
  async jobPositions() {
    try {
      return await ApiService.get("jobPositions");
    } catch (e) {
      console.log(e);
    }
  },
  async userProfileData(hashId) {
    try {
      return await ApiService.get(`profiles/${hashId}`);
    } catch (e) {
      console.log(e);
    }
  },
  async storeProfilePercentage(data) {
    try {
      return await ApiService.post("profiles/percentage", data);
    } catch (e) {
      console.log(e);
    }
  },
  async profilePercentage(userId) {
    try {
      return await ApiService.get(`profiles/${userId}/user`);
    } catch (e) {
      console.log(e);
    }
  },
  async storeFullProfilePoints(userId) {
    try {
      return await ApiService.post(`profiles/full-data-points/${userId}`);
    } catch (e) {
      console.log(e);
    }
  },
  async userPermissions() {
    try {
      return await ApiService.get("user/permissions");
    } catch (e) {
      console.log(e);
    }
  },
  async tyreLimitYearly(year) {
    try {
      return await ApiService.get(`shop-limit/${year}`);
    } catch (e) {
      console.log(e);
    }
  }
};

export { ProfileService };
