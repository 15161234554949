<template>
  <MDBContainer>
    <Menu />
    <MDBRow>
      <MDBCol col="12">
        <props.content />
      </MDBCol>
    </MDBRow>
    <Footer />
  </MDBContainer>
</template>

<script setup>
import Menu from "@/components/Layout/Menu.vue";
import Footer from "@/components/Layout/Footer.vue";
import { MDBCol, MDBRow, MDBContainer } from "mdb-vue-ui-kit";
import { defineProps } from "vue";

const props = defineProps({
  content: Object,
});
</script>

<style scoped></style>
