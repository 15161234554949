const textTransform = {
  convertToSlug: (text) => {
    if (text) {
      return text
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    }
    return "slug";
  },
};

export default textTransform;
