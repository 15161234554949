<template>
  <MDBRow>
    <MDBCol cols="12" lg="6" xl="4" class="mb-4" v-for="(data, i) in contactData" :key="i"
      :class="`${checkAccess(data.langs) ? '' : 'd-none'}`">
      <Card :data="data" :linkText="data.linkText" v-if="checkAccess(data.langs)" :simpleLink="data.simpleLink"
        :target="data.target" class="h-100" />
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import Card from "@/components/General/Card/index.vue";
import i18n from "@/plugins/i18n";
import { computed } from "vue";

const LANG = localStorage.getItem("lang");
const isNetherland = computed(() => LANG === "nl");

const contactData = [
  {
    name: i18n.global.t("contact.categories.service.description"),
    link: { name: "service_number" },
    description:
      !isNetherland.value
        ? null
        : i18n.global.t("contact.categories.contact.short_desc"),
    image: require("@/assets/img/contact/service.png"),
    linkText: i18n.global.t("buttons.discoverButton"),
    langs: ["de", "nl", "ch"],
    simpleLink: false,
    target: '_self'
  },
  {
    name: i18n.global.t("contact.categories.form.description"),
    link: { name: "beanstandungsFormular" },
    description: null,
    image: require("@/assets/img/contact/mail.png"),
    linkText: i18n.global.t("buttons.formButton"),
    langs: ["de", "ch"],
    simpleLink: false,
    target: '_self'
  },
  {
    name: i18n.global.t("strategicPartners.cardTitle"),
    link: { name: "strategic_partners" },
    description: i18n.global.t("strategicPartners.short_desc"),
    image: require("@/assets/img/contact/support_nl.jpg"),
    linkText: i18n.global.t("buttons.discoverButton"),
    langs: ["nl"],
    simpleLink: false,
    target: '_self'
  },
  {
    name: i18n.global.t("contact.categories.touch.title"),
    link: { name: 'touch_incentive' },
    description: i18n.global.t("contact.categories.touch.description"),
    image: require("@/assets/img/contact/ti_logo.png"),
    linkText: i18n.global.t("buttons.discoverButton"),
    langs: ["nl"],
    simpleLink: false,
    target: '_self'
  },
];

const checkAccess = (langCode) => {
  return langCode.includes(LANG);
};
</script>
