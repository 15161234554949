import ApiService from "./api.service";

const MediaService = {
  async mediaCategories() {
    try {
      return await ApiService.get("media-categories");
    } catch (error) {
      console.log(error);
    }
  },
  async singleMedia(data) {
    return await ApiService.get(
      `media/single/${data.id}/show?sortBy=${data.sortBy}`
    );
  },
  async singleMediaCategory(id) {
    return await ApiService.get(`media-categories/${id}`);
  },
  async fetchFilteredMedia(data) {
    return await ApiService.post(`media/filter`, data);
  },
};

export { MediaService };
