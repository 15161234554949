<template>
  <MDBRow>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import FileItem from "@/components/ProductInfo/FileItem.vue";
import { MDBRow } from "mdb-vue-ui-kit";
import { computed, ref } from "vue";
import i18n from "@/plugins/i18n";
const currentLocale = localStorage.getItem("currentLocale");
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isNetherlands = computed(() => LANG === "nl");

const germanContent = ref([
  {
    file: "BS Markierung EV_2pager_Ansicht.pdf",
    name: "EV Markierung",
    description: `${i18n.global.t("summary")}`,
    date: `${i18n.global.t("information")} 2021`,
    type: "pdf",
    simpleLink: true,
    directory: "broschuren/technologien/",
  },
  {
    file: "BS Markierung HL_1pager_Ansicht.pdf",
    name: "HL Markierung",
    description: `${i18n.global.t("summary")}`,
    date: `${i18n.global.t("productOverview")} 2020`,
    type: "pdf",
    simpleLink: true,
    directory: "broschuren/technologien/",
  },
  {
    file: "BS Technologie ENLITEN_2021_4pager_Ansicht.pdf",
    name: "ENLITEN Technologie",
    description: `${i18n.global.t("summary")}`,
    date: `${i18n.global.t("brochure")} 2021`,
    type: "pdf",
    simpleLink: true,
    directory: "broschuren/technologien/",
  },
  {
    file: "EU Reifenlabel_2021_2pager_Ansicht.pdf",
    name: "EU Reifenlabel",
    description: `${i18n.global.t("summary")}`,
    date: `${i18n.global.t("information")} 2021`,
    type: "pdf",
    simpleLink: true,
    directory: "broschuren/technologien/",
  },
]);

const netherlandContent = ref([
  {
    file: "Bridgestone_Enliten_per product_S24.pdf",
    name: "Bridgestone Enliten",
    description: null,
    date: `${i18n.global.t("productOverview")} 2024`,
    type: "pdf",
    simpleLink: true,
    directory: "broches_nl/technology/",
  },
  {
    file: "Bridgestone_prestaties premium banden_S24.pdf",
    name: "Bridgestone prestaties",
    description: null,
    date: `${i18n.global.t("productOverview")} 2024`,
    type: "pdf",
    simpleLink: true,
    directory: "broches_nl/technology/",
  },
]);

const swissContent = computed(() => {
  const data = {
    ch: [
      {
        file: "EM_Gesamtprogramm_DE.pdf",
        name: "EM_Gesamtprogramm_DE",
        description: null,
        date: null,
        type: "pdf",
        simpleLink: true,
        directory: "brochures_ch/",
      },
      {
        file: "EM_Technischer Ratgeber.pdf",
        name: "EM_Technischer Ratgeber",
        description: null,
        date: null,
        type: "pdf",
        simpleLink: true,
        directory: "brochures_ch/",
      },
    ],
    fr: [
      {
        file: "CVR_Twopager_CH_FR_klein_Korrektur2.pdf",
        name: "CVR_Twopager_CH",
        description: "FR_klein_Korrektur2",
        date: null,
        type: "pdf",
        simpleLink: true,
        directory: "brochures_fr/",
      },
      {
        file: "VX-R-brochure-FR.pdf",
        name: "VX-R",
        description: "brochure-FR",
        date: null,
        type: "pdf",
        simpleLink: true,
        directory: "brochures_fr/",
      },
    ],
    it: [
      {
        file: "CVR Twopager_CH_IT_klein_Korrektur2.pdf",
        name: "CVR",
        description: "Twopager_CH_IT_klein_Korrektur2",
        date: null,
        type: "pdf",
        simpleLink: true,
        directory: "brochures_it/",
      },
      {
        file: "VX-R-brochure_IT.pdf",
        name: "VX-R",
        description: "brochure_IT",
        date: null,
        type: "pdf",
        simpleLink: true,
        directory: "brochures_it/",
      },
    ],
  };

  return data[currentLocale];
});

const files = computed(() => {
  if (isGermany.value) {
    return germanContent.value;
  } else if (isNetherlands.value) {
    return netherlandContent.value;
  } else {
    return swissContent.value;
  }
});
</script>
