<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <button type="button" class="btn-close" @click="$emit('close', false)">
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          <iframe
            class="vimeo"
            frameborder="0"
            :src="props.video"
            allow="fullscreen"
            autoplay="false"
            loop="false"
            title=""
            trainingData-ready="true"
            tabindex="-1"
            ref="iframePlayer"
          ></iframe>
        </slot>
      </section>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, computed } from "vue";
import Player from "@vimeo/player";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  video: String,
});

const iframePlayer = ref(null);
const user = computed(() => store.getters.getMe);
const training = computed(() => store.getters.getTraining);

onMounted(() => {
  const player = new Player(iframePlayer.value, {
    loop: false,
    autoplay: false,
  });

  player.on("ended", async function () {
    const data = {
      user_id: user.value.id,
      training_id: training.value.id,
    };

    await store.dispatch("storeWatchedVideo", data);
    await store.dispatch("loadWatchedVideo", data);
  });
});
</script>

<style scoped lang="scss">
.vimeo {
  width: 100%;
  height: 400px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
  max-height: 500px;
}

.modal-header {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-body {
  position: relative;
  padding: 0;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}
</style>
