<template>
  <div :class="props.mainClass">
    <div v-if="props.bannerImage">
      <img :src="require(`@/assets/${props.bannerImage}`)" />
    </div>
    <div :class="props.contentClass">
      <Breadcrumbs :showBreadcrumbs="showBreadcrumbs" :parent-routes="breadcrumbsParentRoutes"
        :routeName="props.breadcrumbsRouteName" />
      <div v-if="props.loader">
        <Loader :show="props.loader"></Loader>
      </div>
      <MDBRow v-else>
        <MDBCol col="12" :lg="leftColWidth">
          <h1 v-if="props.title" class="my-3 title text-uppercase" v-html="props.title"></h1>
          <p v-if="props.description" :class="`font-16 description ${props.small ? 'no-height mb-5' : ''
            }`" v-html="props.description"></p>
        </MDBCol>
        <MDBCol v-if="showRight()" col="12" :lg="rightColWidth">
          <slot name="right" />
        </MDBCol>
      </MDBRow>
    </div>
  </div>
</template>

<script setup>
import Breadcrumbs from "@/components/General/Breadcrumbs/index";
import Loader from "@/components/General/Loader/index.vue";
import { defineProps, useSlots, computed } from "vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";

const props = defineProps({
  title: String,
  description: String,
  small: Boolean,
  bannerImage: {
    type: String,
    default: "",
  },
  bannerTitle: String,
  breadcrumbsParentRoutes: Array,
  breadcrumbsRouteName: String,
  loader: Boolean,
  contentClass: {
    type: String,
    default: "",
  },
  leftCol: String,
  rightCol: String,
  showBreadcrumbs: {
    type: Boolean,
    default: true,
  },
  mainClass: {
    type: String,
    default: "category-container",
  },
});

const slots = useSlots();
const showRight = () => !!slots.right;
const leftColWidth = computed(() => {
  if (props.leftCol) {
    return props.leftCol;
  }
  return showRight() ? "9" : "12";
});

const rightColWidth = computed(() => {
  return props.rightCol ? props.rightCol : "3";
});
</script>

<style scoped lang="scss">
@import "../../../styles/variables";

.banner {
  position: relative;
  background: $backgroundWhite;
  background-size: cover;
  background-position: right bottom;

  min-height: 282px;

  @media screen and (max-width: $xxxlBreakpoint) {
    min-height: 195px;
  }

  @media screen and (max-width: $xxlBreakpoint) {
    min-height: 190px;
  }
}

.description {
  font-weight: 400;
  font-style: normal;
  line-height: normal;

  &.no-height {
    min-height: auto;
  }
}

.banner-title {
  color: white;
  text-shadow: 5px 5px 20px rgb(0 0 0 / 70%);
}

.title {
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    width: 30px;
    height: 4px;
    background: #ed1c24;
    transform: rotate(110deg);
    margin: 10px 0;
  }

  @media screen and (max-width: $mdBreakpoint) {
    font-size: 20px;
  }
}

.category-container {
  min-height: 200px;
}

.training-container {
  min-height: 40px;
}
</style>
