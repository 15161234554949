<template>
  <MDBRow class="mb-5">
    <MDBCol>
      <h6 class="mb-3">
        {{ question.body[LANG] }}
      </h6>
      <div
        v-for="(answer, key) in question.answers"
        :key="key"
        class="form-check mb-1 d-flex"
      >
        <input
          :class="`form-check-input ${
            wrongAnswerKey === key || wrongAnswerKey === 'all'
              ? 'border-danger'
              : ''
          }`"
          type="radio"
          :name="question.id"
          :id="`${question.id}-${key}`"
          v-model="checked"
          :value="key"
          @change="handleAnswer()"
        />
        <label
          :class="`form-check-label ${
            wrongAnswerKey === key || wrongAnswerKey === 'all'
              ? 'text-danger'
              : ''
          }`"
          :for="`${question.id}-${key}`"
        >
          {{ answer.body[LANG] }}
        </label>
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { MDBCol, MDBRow } from "mdb-vue-ui-kit";
import { ref, watch, computed } from "vue";

const LANG = computed(() => localStorage.getItem("lang"));

const checked = ref(false);
const wrongAnswerKey = ref(false);

const props = defineProps({
  question: Object,
  validate: Number,
});

const emit = defineEmits(["validationEffect"]);

watch(props, () => {
  if (props.validate == 0) return;
  if (checked.value === false) {
    wrongAnswerKey.value = "all";
  } else if (
    checked.value !== false &&
    props.question.correct[LANG.value] != checked.value
  ) {
    wrongAnswerKey.value = checked.value;
  } else {
    wrongAnswerKey.value = false;
  }
});

const handleAnswer = () =>
  emit("validationEffect", props.question.correct[LANG.value] == checked.value);
</script>

<style scoped lang="scss">
// @import "../../styles/_variables.scss";
</style>
