<template>
  <MDBRow>
    <MDBCol cols="12" lg="7">
      <div class="textBox textBox--small">
        <div class="fw-bold pb-2">
          {{ $t("trainings.singleLecture.registerForm.title") }}
        </div>
        <div v-if="training" v-html="description"></div>
      </div>
    </MDBCol>
    <MDBCol cols="12" lg="5" v-if="result.used_points == 0 && !isWholesaler && !isWholesalerStaff">
      <VeeForm class="form user-form" @submit="onSubmit">
        <p class="fw-bold">
          {{ $t("trainings.singleLecture.registerForm.inquiry") }}
        </p>
        <p>
          {{ $t("trainings.singleLecture.registerForm.interested") }}
          <span class="fw-bold">{{ training.title }}</span>
        </p>
        <div v-for="trainingType in trainingTypes" :key="trainingType.id">
          <input class="form-check-input" type="radio" :name="trainingType.id" v-model="selectedTrainingType"
            :value="trainingType.id" :disabled="trainingDetails.length < 2" />
          <label class="form-check-label" :for="trainingType.id">{{
            trainingType.name
          }}</label>
        </div>
        <div v-if="selectedTrainingType == 1">
          <p>
            {{ $t("trainings.singleLecture.registerForm.participantsNr") }}
          </p>
          <div>
            <Field type="number" class="form-control" :placeholder="$t('trainings.singleLecture.registerForm.participantsNr')
              " :aria-label="$t('trainings.singleLecture.registerForm.participantsNr')
                " aria-describedby="basic-addon1" v-model="participantCount" :rules="{ min_value: 1 }"
              name="participantCount" label="Teilnehmerzahl" />
            <ErrorMessage name="participantCount" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </div>
        <p class="fw-bold" v-if="canUsePoints">
          {{ $t("trainings.singleLecture.registerForm.pointsFromBalance") }}
        </p>
        <div v-if="canUsePoints">
          <Field type="number" class="form-control" :placeholder="$t('points')" :aria-label="$t('points')"
            aria-describedby="basic-addon1" v-model="setPoints" name="setPoints"
            :rules="{ min_value: 0, max_value: maxPoints }" :label="$t('points')"
            :disabled="!canUsePoints || isStaff" />
          <ErrorMessage name="setPoints" class="text-danger text-small text-end m-0 pt-1" />
        </div>
        <p v-if="calculatePriceDiscount > 0 && canUsePoints">
          {{
            $t("trainings.singleLecture.registerForm.reduction", {
              points: calculatePriceDiscount,
            })
          }}
        </p>
        <div class="pt-3">
          <button type="submit" class="btn btn-danger text-uppercase" :disabled="isStaff">
            {{ $t("trainings.singleLecture.registerForm.request") }}
          </button>
        </div>
      </VeeForm>
    </MDBCol>
    <Success v-else-if="!isWholesaler && !isWholesalerStaff" />
  </MDBRow>
</template>

<script setup>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import Success from "./Success.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { defineProps, ref, computed, defineEmits, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();

const props = defineProps({
  training: Object,
  trainingTypes: Array,
});

const emit = defineEmits(["refresh"]);

const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isStaff = ref(false);
const selectedTrainingType = ref(1);
const setPoints = ref(0);
const participantCount = ref(0);
const user = computed(() => store.getters.getMe);
const profile = computed(() => store.getters.getProfile);
const trainingDetails = computed(() => store.getters.getBTrainingDetails);
const maxPoints = computed(() => user.value.points);
const result = computed(() => store.getters.getBTrainingResult);
const canUsePoints = ref(false);
const description = ref(null);

const calculatePriceDiscount = computed(() => {
  return Math.round(setPoints.value * 0.25);
});

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }
});

const onSubmit = async () => {
  const trainingDetail = trainingDetails.value.find(
    (el) => el.b_training_type_id == selectedTrainingType.value
  );

  const objData = Object.assign({}, trainingDetail);

  let data = {
    user_id: user.value.id,
    b_training_id: props.training.id,
    b_training_type_id: selectedTrainingType.value,
    b_training_cost_id: objData.b_training_cost_id,
    participant_number:
      selectedTrainingType.value == 1 ? participantCount.value : 0,
    used_points: setPoints.value,
    price: calcPrice(objData),
  };

  const response = await store.dispatch("storeBResult", data);

  if (response) {
    emit("refresh");
  }
};

const calcPrice = (objData) => {
  const currentPrice =
    selectedTrainingType.value == 1
      ? objData.price * participantCount.value
      : objData.price;

  return currentPrice - calculatePriceDiscount.value;
};

watch(trainingDetails, () => {
  const objData = Object.assign({}, trainingDetails.value);

  if (trainingDetails.value.length < 2) {
    selectedTrainingType.value = objData[0].b_training_type_id;
  }

  canUsePoints.value = props.training.can_spend_points.includes(
    profile.value.group_id
  );

  description.value = setDescription();
});
const setDescription = () => {
  if (
    props.training &&
    props.training.can_spend_points.includes(profile.value.group_id)
  ) {
    return props.training.description;
  } else {
    return props.training.default_description;
  }
};
</script>
