<template>
  <CategoryTop
    :title="$t('productBrochures.technologiesTitle')"
    :breadcrumbs-parent-routes="
      isGermany ? ['productInfo', 'produktbroschuren'] : ['produktbroschuren']
    "
    :description="$t('productBrochures.technologiesDesc')"
  />
</template>

<script setup>
import { computed } from "vue";
import CategoryTop from "../../General/CategoryTop/index.vue";

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
</script>
