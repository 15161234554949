import ApiService from "./api.service";

const PriceService = {
  async prices() {
    try {
      return await ApiService.get("userPrices");
    } catch (error) {
      return error.response;
    }
  },
  async priceCategories() {
    try {
      return await ApiService.get("priceCategories");
    } catch (error) {
      return error.response;
    }
  },

  async singlePriceList(slug) {
    try {
      return await ApiService.get(`price-categories/${slug}`);
    } catch (error) {
      return error.response;
    }
  },

  async brochureCategories() {
    try {
      return await ApiService.get("brochure-categories");
    } catch (error) {
      return error.response;
    }
  },

  async fetchFilteredBrochure(data) {
    return await ApiService.post(`brochure/filter`, data);
  },
};

export { PriceService };
