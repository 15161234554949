import ApiService from "./api.service";

const TrainingService = {
  async trainings() {
    try {
      return await ApiService.get("trainings?withQuestion=1");
    } catch (e) {
      console.log(e);
    }
  },
  async training(id) {
    try {
      return await ApiService.get("trainings/" + id + "/show/langs");
    } catch (error) {
      console.log(error);
    }
  },
  async questions(id) {
    try {
      return await ApiService.get("questions/" + id);
    } catch (error) {
      console.log(error);
    }
  },
  async storeResult(data) {
    try {
      return await ApiService.post("trainings/result", data);
    } catch (e) {
      console.log(e);
    }
  },

  async doneTraining(data) {
    try {
      return await ApiService.post("trainings/done", data);
    } catch (error) {
      console.log(error);
    }
  },

  async generateCertificate() {
    try {
      return await ApiService.get("blank-verkaufsstarke-certificate.pdf");
    } catch (error) {
      console.log(error);
    }
  },

  async sendTrainingCertificate(data) {
    try {
      return await ApiService.post("trainings/send-certificate", data);
    } catch (error) {
      console.log(error);
    }
  },

  async storeVideoWatched(data) {
    try {
      return await ApiService.post("userVideo", data);
    } catch (e) {
      console.log(e);
    }
  },

  async fetchWatchedVideo(data) {
    try {
      return await ApiService.get(
        `userVideo/${data.user_id}/show/${data.training_id}`
      );
    } catch (e) {
      console.log(e);
    }
  },
  async certificateTexts(id) {
    try {
      return await ApiService.get(`trainings/certificate/${id}`);
    } catch (error) {
      console.log(error);
    }
  },
};

export { TrainingService };
