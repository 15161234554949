<template>
  <div class="background-wrap">
    <div class="background background--de"></div>
  </div>

  <div class="login-container px-2">
    <MDBRow :class="[`${isGermany || isSwitzerland ? 'pt-5 mt-5' : ''}`]">
      <MDBCol lg="12" xl="6" class="d-flex justify-content-center">
        <div
          class="d-flex flex-column justify-content-start align-items-center text-lang-wrapper"
        >
          <MDBCard class="box-shadow card--height px-5">
            <div class="w-100 d-flex justify-content-start">
              <img
                :src="require('@/assets/img/bs_logo_solution.svg')"
                alt="logo"
              />
            </div>
            <MDBCardBody class="card--height px-0">
              <MDBCardTitle class="text-start font-24 mb-5">
                <div v-html="$t('languageSelect.title')"></div>
              </MDBCardTitle>
              <MDBCardText class="pb-0">
                <h1 class="pb-2">{{ $t("languageSelect.label") }}</h1>
                <div class="select-desc">
                  {{ $t("languageSelect.description") }}
                </div>
                <div
                  class="d-flex flex-row justify-content-start align-items-center mt-4"
                >
                  <img
                    :src="require('@/assets/img/swiss-flag.png')"
                    alt="flag"
                  />
                  <div>
                    <div
                      class="lang-btn cursor--pointer"
                      @click="setLanguage('ch')"
                    >
                      START
                    </div>
                    <div
                      class="lang-btn cursor--pointer"
                      @click="setLanguage('fr')"
                    >
                      DEPART
                    </div>
                    <div
                      class="lang-btn cursor--pointer"
                      @click="setLanguage('it')"
                    >
                      INIZIO
                    </div>
                  </div>
                </div>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </div>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBRow,
} from "mdb-vue-ui-kit";
import { computed } from "vue";

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isSwitzerland = computed(() => LANG === "ch");

const setLanguage = (lang) => {
  localStorage.setItem("currentLocale", lang);
  localStorage.setItem("langSelected", 1);

  window.location.reload();
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/auth";
</style>
