<template>
  <MDBCardBody>
    <MDBCardTitle>
      {{ $t("dashboard.profile.welcome") }} {{ isGermany ? $t("at") : ""
      }}<br />
      <div class="fw-bold" v-if="isGermany">
        BRIDGESTONE <span class="text-danger">PLUS</span>,
      </div>
      {{ profile.forename }} {{ profile.name }}
    </MDBCardTitle>
    <MDBCardText>
      <template
        v-if="
          !isWholesaler && !isStaff && !isWholesalerStaff && canSeeWelcomePoints
        "
      >
        <p v-if="profilePercentage != 100">
          {{ $t("dashboard.profile.completeProfile") }}
          <span
            class="success-green fw-bold cursor--pointer"
            @click="goToMyAccount"
            :disabled="user?.commitment_letter_accept && user?.intro == 1"
          >
            {{ $t("dashboard.profile.completeProfileBold") }}
          </span>
        </p>
        <p v-else>
          {{ $t("dashboard.profile.fullCompleteProfile") }}
        </p>
      </template>
      <DashboardLink
        v-if="!isWholesaler && !isStaff"
        class="text-uppercase"
        :to="{ name: 'stammdaten' }"
        :disabled="user?.commitment_letter_accept && user?.intro == 1"
      >
        {{ $t("dashboard.profile.link") }}
      </DashboardLink>
    </MDBCardText>
  </MDBCardBody>
</template>

<script setup>
import { MDBCardBody, MDBCardTitle, MDBCardText } from "mdb-vue-ui-kit";
import DashboardLink from "../Dashboard/Dashboard-link.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, watch, ref } from "vue";
import TokenService from "@/services/storage.service";

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");

const store = useStore();
const router = useRouter();

const user = computed(() => store.getters.getMe);
const profile = computed(() => store.getters.getProfile);

const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isStaff = ref(false);
const canSeeWelcomePoints = ref(false);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }

  canSeeWelcomePoints.value = TokenService.showElement("welcomePoints.see");
});

const profilePercentage = computed(() => {
  return sessionStorage.getItem("percentage") || store.getters.getPercentageNum;
});

const goToMyAccount = () => {
  router.push({ name: "stammdaten" });
};
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/welcome_box.scss";
@import "@/styles/mailbox.scss";

:deep(.card-title) {
  font-size: 20px;
  @media screen and (min-width: $xxlBreakpoint) {
    font-size: 25px;
  }
}

.message-notification {
  background: #f2790d;
  color: white;
  display: inline-block;
  border-radius: 25px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  margin-left: 5px;
}

.title {
  position: relative;
}
</style>
