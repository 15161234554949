<template>
  <div class="px-5 py-5 bg-gray">
    <h5>{{ $t("marketingModal.title") }}</h5>
    <div class="mb-3">{{ $t("marketingModal.description") }}</div>

    <div
      class="bg-white px-3 py-3 content-box"
      ref="contentBox"
      v-on:scroll="handleScroll"
    >
      <Content />
    </div>

    <MDBCardFooter class="px-0 d-flex justify-content-between pt-4">
      <div class="d-flex align-items-center ps-4">
        <img
          src="@/assets/img/icons/wf-pdf.svg"
          alt="Download"
          @click="downloadCommitmentLetter()"
        />
        <span
          class="ps-2 text-decoration-underline cursor--pointer"
          @click="downloadCommitmentLetter()"
          >Download</span
        >
      </div>
      <div>
        <MDBBtn
          class="d-flex justify-content-center align-items-center"
          color="danger"
          :disabled="disableBtn"
          @click="accept()"
          ><span>{{ $t("buttons.acceptBtn") }}</span>
          <Loader
            :show="loading"
            :small="true"
            spinner="spinner.svg"
            :tiny="true"
        /></MDBBtn>
      </div>
    </MDBCardFooter>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ProfileService } from "@/services/profile.service";
import { defineEmits, ref, computed } from "vue";
import { MDBBtn, MDBCardFooter } from "mdb-vue-ui-kit";
import Loader from "@/components/General/Loader/index.vue";
import Content from "@/components/CommitmentLetter/Content.vue";
const emit = defineEmits(["close"]);
window.addEventListener("scroll", handleScroll);

const store = useStore();
const contentBox = ref();
const disableBtn = ref(true);
const profile = computed(() => store.getters.getProfile);
const loading = computed(() => store.getters.getPending);

const close = () => {
  emit("close", false);
};

const handleScroll = (event) => {
  if (
    Math.round(event.srcElement.offsetHeight + event.srcElement.scrollTop) +
      2 >=
    event.srcElement.scrollHeight
  ) {
    disableBtn.value = false;
  }
};

const accept = async () => {
  disableBtn.value = true;
  const data = {
    id: profile.value.id,
  };
  const response = await ProfileService.updateMarketingOption(data);
  disableBtn.value = false;
  if (response.data.success) {
    await store.dispatch("me");
    await store.dispatch("loadUnreadMessages");

    close();
  }
};

const downloadCommitmentLetter = () => {
  window.open(
    `${window.location.origin}/files/Condition_of_Participation_Netherlands.pdf`,
    "_blank"
  );
};
</script>

<style lang="scss" scoped>
.content-box {
  height: 300px;
  overflow-y: scroll;
}
</style>
