import ApiService from "./api.service";

const InvestmentSupportService = {
  async store(data) {
    try {
      return await ApiService.post("investmentSupport", data);
    } catch (error) {
      return error.response;
    }
  },
};

export { InvestmentSupportService };
