<template>
  <ProductSearchBox />
  <CategoryList></CategoryList>

  <CategoryFilter @sort-changed="(sortValue) => (sortBy = sortValue)" @per-page-changed="(value) => (perPage = value)"
    :loader="categoriesRequestSent" />

  <ProductList :products="products" :loader="productRequestSent" @page-number-changed="(e) => loadProducts(e)"
    class="pt-2" />
</template>

<script setup>
import { useStore } from "vuex";
import { computed, ref, watch, onMounted } from "vue";
import CategoryFilter from "@/components/Shop/CategoriesFilter.vue";
import CategoryList from "@/components/Shop/CategoryList.vue";
import ProductSearchBox from "@/components/Shop/ProductSearchBox.vue";
import { useRoute } from "vue-router";
import ProductList from "@/components/Shop/ProductList.vue";
import TokenService from "@/services/storage.service";

const page = ref(TokenService.getProductPage() ?? 1);
const store = useStore();
const sortBy = ref("asc");
const productRequestSent = computed(() => store.getters.getProductRequestSent);
const categoriesRequestSent = computed(
  () => store.getters.getCategoriesRequestSent
);
const route = useRoute();
const categoryId = computed(() => route.params.id);
const products = computed(() => store.getters.getProducts);
const perPage = ref(9)

watch(route, () => {
  if (route.name == "shopCategory") {
    page.value = TokenService.getProductPage()
    loadProducts(page.value);
  }
});

watch(sortBy, (newVal) => {
  const data = { id: categoryId.value, pageNumber: 1, sort: newVal, perPage: perPage.value };
  store.dispatch("loadCategoryProduct", data);
});


watch(perPage, (newVal) => {
  const data = { id: categoryId.value, pageNumber: 1, sort: sortBy.value, perPage: newVal };
  store.dispatch("loadCategoryProduct", data);
});

onMounted(() => {
  if (route.params.id) {
    loadProducts(page.value);
  }
});

const loadProducts = (e = 1) => {
  TokenService.setProductPage(e)
  const data = { id: categoryId.value, pageNumber: e, sort: sortBy.value, perPage: perPage.value };

  store.dispatch("loadCategoryProduct", data);
};
</script>

<style scoped lang="scss">
:deep(.description) {
  min-height: none;
}
</style>
