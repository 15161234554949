<template>
  <MDBRow>
    <MDBCol lg="4" md="6" class="mb-4" v-for="(price, i) in priceLists" :key="i" :class="{ 'd-none': !price.show }">
      <Card :data="price" v-if="price.show" minHeight="container-height" :linkText="price.linkText" />
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed } from "vue";
import Card from "@/components/General/Card/index.vue";
import i18n from "@/plugins/i18n";

const LANG = localStorage.getItem("lang");
const isNetherland = computed(() => LANG === "nl");
const isGermany = computed(() => LANG === "de");
const isSwitzerland = computed(() => LANG === "ch");

const priceLists = computed(() => [
  {
    name: i18n.global.t("productInfo.index.categories.productBrochures.title"),
    link: { name: "brochure_prices" },
    description: i18n.global.t(
      "productInfo.index.categories.productBrochures.description"
    ),
    image: require(`@/assets/img/brochures/produktbroschuren_${LANG}.png`),
    show: isGermany.value,
    simpleLink: isGermany.value,
  },
  {
    name: i18n.global.t("pageName.produktbroschuren"),
    link: { name: "produktbroschuren" },
    description: i18n.global.t("productInfo.index.categories.productBrochures.description"),
    image: require(`@/assets/img/brochures/produktbroschuren_${LANG}.png`),
    show: isSwitzerland.value,
    linkText: i18n.global.t("buttons.toDiscover"),
  },
  {
    name: i18n.global.t("pageName.preislistenList"),
    link: { name: "preislistenList" },
    description: i18n.global.t("priceDescription"),
    image: require(`@/assets/img/price_list_${LANG}.png`),
    show: isSwitzerland.value || isNetherland.value,
    linkText: i18n.global.t("buttons.toDiscover"),
  },
  {
    name: i18n.global.t("pageName.pricat"),
    link: { name: "pricat" },
    description: i18n.global.t("pricatDescription"),
    image: require("@/assets/img/pricat.png"),
    show: !isNetherland.value,
    linkText: i18n.global.t("buttons.toDiscover"),
  },
]);
</script>
