<template>
  <MDBContainer>
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 100vh"
    >
      <div class="text-center">
        <MDBTable>
          <thead>
            <tr>
              <th
                scope="col"
                v-for="(column, key) in columnsStandard"
                :key="key"
              >
                {{ column }}
              </th>
              <th
                scope="col"
                v-for="(column, key) in columnsBoosters"
                :key="key"
              >
                {{ column }}
              </th>
              <th
                scope="col"
                v-for="(column, key) in columnsAdditional"
                :key="key"
              >
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, key) in bank" :key="key">
              <td>{{ account.date ?? "-" }}</td>
              <td>{{ account.source ?? "-" }}</td>
              <td>{{ account.article ?? "-" }}</td>
              <td>{{ account.brand ?? "-" }}</td>
              <td>{{ account.product ?? "-" }}</td>
              <td>{{ account.type ?? "-" }}</td>
              <td>{{ account.dimension ?? "-" }}</td>
              <td>{{ account.saison ?? "-" }}</td>
              <td>{{ account.rim ?? "-" }}</td>
              <td>{{ account.qty ?? "-" }}</td>
              <td>
                <strong>{{ account.points ?? "-" }}</strong>
              </td>
              <td v-for="(boost, key2) in account.boosters" :key="key2">
                {{ boost ?? "-" }}
              </td>
              <td>{{ account.provenance ?? "-" }}</td>
              <td>{{ account.sumPoints - account.points }}</td>
            </tr>
          </tbody>
        </MDBTable>
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>{{ $t("shop.pointsTotal") }}</MDBCardTitle>
            <MDBCardText>
              {{ $t("bankstatement.regularPoints") }}:
              <strong>{{ points?.sumStandardPoints }}</strong>
            </MDBCardText>
            <MDBCardText>
              {{ $t("bankstatement.boosterPoints") }}:
              <strong>{{ points?.sumBoosterPoints }}</strong>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </div>
    </div>
  </MDBContainer>
</template>
<script>
import {
  MDBContainer,
  MDBTable,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "UserBankStatement",
  components: {
    MDBContainer,
    MDBTable,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
  },
  setup() {
    const store = useStore();
    store.dispatch("bankStatement");
    return {
      columnsStandard: computed(
        () => store.getters.getBankStatementHeadersStandard
      ),
      columnsBoosters: computed(
        () => store.getters.getBankStatementHeadersBoosters
      ),
      columnsAdditional: computed(
        () => store.getters.getBankStatementHeadersAdditional
      ),
      points: computed(() => store.getters.getPoints),
      bank: computed(() => store.getters.getBankStatementData),
    };
  },
};
</script>

<style scoped></style>
