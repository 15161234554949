<template>
  <VeeForm :class="props.formClass" @submit="onSubmit">
    <MDBRow :class="props.contentClass">
      <MDBCol col="12" lg="6" xl="3">
        <MDBRow>
          <MDBCol col="12">
            <div class="input-group mb-1">
              <label for="old_label" class="label text-uppercase my-1">
                {{ $t("fuelCalculator.oldLabel") }}
              </label>
              <Field
                name="old_label"
                as="select"
                class="form-select w-100"
                aria-label=".form-select-lg example"
                v-model="form.old_label"
                :rules="{ required: true }"
                :label="$t('fuelCalculator.oldLabel')"
              >
                <option selected value="">
                  {{ $t("fuelCalculator.selectOldLabel") }}*
                </option>
                <option
                  :value="key"
                  v-for="(value, key, index) in labelTranslation"
                  :key="index"
                >
                  {{ value }}
                </option>
              </Field>
              <div class="w-100">
                <ErrorMessage
                  name="old_label"
                  class="text-danger text-small text-end m-0 pt-1"
                />
              </div>
            </div>
          </MDBCol>
          <MDBCol col="12" id="4">
            <div class="input-group mb-2">
              <label for="new_label" class="label text-uppercase mb-1">
                {{ $t("fuelCalculator.newLabel") }}
              </label>
              <Field
                name="new_label"
                as="select"
                class="form-select mb-2 w-100"
                aria-label=".form-select-lg example"
                v-model="form.new_label"
                :rules="{ required: true }"
                :label="$t('fuelCalculator.newLabel')"
              >
                <option selected value="">
                  {{ $t("fuelCalculator.selectNewLabel") }}*
                </option>
                <option
                  :value="key"
                  v-for="(value, key, index) in labelTranslation"
                  :key="index"
                >
                  {{ value }}
                </option>
              </Field>
              <div class="w-100">
                <ErrorMessage
                  name="new_label"
                  class="text-danger text-small text-end m-0 pt-1"
                />
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol col="12" lg="6" xl="5">
        <MDBRow>
          <MDBCol col="12" id="2">
            <label for="old_label" class="label text-uppercase my-1">
              {{ $t("fuelCalculator.tyreLife") }}
            </label>
            <Field
              type="number"
              class="form-control"
              :placeholder="$t('fuelCalculator.tyreLifeLabel')"
              :aria-label="$t('fuelCalculator.tyreLifeLabel')"
              aria-describedby="basic-addon1"
              name="tyre_life"
              :rules="{ required: true }"
              :label="$t('fuelCalculator.tyreLife')"
              min="1"
              v-model="form.tyre_life"
            />
            <div class="w-100">
              <ErrorMessage
                name="tyre_life"
                class="text-danger text-small text-end m-0 pt-1"
              />
            </div>
          </MDBCol>
          <MDBCol col="12" id="5">
            <label for="fuel_consumption" class="label text-uppercase mb-1">
              {{ $t("fuelCalculator.fuelConsumption") }}
            </label>
            <Field
              type="number"
              class="form-control"
              :placeholder="$t('fuelCalculator.fuelConsumptionLabel')"
              :aria-label="$t('fuelCalculator.fuelConsumptionLabel')"
              aria-describedby="basic-addon1"
              name="fuel_consumption"
              :rules="{ required: true }"
              :label="$t('fuelCalculator.fuelConsumption')"
              min="1"
              v-model="form.fuel_consumption"
              step=".01"
            />
            <div class="w-100">
              <ErrorMessage
                name="fuel_consumption"
                class="text-danger text-small text-end m-0 pt-1"
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol col="12" lg="6" xl="4"
        ><MDBRow>
          <MDBCol col="12" id="3">
            <label for="fuel_price" class="label text-uppercase my-1">
              {{ $t("fuelCalculator.fuelPrice") }}
            </label>
            <Field
              type="number"
              class="form-control"
              :placeholder="$t('fuelCalculator.fuelPriceLabel')"
              :aria-label="$t('fuelCalculator.fuelPriceLabel')"
              aria-describedby="basic-addon1"
              name="fuel_price"
              :rules="{ required: true }"
              :label="$t('fuelCalculator.fuelPrice')"
              min="1"
              v-model="form.fuel_price"
              step=".01"
            />
            <div class="w-100">
              <ErrorMessage
                name="fuel_price"
                class="text-danger text-small text-end m-0 pt-1"
              />
            </div>
          </MDBCol>
          <MDBCol col="12" id="6">
            <label class="label text-uppercase mb-0">&nbsp;</label>
            <div>
              <MDBBtn
                type="submit"
                :class="[
                  { 'btn-danger': !props.recalculate },
                  { 'btn-outline-dark': props.recalculate },
                ]"
                :disabled="loading"
                ><span>{{
                  props.recalculate
                    ? $t("buttons.reset")
                    : $t("buttons.calculate")
                }}</span>
                <Loader
                  :show="loading"
                  :small="true"
                  spinner="spinner.svg"
                  :tiny="true"
              /></MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  </VeeForm>
</template>

<script setup>
import Loader from "@/components/General/Loader/index.vue";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { MDBRow, MDBCol, MDBBtn } from "mdb-vue-ui-kit";
import { ref, computed, defineEmits, defineProps, onMounted } from "vue";

import { useStore } from "vuex";

const props = defineProps({
  formClass: { type: String, default: "form bg-white px-2 px-xxl-5 py-4 mt-3" },
  contentClass: { type: String, default: "" },
  recalculate: { type: Boolean, default: false },
  formValue: { type: Object, default: null },
});

const store = useStore();
const loading = computed(() => store.getters.getPending);
const form = ref({
  old_label: null,
  tyre_life: null,
  fuel_price: null,
  new_label: null,
  fuel_consumption: null,
});
const fuelPercentageSave = ref(0);
const fuelPercentageDisplay = ref(0);
const newFuelConsumption = ref(0);
const savingInEur = ref(0);
const savedInLiter = ref(0);
const fuelSavedTotal = ref(0);
const labelTranslation = ref({ 1: "A", 2: "B", 3: "C", 4: "D", 5: "E" });
const emit = defineEmits(["calculated"]);

const onSubmit = () => {
  fuelPercentageSave.value =
    ((form.value.old_label - form.value.new_label) * 2.5) / 100;

  fuelPercentageDisplay.value = fuelPercentageSave.value * 100;

  newFuelConsumption.value =
    form.value.fuel_consumption * (1 - fuelPercentageSave.value);

  savedInLiter.value = form.value.fuel_consumption - newFuelConsumption.value;

  fuelSavedTotal.value = (savedInLiter.value / 100) * form.value.tyre_life;

  savingInEur.value =
    (savedInLiter.value * form.value.tyre_life * form.value.fuel_price) / 100;

  const data = {
    fuelPercentageSave: fuelPercentageSave.value,
    fuelPercentageDisplay: fuelPercentageDisplay.value,
    newFuelConsumption: newFuelConsumption.value,
    savedInLiter: savedInLiter.value,
    fuelSavedTotal: fuelSavedTotal.value,
    savingInEur: savingInEur.value,
    form: form.value,
  };

  emit("calculated", data);
};

onMounted(() => {
  if (props.formValue) {
    console.log(props.formValue);
    form.value.old_label = props.formValue.old_label;
    form.value.tyre_life = props.formValue.tyre_life;
    form.value.fuel_price = props.formValue.fuel_price;
    form.value.new_label = props.formValue.new_label;
    form.value.fuel_consumption = props.formValue.fuel_consumption;

    onSubmit();
  }
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
</style>
