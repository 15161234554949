<template>
  <CategoryTop
    :title="$t('pageName.userOrders')"
    :description="$t('order.description')"
    :breadcrumbs-parent-routes="['userOrders']"
  />
  <Loader :show="loading" v-if="loading"></Loader>
  <div v-else>
    <div v-if="order">
      <MDBCard class="mb-2">
        <MDBCardBody>
          <MDBRow>
            <MDBCol col="12" md="6">
              <div>
                <span class="fw-bold">{{ $t("order.number") }}:</span>
                {{ order.order_number }}
              </div>
              <div>
                <span class="fw-bold">{{ $t("order.createdDate") }}:</span>
                {{ dates.parseDate(order.order_date) }}
              </div>
            </MDBCol>
            <MDBCol col="12" md="6">
              <h6>{{ $t("shop.selectedAddress") }}</h6>
              <div>
                <span class="fw-bold">{{ $t("profile.streetHouseNr") }}:</span>
                {{ order.street }}
              </div>
              <div>
                <span class="fw-bold">{{ $t("profile.postcode") }}:</span>
                {{ order.postal_code }}
              </div>
              <div>
                <span class="fw-bold">{{ $t("profile.location") }}:</span>
                {{ order.city }}
              </div>
              <div>
                <span class="fw-bold">{{ $t("profile.country") }}:</span>
                {{ $t(`profile.${order.country}`) }}
              </div>
            </MDBCol>
          </MDBRow>
          <div class="pt-3">
            <MDBRow v-for="(item, index) in order.items" :key="index">
              <MDBCol col="12" md="6">
                <div
                  v-if="item.product.imageUrl"
                  class="product-image d-flex align-items-center justify-content-center"
                >
                  <img
                    :src="item.product.imageUrl"
                    alt=""
                    :placeholder="item.product.name"
                  />
                </div>
                <div class="pt-5">
                  <span class="fw-bold">{{ item.product.name }}</span>
                </div>
                <div v-if="item.shipped_at">
                  <span class="fw-bold"
                    >{{ $t("order.shippedAt") }}
                    {{ dates.parseDate(item.shipped_at) }}</span
                  >
                </div>
                <div v-if="item.product?.sku">
                  <span class="fw-bold">{{ $t("order.sku") }}:</span>
                  {{ item.product?.sku }}
                </div>
              </MDBCol>
              <MDBCol col="12" md="6"
                ><div>
                  <span class="fw-bold">{{ $t("quantity") }}:</span>
                  {{ item.quantity }}
                </div>
                <div>
                  <span class="fw-bold">{{ $t("points") }}:</span>
                  {{ item.quantity * item.product.points }}
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCardBody>
      </MDBCard>
    </div>
    <div v-else>
      <MDBCard
        ><MDBCardBody>
          <MDBRow>
            <MDBCol col="12"
              ><span class="fw-bold">{{ $t("order.anyOrder") }}</span></MDBCol
            ></MDBRow
          ></MDBCardBody
        ></MDBCard
      >
    </div>
  </div>
</template>

<script setup>
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import { MDBCard, MDBCardBody, MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { onMounted, computed } from "vue";
import Loader from "@/components/General/Loader/index.vue";
import dates from "@/plugins/dates";

const store = useStore();
const route = useRoute();

const order = computed(() => store.getters.getUserOrder);
const loading = computed(() => store.getters.getPending);

const fetchUserOrder = async () => {
  try {
    await store.dispatch("loadUserOrder", route.params.id);
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  fetchUserOrder();
});
</script>

<style scoped lang="scss">
@import "@/styles/variables";
.product-image {
  position: relative;
  top: 10px;
  right: 10px;
  width: 50%;
  height: 150px;
  z-index: 1;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
