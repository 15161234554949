import ApiService from "./api.service";

const WholesalerService = {
  async wholesalerSelect() {
    try {
      return await ApiService.get("wholesalers-select");
    } catch (error) {
      return error.response;
    }
  },
  async wshBankStatement(data) {
    try {
      return await ApiService.get(
        `wsh/user/bank_statement/${data.page ?? 0}?${data.query ?? ""}`
      );
    } catch (e) {
      console.log(e);
    }
  },

  async wholesalerSearch(id, query, page = 0) {
    try {
      let search = query ? query : "no-query";
      let response = await ApiService.get(
        `users/${id}/search/${search}?page=${page}`
      );
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async wholesalerOverview(id) {
    try {
      let response = await ApiService.get(`users/${id}/overview`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async wholesalers() {
    try {
      let response = await ApiService.get(`wholesalers`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async wholesalerLastImport(id) {
    try {
      let response = await ApiService.get(`users/${id}/lastImport`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async wholesalerPoints(id) {
    try {
      let response = await ApiService.get(`users/${id}/points`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async wholesalerKickbacks(id) {
    try {
      let response = await ApiService.get(`users/${id}/kickbacks`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async wholesalerTyres(id) {
    try {
      let response = await ApiService.get(`users/${id}/tyres`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },

  async vouchers(id) {
    try {
      let response = await ApiService.get(`users/vouchers/${id}`);
      if (response.status == 200) {
        return response.data;
      }
    } catch (e) {
      return e.response;
    }
  },
};

export { WholesalerService };
