<template>
  <div class="home">
    <props.content />
  </div>
  <div :class="[`${isGermany || isSwitzerland ? '' : 'triangle'}`]"></div>
</template>

<script setup>
import { defineProps, computed } from "vue";

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isSwitzerland = computed(() => LANG === "ch");

const props = defineProps({
  content: Object,
});
</script>
