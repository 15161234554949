<template>
  <div>
    <MDBRow>
      <MDBCol cols="12">
        <h5 class="pb-2 main-height">
          {{ $t("serviceNumber.subtitle") }}
        </h5></MDBCol
      >
    </MDBRow>
    <MDBRow class="pb-4">
      <MDBCol xl="4" cols="12">
        <div class="px-4 py-3 d-flex flex-column h-100 bg-white">
          <MDBRow class="">
            <MDBCol cols="12">
              <div class="d-flex flex-column justify-content-end h-100">
                <img
                  class="pt-1 cto"
                  src="@/assets/img/contact/sellers_ch/urs_luchinger.png"
                  alt=""
                />
                <div class="fw-bold text-danger text-uppercase pt-3">
                  {{ $t("serviceNumber.manager") }}
                </div>
                <h5>Urs Lüchinger</h5>
                <div class="font-13">
                  <span class="fw-bold">E-Mail</span>&nbsp;
                  <a href="mailto:urs.luchinger@bridgestone.eu"
                    >urs.lüchinger@bridgestone.eu</a
                  >
                </div>
                <div>
                  <span class="fw-bold">{{ $t("phoneShortCut") }}</span
                  >&nbsp;
                  <a href="tel:+49175-9314310">+41 79 903 99 59</a>
                </div>

                <div class="d-grid gap-2 pt-2">
                  <a
                    href="mailto:urs.luchinger@bridgestone.eu"
                    class="btn btn-danger text-uppercase text-white"
                    >{{ $t("buttons.makeContact") }}</a
                  >
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBCol>
      <MDBCol xl="8" cols="12">
        <div class="bg-white px-3 py-3 h-100">
          <h5 class="pb-4">{{ $t("serviceNumber.generalCustomerService") }}</h5>
          <div class="pb-1 wrapper-sells">
            <div class="fw-bold text-uppercase">
              <div class="text-danger">{{ $t("serviceNumber.warranty") }}</div>

              <span class="fw-bold">Tel.</span>&nbsp;+49 (0) 69 9999 18 – 435
              <div class="font-13">
                <span class="fw-bold">E-Mail</span>&nbsp;<a
                  href="mailto:gewaehrleistung@bridgestone.eu"
                  >gewaehrleistung@bridgestone.eu</a
                >
              </div>
            </div>
            <div class="fw-bold text-uppercase">
              <div class="text-danger">
                {{ $t("serviceNumber.commercial_vt") }}
              </div>
              <span class="fw-bold">Tel.</span>&nbsp;+49 (0) 69 9999 18 – 428
              <div class="font-13">
                <span class="fw-bold">E-Mail</span>&nbsp;<a
                  href="mailto:technik@bridgestone.eu"
                  >technik@bridgestone.eu</a
                >
              </div>
            </div>
          </div>
          <div class="pb-1 wrapper-sells">
            <div class="fw-bold text-uppercase pt-2">
              <div class="text-danger">
                {{ $t("serviceNumber.car_releases") }}
              </div>

              <span class="fw-bold">Tel.</span>&nbsp;+49 (0) 69 9999 18 – 430 o.
              432
              <div class="font-13">
                <span class="fw-bold">E-Mail</span>&nbsp;<a
                  href="mailto:technik@bridgestone.eu"
                  >technik@bridgestone.eu</a
                >
              </div>
            </div>
            <div class="fw-bold text-uppercase pt-2">
              <div class="text-danger">
                {{ $t("serviceNumber.agricultural_tires") }}
              </div>
              <div>
                <span class="fw-bold">Tel.</span>&nbsp;+49 (0) 69 9999 18 – 3134
                <div class="font-13">
                  <span class="fw-bold">E-Mail</span>&nbsp;<a
                    href="mailto:technik@bridgestone.eu"
                    >technik@bridgestone.eu</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="pb-1 wrapper-sells">
            <div class="fw-bold text-uppercase pt-2">
              <div class="text-danger">
                {{ $t("serviceNumber.motorcycle_releases") }}
              </div>

              <span class="fw-bold">Tel.</span>&nbsp;+49 (0) 69 9999 18 – 255
              <div class="font-13">
                <span class="fw-bold">E-Mail</span>&nbsp;<a
                  href="mailto:mc-technik@bridgestone.eu"
                  >mc-technik@bridgestone.eu</a
                >
              </div>
            </div>
            <div class="fw-bold text-uppercase pt-2">
              <div class="text-danger">E-Business</div>

              <span class="fw-bold">Tel.</span>&nbsp;+49 (0) 69 9999 18 – 274
              <div class="font-13">
                <span class="fw-bold">E-Mail</span>&nbsp;<a
                  href="mailto:econtact-de@bridgestone.eu "
                  >econtact-de@bridgestone.eu
                </a>
              </div>
            </div>
          </div>

          <div class="pt-5 mt-2">
            <div class="fw-bold text-uppercase font-25">
              {{ $t("serviceNumber.centralEmail") }}
            </div>
            <div class="font-17">
              <span class="fw-bold">E-Mail</span>&nbsp;<a
                class="text-danger"
                href="mailto:customerservice@bridgestone.eu"
                >customerservice@bridgestone.eu</a
              >
            </div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol lg="4" class="mb-4" v-for="(data, i) in salesmenDataDe" :key="i"
        ><Card
          :data="data"
          :linkText="data.linkText"
          :simpleLink="true"
          target="_blank"
        />
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import Card from "@/components/General/Card/index.vue";
import i18n from "@/plugins/i18n";

const salesmenDataDe = [
  {
    name: "Gian-Piero Morga",
    link: "mailto:gian-piero.morga@bridgestone.eu",
    description: `<span class="font-13"><b>E-Mail:</b>gian-piero.morga@bridgestone.eu<br/><b>${i18n.global.t(
      "phoneShortCut"
    )}</b> +41 79 455 20 59</span>`,
    image: require("@/assets/img/contact/sellers_ch/gian_piero_morga.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Brandon Villard",
    link: "mailto:brandon.villard@bridgestone.eu",
    description: `<span class="font-13"><b>E-Mail:</b>brandon.villard@bridgestone.eu<br/><b>${i18n.global.t(
      "phoneShortCut"
    )}</b> +41 79 669 74 00</span>`,
    image: require("@/assets/img/contact/sellers_ch/brandon_villard.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Jean-Louis Marchon",
    link: "mailto:jean-louis.marchon@bridgestone.eu",
    description: `<span class="font-13"><b>E-Mail:</b>jean-louis.marchon@bridgestone.eu<br/><b>${i18n.global.t(
      "phoneShortCut"
    )}</b> +41 79 662 40 02</span>`,
    image: require("@/assets/img/contact/sellers_ch/jean_louis_marchon.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Christof Rupp",
    link: "mailto:christof.rupp@bridgestone.eu",
    description: `<span class="font-13"><b>E-Mail:</b>christof.rupp@bridgestone.eu<br/><b>${i18n.global.t(
      "phoneShortCut"
    )}</b> +41 79 677 19 16</span>`,
    image: require("@/assets/img/contact/sellers_ch/christof_rupp.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Nastassia Gallace",
    link: "mailto:nastassia.gallace@bridgestone.eu",
    description: `<span class="font-13"><b>E-Mail:</b>nastassia.gallace@bridgestone.eu<br/><b>${i18n.global.t(
      "phoneShortCut"
    )}</b> +41 79 324 10 73</span>`,
    image: require("@/assets/img/contact/sellers_ch/nastassia_gallace.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Oshan Ari",
    link: "mailto:oshan.ari@bridgestone.eu",
    description: `<span class="font-13"><b>E-Mail:</b>oshan.ari@bridgestone.eu<br/><b>${i18n.global.t(
      "phoneShortCut"
    )}</b> + 41 79 248 67 46</span>`,
    image: require("@/assets/img/contact/sellers_ch/oshan_ari.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "<h6 class='text-danger'>KEY ACCOUNT MANAGER AUTOHAUS</h6> Konrad Schütz",
    link: "mailto:konrad.schuetz@bridgestone.eu",
    description: `<span class="font-13"><b>E-Mail:</b>konrad.schuetz@bridgestone.eu<br/><b>${i18n.global.t(
      "phoneShortCut"
    )}:</b> +41 79 629 29 99</span>`,
    image: require("@/assets/img/contact/sellers_ch/konrad_schutz.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "<h6 class='text-danger'>KEY ACCOUNT MANAGER HANDEL</h6> Patrik Tschachtli",
    link: "mailto:patrik.tschachtli@bridgestone.eu",
    description: `<span class="font-13"><b>E-Mail:</b>patrik.tschachtli@bridgestone.eu<br/><b>${i18n.global.t(
      "phoneShortCut"
    )}</b>: +41 79 218 38 63</span>`,
    image: require("@/assets/img/contact/sellers_ch/patrik_tschachtli.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "<h6 class='text-danger'>MARKETING</h6> Anne Scheel",
    link: "mailto:anne.scheel@bridgestone.eu",
    description: `<span class="font-13"><b>E-Mail:</b>anne.scheel@bridgestone.eu<br/><b>${i18n.global.t(
      "phoneShortCut"
    )}</b>: +41 79 152 92 44</span>`,
    image: require("@/assets/img/contact/sellers_ch/anne_scheel.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "<h6 class='text-danger'>Customer Service</h6> Thomas Moser",
    link: "mailto:customerservice@bridgestone.eu",
    description: `<span class="font-13"><b>E-Mail:</b>customerService@bridgestone.eu<br/><b>${i18n.global.t(
      "phoneShortCut"
    )}</b>: 0800 55 50 55</span>`,
    image: require("@/assets/img/contact/sellers_ch/karl_moser.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },

  {
    name: "<h6 class='text-danger'>Customer Service</h6> Sandro Huber",
    link: "mailto:customerservice@bridgestone.eu",
    description: `<span class="font-13"><b>E-Mail:</b>customerService@bridgestone.eu<br/><b>${i18n.global.t(
      "phoneShortCut"
    )}</b>: 0800 55 50 55</span>`,
    image: require("@/assets/img/contact/sellers_ch/peter_huber.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
];
</script>

<style scoped lang="scss">
@import "@/styles/contact";

.wrapper-sells {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
