<template>
  <div>
    <CategoryTop :breadcrumbs-parent-routes="['trainings', 'onlineTrainings']" :breadcrumbs-route-name="training.title"
      class="mb-3" :showBreadcrumbs="true" contentClass="" mainClass="training-container" />
    <MDBRow>
      <MDBCol xl="5" cols="12" class="mb-1">
        <div class="lightboxContainer">
          <div class="lightbox" @click="showLightBox = true"></div>
          <iframe v-if="training && training.video" class="vimeo" frameborder="0" :src="training.video"
            allow="fullscreen" title="" data-ready="true" tabindex="-1"></iframe>
        </div>
      </MDBCol>
      <MDBCol xl="7" cols="12" class="mb-1">
        <div class="textBox h-auto textBox__training">
          <h5 v-if="training" v-html="training.title"></h5>
          <div v-if="training" v-html="training.short_description"></div>
        </div>
      </MDBCol>
    </MDBRow>

    <Lightbox v-if="showLightBox" :video="training.video" @close="(showValue) => (showLightBox = showValue)" />
  </div>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import Lightbox from "./../Form/Inputs/Lightbox.vue";
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const route = useRoute();

store.dispatch("me");

let showLightBox = ref(false);
const isWholesaler = ref(false);

const fetchTraining = async () => {
  await store.dispatch("loadTrainingById", route.params.id);
  await fetchWatchedVideo();
};

const user = computed(() => store.getters.getMe);
const training = computed(() => store.getters.getTraining);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
});

const fetchWatchedVideo = async () => {
  const data = {
    user_id: user.value.id,
    training_id: training.value.id,
  };
  await store.dispatch("loadWatchedVideo", data);
};

fetchTraining();
</script>

<style scoped lang="scss">
@import "../../../styles/_variables.scss";

.vimeo {
  width: 100%;
  height: 300px;
}

.breadcrumbContainer {
  padding: 28px 0;
}
</style>
