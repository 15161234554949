<template>
  <CategoryTop :title="title" :breadcrumbs-parent-routes="['preislisten', 'preislistenList']"
    :description="$t('productList.pkw.description')" />
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import i18n from "@/plugins/i18n";
const store = useStore();
const route = useRoute();

const files = computed(() => store.getters.getSinglePriceList);
const title = computed(() => {
  if (files.value && route.name == "pkw") {
    return i18n.global.t(`productList.${route.params.slug}.title`);
  }
  return "";
});
</script>
