import ApiService from "./api.service";

const HunterService = {
  async hunters() {
    return await ApiService.get("userHunters");
  },

  async hunter(id) {
    return await ApiService.get(`hunterProduct/detail/${id}/show`);
  },

  async storeHunter(data) {
    try {
      return await ApiService.post("hunterProduct", data);
    } catch (error) {
      return error.response;
    }
  },
};

export { HunterService };
