<template>
  <CategoryTop
    :title="$t('pageName.productInfoAndPriceList')"
    :description="$t('productInfo.page.description')"
  />

  <MDBRow>
    <MDBCol lg="4" class="mb-4" v-for="(training, i) in trainings" :key="i">
      <router-link
        :to="training.link"
        class="training text-center d-flex align-items-center justify-content-center p-3"
      >
        <h3
          v-html="training.name"
          class="training-title font-40 font-lg-30 font-xxl-40"
        ></h3>
      </router-link>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed } from "vue";
import i18n from "@/plugins/i18n";

const trainings = computed(() => [
  {
    name: i18n.global.t("productInfo.categories.info"),
    link: { name: "productInfo" },
  },
  {
    name: i18n.global.t("productInfo.categories.priceList"),
    link: { name: "preislisten" },
  },
  {
    name: i18n.global.t("productInfo.categories.contact"),
    link: { name: "contact_service" },
  },
]);
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.training {
  background: linear-gradient(
    142deg,
    rgba(205, 205, 205, 1) 0%,
    rgba(97, 97, 97, 1) 100%
  );
  min-height: 200px;
}

.training-title {
  color: white;
}
</style>
