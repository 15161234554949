import Product from "@/components/Shop/Product.vue";
import ProductFooter from "@/components/Shop/Content/ProductFooter.vue";
import Default from "../views/Default.vue";
import SubDefault from "../views/SubDefault.vue";
import Category from "@/components/Shop/Category.vue";
import CategoryListContent from "@/components/Shop/Category/Content/List.vue";
import ShopIndex from "@/components/Shop/index.vue";
import CategoryList from "@/components/Shop/Category/List.vue";
import Order from "@/views/Order.vue";
import OrderSummary from "@/components/Shop/Order/OrderSummary.vue";
import OrderDelivery from "@/components/Shop/Order/OrderDelivery.vue";
import OrderComplete from "@/components/Shop/Order/OrderComplete.vue";
import Cart from "@/views/Cart.vue";
import OrderIndex from "@/components/Shop/OrderList/index.vue";
import FavoriteProducts from "@/components/Shop/FavoriteProducts/index.vue";
import UserOrder from "@/components/Shop/SingleOrder/index.vue";
import HunterShop from "@/components/Hunter/index.vue";
import HunterShopList from "@/components/Hunter/Content/List.vue";
import HunterShopSingle from "@/components/Hunter/SingleHunter/index.vue";
import HunterSingleContent from "@/components/Hunter/Content/Single.vue";
import InvestmentSupport from "@/components/InvestmentSupport/index.vue";
import InvestmentSupportData from "@/components/InvestmentSupport/Content/Data.vue";
import SingleColumn from "@/views/SingleColumn.vue";

export default [
  {
    path: "/shop",
    name: "shop",
    component: SubDefault,
    props: {
      content: ShopIndex,
      switchOrderMobile: true,
      data: CategoryList,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },
  {
    path: "/shop/products",
    name: "shopProducts",
    component: Default,
    props: {
      content: Category,
      switchOrderMobile: true,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },
  {
    path: "/shop/cart",
    name: "warenkorb",
    component: Cart,
    meta: {
      requiredRole: ["dealer"],
      requiredLang: ["de", "ch", "nl"],
      permission: "cartIcon.see",
    },
  },
  {
    path: "/shop/order",
    name: "order",
    component: Order,
    meta: {
      requiredRole: ["dealer"],
      requiredLang: ["de", "ch", "nl"],
    },
    children: [
      {
        path: "delivery",
        name: "orderDelivery",
        component: OrderDelivery,
        meta: {
          requiredLang: ["de", "ch", "nl"],
        },
      },
      {
        path: "summary",
        name: "orderSummary",
        component: OrderSummary,
        meta: {
          requiredLang: ["de", "ch", "nl"],
        },
      },
      {
        path: "complete",
        name: "orderComplete",
        component: OrderComplete,
        meta: {
          requiredLang: ["de", "ch", "nl"],
        },
      },
    ],
  },
  {
    path: "/shop/categories/:id",
    name: "shopCategory",
    component: SubDefault,
    props: {
      content: Category,
      switchOrderMobile: true,
      data: CategoryListContent,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
    query: {
      page: 1,
    },
  },
  {
    path: "/shop/products/:id/detail",
    name: "productDetails",
    component: Default,
    props: {
      content: Product,
      switchOrderMobile: true,
      data: ProductFooter,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },
  {
    path: "/shop/user/orders",
    name: "userOrders",
    component: SingleColumn,
    meta: {
      requiredRole: ["dealer"],
      requiredLang: ["de", "ch", "nl"],
      permission: "orderList.see",
    },
    props: {
      content: OrderIndex,
      switchOrderMobile: true,
    },
  },
  {
    path: "/shop/user/favorite",
    name: "favoriteProducts",
    component: SingleColumn,
    meta: {
      requiredRole: ["dealer"],
      requiredLang: ["de", "ch", "nl"],
      permission: "orderList.see",
    },
    props: {
      content: FavoriteProducts,
      switchOrderMobile: true,
    },
  },
  {
    path: "/shop/user/order/:id",
    name: "userOrder",
    component: Default,
    meta: {
      requiredRole: ["dealer"],
      requiredLang: ["de", "ch", "nl"],
    },
    props: {
      content: UserOrder,
      switchOrderMobile: true,
    },
  },
  {
    path: "/shop/hunter",
    name: "hunterShop",
    component: SubDefault,
    meta: {
      requiredRole: ["dealer", "staff"],
      requiredLang: ["de", "ch"],
    },
    props: {
      content: HunterShop,
      switchOrderMobile: true,
      data: HunterShopList,
    },
  },

  {
    path: "/shop/hunter/:id",
    name: "hunter",
    component: Default,
    meta: {
      requiredRole: ["dealer", "staff"],
      requiredLang: ["de", "ch"],
    },
    props: {
      content: HunterShopSingle,
      switchOrderMobile: true,
      data: HunterSingleContent,
    },
  },

  {
    path: "/investment-support",
    name: "investment",
    component: SubDefault,
    meta: {
      requiredRole: ["dealer", "staff"],
      requiredLang: ["de", "ch"],
    },
    props: {
      content: InvestmentSupport,
      switchOrderMobile: true,
      data: InvestmentSupportData,
    },
  },
];
