import TrainingsOverview from "@/components/Trainings/index.vue";
import TrainingsOverviewList from "@/components/Trainings/Content/List.vue";
import SingleTraining from "@/components/Trainings/Single/index.vue";
import SingleTrainingContent from "@/components/Trainings/Single/Content/index.vue";
import OnlineTrainings from "@/components/Trainings/Online/index.vue";
import OnlineTrainingList from "@/components/Trainings/Online/Content/List.vue";
import SchulungenTrainings from "@/components/Trainings/Schulungen/index.vue";
import SchulungenTrainingsList from "@/components/Trainings/Schulungen/Content/List.vue";
import SingleSchulungenTraining from "@/components/Trainings/SingleSchulungen/index.vue";
import SingleSchulungenTrainingContent from "@/components/Trainings/SingleSchulungen/Content/index.vue";
import ExterneTraining from "@/components/Trainings/Externe/index.vue";
import ExterneTrainingContent from "@/components/Trainings/Externe/Content/index.vue";
import Default from "../views/Default.vue";
import SubDefault from "../views/SubDefault.vue";
import Form from "@/components/Trainings/Form";
import FormContent from "@/components/Trainings/Form/Content/index.vue";

export default [
  {
    path: "/trainings",
    name: "trainings",
    component: SubDefault,
    props: {
      content: TrainingsOverview,
      switchOrderMobile: true,
      data: TrainingsOverviewList,
    },
    meta: {
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/online-trainings",
    name: "onlineTrainings",
    component: SubDefault,
    props: {
      content: OnlineTrainings,
      switchOrderMobile: true,
      data: OnlineTrainingList,
    },
    meta: {
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/bridgestone-training-courses",
    name: "bridgestoneSchulungen",
    component: SubDefault,
    props: {
      content: SchulungenTrainings,
      switchOrderMobile: true,
      data: SchulungenTrainingsList,
    },
    meta: {
      requiredRole: ["dealer", "staff"],
      requiredLang: ["de", "ch"],
      permission: "bTraining.see",
    },
  },
  {
    path: "/bridgestone-training-courses/:id",
    name: "bridgestoneSingleSchulungen",
    component: Default,
    props: {
      content: SingleSchulungenTraining,
      switchOrderMobile: true,
      data: SingleSchulungenTrainingContent,
    },
    meta: {
      requiredRole: ["dealer", "staff"],
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/training/:id",
    name: "training",
    component: Default,
    props: {
      content: SingleTraining,
      switchOrderMobile: true,
      data: SingleTrainingContent,
    },
    meta: {
      requiredRole: ["dealer", "staff"],
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/training/:id/form",
    name: "trainingForm",
    component: Default,
    props: {
      content: Form,
      switchOrderMobile: true,
      data: FormContent,
    },
    meta: {
      requiredRole: ["dealer", "staff"],
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/externe-training",
    name: "externeTraining",
    component: SubDefault,
    props: {
      content: ExterneTraining,
      switchOrderMobile: true,
      data: ExterneTrainingContent,
    },
    meta: {
      requiredRole: ["dealer", "staff"],
      requiredLang: ["de", "ch"],
      permission: "externalTraining.see",
    },
  },
];
