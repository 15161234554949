<template>
  <router-link
    :to="props.to"
    class="link"
    :disabled="props.disabled"
    :class="`${props.disabled ? 'disable-link' : ''}`"
  >
    <slot></slot> <MDBIcon icon="chevron-right" size="1x" />
  </router-link>
</template>

<script setup>
import { MDBIcon } from "mdb-vue-ui-kit";
import { defineProps } from "vue";

const props = defineProps({
  to: Object,
  disabled: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/variables";
.link {
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  color: #333;
  position: absolute;
  bottom: 17px;
  left: 1.5rem;
  transition: 250ms ease-in-out;

  @media screen and (max-width: $xlBreakpoint) {
    bottom: 15px;
  }

  &:hover {
    .fas {
      right: -3px;
    }
  }

  .fas {
    font-size: 0.7em;
    position: relative;
    top: -1px;
    right: 0;
    transition: 250ms ease-in-out;
  }
}
</style>
