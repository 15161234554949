<template>
  <MDBCard class="border-0">
    <CategoryTop small :title="$t('profile.title')" :breadcrumbs-parent-routes="['account']"
      :description="$t('profile.description')" content-class="px-4 mt-4" left-col="6" right-col="6">
      <template #right>
        <MDBRow>
          <MDBCol col="12" class="mb-5">
            <div class="progress">
              <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated" role="progressbar"
                :style="'width:' + fillPercent + '%'" :aria-valuenow="fillPercent" aria-valuemin="0"
                aria-valuemax="100">
                {{ fillPercent }}%
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </template>
    </CategoryTop>
    <VeeForm class="form user-form" @submit="onSubmit">
      <MDBRow>
        <MDBCol xl="4" md="6" class="mb-4">
          <PersonalData ref="personalData"></PersonalData>
        </MDBCol>

        <MDBCol xl="4" md="6" class="mb-4">
          <OperatingData ref="operatingData" @diffAddress="diffAddress"></OperatingData>
        </MDBCol>

        <MDBCol xl="3" md="6" class="mb-4">
          <div>
            <label for="legalForm" class="label px-3 text-uppercase pt-5">
              {{ $t("profile.legalForm") }}
            </label>
            <input type="text" class="form-control" :placeholder="$t('profile.legalForm')"
              :aria-label="$t('profile.legalForm')" aria-describedby="basic-addon1" v-model="legalForm"
              id="legalForm" />
          </div>

          <div class="switch-wrapper d-flex flex-column justify-content-center pt-4">
            <div class="form-check form-switch">
              <input v-model="diffDeliveryAddress" class="form-check-input" type="checkbox" role="switch"
                name="switch_addr" />
              <label class="form-check-label font-12" for="switch_addr">{{
                $t("profile.diffDeliveryAddres")
              }}</label>
            </div>
          </div>
          <DeliveryData v-show="diffDeliveryAddress" ref="deliveryData"></DeliveryData>
        </MDBCol>
      </MDBRow>
      <MDBRow v-if="!isWholesaler && !isStaff && !isWholesalerStaff">
        <MDBCol cols="12" class="mb-4">
          <ClientNumbers></ClientNumbers>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol col="12" class="mb-4">
          <OpeningHoursData ref="openingHoursData"></OpeningHoursData>
          <IndicatorsData ref="indicatorsData"></IndicatorsData>
        </MDBCol>
        <MDBCol col="12" class="mb-4">
          <Field type="checkbox" name="conditions" v-model="conditions" :value="true" :unchecked-value="false"
            class="form-check-input" :label="$t('profile.acceptTerms')" :rules="{ required: true }"></Field>
          <span class="px-2 text-decoration-underline--hover cursor--pointer" @click="pushToRules()">{{
            $t("profile.acceptTerms") }}</span>
          <div>
            <ErrorMessage name="conditions" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol col="12" class="text-start">
          <div v-html="$t('profile.newCustomerNumbersInfo')" class="pb-3" v-if="!isWholesaler && !isStaff"></div>
          <div v-if="!isGermany">
            <div v-if="me.salespersons_dealers && me.salespersons_dealers.length > 0" class="pb-4">
              <div v-for="(seller, index) in me.salespersons_dealers" :key="index">
                <div v-if="!seller.is_internal">
                  <span class="fw-bold">{{ $t("contact.form.contactPerson") }}:</span>
                  {{ seller.name }}
                  <a :href="'mailto:' + seller.email">{{ seller.email }}</a>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            {{ $t('profile.contactInfo') }} <a href="mailto:plus@bridgestone.eu">plus@bridgestone.eu</a>
          </div>

          <MDBBtn type="submit" color="danger" :disabled="loading"
            class="d-flex justify-content-center align-items-center">
            <span>{{ $t("buttons.sendButton") }}</span>
            <Loader :show="loading" :small="true" spinner="spinner.svg" :tiny="true" />
          </MDBBtn>
          <p v-if="apiError" class="text-danger text-small text-end m-0 pt-2 text-small">
            {{ apiError }}
          </p>
        </MDBCol>
      </MDBRow>
    </VeeForm>
  </MDBCard>
</template>

<script setup>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import { MDBRow, MDBCol, MDBCard, MDBBtn } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { ref, onMounted, computed, toRaw, watch } from "vue";
import PersonalData from "@/components/User/ProfileForms/PersonalData.vue";
import OperatingData from "@/components/User/ProfileForms/OperatingData.vue";
import DeliveryData from "@/components/User/ProfileForms/DeliveryData.vue";
import OpeningHoursData from "@/components/User/ProfileForms/OpeningHoursData.vue";
import IndicatorsData from "@/components/User/ProfileForms/IndicatorsData.vue";
import ClientNumbers from "@/components/User/ProfileForms/ClientNumbers.vue";
import Loader from "@/components/General/Loader/index.vue";

import "vue3-toastify/dist/index.css";
import { useRouter } from "vue-router";
import textTransform from "@/plugins/textTransform";

import { ProfileService } from "@/services/profile.service";
import { TextPageService } from "@/services/textPage.service";
import TokenService from "@/services/storage.service";

import { fetchUserProfile, afterSuccess } from "@/composables/profile.js";

const store = useStore();
const router = useRouter();
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const personalData = ref(null);
const operatingData = ref(null);
const deliveryData = ref(null);
const openingHoursData = ref(null);
const indicatorsData = ref(null);
const diffDeliveryAddress = ref(false);
const apiError = ref(null);
const legalForm = ref(null);
const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isStaff = ref(false);
const conditions = ref(false);
const errors = ref(false);
const loading = computed(() => store.getters.getPending);
const profile = computed(() => store.getters.getProfile);
const me = computed(() => store.getters.getMe);

const diffAddress = (bool) => {
  diffDeliveryAddress.value = bool;
};

const profilePercentage = computed(() => {
  return sessionStorage.getItem("percentage") || store.getters.getPercentageNum;
});

const onSubmit = async () => {
  const { vat, email, username, id, ...personal } = personalData.value.models;
  const { ...operating } = operatingData.value.models;
  const { lang, ...mainAddress } = operating;

  let args = {
    id,
    user: {
      username,
      email,
      country: operating.country,
      lang,
      vat,
      is_active: true,
    },
    profile: {
      user_id: id,
      legalForm: legalForm.value,
      ...personal,
      ...openingHoursData.value.models,
      ...mainAddress,
      ...indicatorsData.value.models,
      conditions: conditions.value
    },
    addresses: [mainAddress],
  };

  if (diffDeliveryAddress.value) {
    args.addresses.push(toRaw(deliveryData.value.models));
  }

  if (!diffDeliveryAddress.value && deliveryData.value.models.id) {
    deliveryData.value.models.to_remove = true;
    args.addresses.push(toRaw(deliveryData.value.models));
  }

  if (args.user.country !== "ch") {
    args.user.lang = "de";
  }

  const response = await store.dispatch("updateUser", args);

  if (response.status === 200) {
    await fetchUserProfile(store);
    await afterSuccess(
      id,
      profile.value.id,
      fillPercent.value,
      me.value.plainId,
      store
    );

    apiError.value = null;
  } else {
    apiError.value = response.data.message;
  }
};

const fillPercent = computed(() => {
  let legalFormPercentage = legalForm.value ? 100 : 0;

  let percentages = [
    personalData.value?.percentage,
    operatingData.value?.percentage,
    !diffDeliveryAddress.value ? 100 : deliveryData.value?.percentage,
    openingHoursData.value?.percentage,
    indicatorsData.value?.percentage,
    legalFormPercentage,
  ];

  let length = percentages.length;

  return Math.round(percentages.reduce((acc, a) => acc + a, 0) / length);
});

watch(me, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isStaff.value = true;
  }
  if (profilePercentage.value == 100 && TokenService.showElement("welcomePoints.see")) {
    await ProfileService.storeFullProfilePoints(me.value.plainId);
  }
});

watch(
  () => indicatorsData.value?.errors,
  (newVal) => {
    if (Object.values(newVal).findIndex((item) => item == true) == -1) {
      errors.value = false;
    } else {
      errors.value = true;
    }
  },
  {
    deep: true,
  }
);

watch(
  () => store.getters.getProfile,
  (newVal) => {
    legalForm.value = newVal.legalForm;
  }
);

watch(diffDeliveryAddress, (newVal) => {
  diffAddress(newVal);
});

onMounted(async () => {
  await fetchUserProfile(store);
  const { id } = personalData.value.models;

  const data = {
    user_id: id,
    profile_id: profile.value.id,
    percentage: fillPercent.value,
  };

  await store.dispatch("storeProfilePercentage", data);
});

const pushToRules = async () => {
  const response = await TextPageService.textPageBySlug("terms-and-conditions");

  if (response.status == 200) {
    router.push({
      name: "text_page",
      params: {
        id: response.data.data.id,
        slug: textTransform.convertToSlug(response.data.data.title),
      },
    });
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
@import "@/styles/profile.scss";
</style>
