<template>
  <LanguageSelect v-if="step == 0" />
  <div v-else>
    <img
      :src="require('@/assets/img/B_MarkLogo.svg')"
      alt="logo"
      v-if="!isGermany"
      class="bs-logo-nl"
    />
    <div class="background-wrap">
      <div
        class="background"
        :class="[
          `${isGermany || isSwitzerland ? 'background--de' : 'background--nl'}`,
        ]"
      ></div>
    </div>

    <div class="login-container px-2">
      <MDBRow :class="[`${isGermany || isSwitzerland ? 'pt-5 mt-5' : ''}`]">
        <MDBCol lg="12" xl="4" class="d-flex justify-content-center">
          <div
            class="d-flex flex-column justify-content-center align-items-center text-slider-wrapper"
          >
            <MDBCard
              v-if="!maintenance"
              class="border-0 box-shadow card--height"
            >
              <div
                class="w-100 d-flex justify-content-center"
                v-if="!isGermany || !isSwitzerland"
              >
                <img
                  :src="require('@/assets/img/bs_logo_solution.svg')"
                  alt="logo"
                />
              </div>
              <MDBCardBody class="card--height">
                <MDBCardTitle class="text-start font-35 mb-3">
                  <div v-html="$t('loginPageSteps.step3.title')"></div>
                </MDBCardTitle>
                <MDBCardText class="pb-0">
                  <p
                    class="font-16"
                    v-html="$t('loginPageSteps.step3.description')"
                  ></p>
                  <p
                    class="font-16"
                    v-html="
                      $t('loginPageSteps.info', {
                        email: 'plus-nl@bridgestone.eu',
                      })
                    "
                    v-if="isNetherlands"
                  ></p>
                </MDBCardText>
                <ButtonMediumVue
                  className="btn btn-danger btn-lg d-flex justify-content-center align-items-center font-12 btn-position ripple-surface"
                  div-class-name="text-white fw-bold margin-reset font-22"
                  property1="default"
                  :text="$t('loginPageSteps.step3.actionBtn')"
                  @click="redirectAzure"
                  :disableState="loading"
                />
              </MDBCardBody>
            </MDBCard>
            <Maintenance v-if="maintenance"></Maintenance>
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  </div>
</template>

<script setup>
import config from "@/config";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBRow,
} from "mdb-vue-ui-kit";
import Maintenance from "../Maintenance.vue";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import ButtonMediumVue from "../General/Buttons/ButtonMedium.vue";
import LanguageSelect from "../MainPage/LanguageSelect.vue";

import { MaintenanceService } from "@/services/maintanace.service";

const store = useStore();
const azureLoginUrl = `${config.URL}/b2c`;

const maintenance = ref(false);
const step = ref(1);

const loading = computed(() => store.getters.getPending);

const redirectAzure = () => {
  window.location.href = azureLoginUrl;
};
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isSwitzerland = computed(() => LANG === "ch");
const isNetherlands = computed(() => LANG === "nl");

const langSelected = localStorage.getItem("langSelected");

onMounted(async () => {
  step.value = isSwitzerland.value && !langSelected ? 0 : 1;
  const response = await MaintenanceService.maintenance();

  maintenance.value = Object.keys(response.data).length > 0;
});
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/auth";
</style>
