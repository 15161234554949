<template>
  <div class="px-3 py-3 intro__title">{{ title }}</div>
  <div class="px-3 py-3" v-html="description"></div>
  <div class="d-flex justify-content-between align-items-center px-3 pb-3 pt-5">
    <div class="text-uppercase intro__step intro__step--width">
      {{ user.intro }} {{ $t("of") }} {{ introMaxSteps }}
    </div>
    <div class="d-flex align-items-center justify-content-between w-100">
      <span class="px-2 cursor--pointer" @click="updateIntro(introMaxSteps)">{{
        $t("buttons.skip")
      }}</span>
      <div>
        <MDBBtn
          class="btn btn-outline-primary"
          @click="updateIntro('back')"
          v-if="user?.intro > 1"
          >{{ $t("buttons.back") }}</MDBBtn
        >
        <MDBBtn color="primary" @click="updateIntro('next')">{{
          $t("buttons.next")
        }}</MDBBtn>
      </div>
    </div>
  </div>
</template>

<script setup>
import { MDBBtn } from "mdb-vue-ui-kit";
import i18n from "@/plugins/i18n";

import { computed } from "vue";
import { useStore } from "vuex";
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isNetherland = computed(() => LANG === "nl");

const store = useStore();
const user = computed(() => store.getters.getMe);

const introMaxSteps = computed(() => {
  if (isGermany.value) {
    return 3;
  } else if (isNetherland.value) {
    return 4;
  } else {
    return 3;
  }
});

const title = computed(() => {
  if (user.value?.intro == 1) {
    return i18n.global.t("intro.step1.title");
  } else if (user.value?.intro == 2) {
    return i18n.global.t("intro.step2.title");
  } else if (user.value?.intro == 3) {
    return i18n.global.t("intro.step3.title");
  } else {
    return i18n.global.t("intro.step4.title");
  }
});

const description = computed(() => {
  if (user.value?.intro == 1) {
    return i18n.global.t("intro.step1.description");
  } else if (user.value?.intro == 2) {
    return i18n.global.t("intro.step2.description");
  } else if (user.value?.intro == 3) {
    return i18n.global.t("intro.step3.description");
  } else {
    return i18n.global.t("intro.step4.description");
  }
});

const updateIntro = async (step) => {
  let currentStep = 0;
  if (introMaxSteps.value == step) {
    currentStep = introMaxSteps.value + 1;
  } else if (step == "back") {
    currentStep = user.value.intro - 1;
  } else {
    currentStep = user.value.intro + 1;
  }

  const data = {
    id: user.value.id,
    intro: currentStep,
  };
  await store.dispatch("updateIntro", data);
  await store.dispatch("me");
};
</script>
