<template>
  <MDBRow>
    <MDBCol col="12" md="6" xl="4" v-for="(training, i) in trainings" :key="i">
      <router-link
        :to="training.link"
        class="brochure text-center p-3 mb-4"
        :class="[
          { 'box-bg': !training.background },
          { 'img-bg': training.background },
        ]"
      >
        <div class="boxContainer w-100">
          <div class="w-100">
            <img
              :src="training.image"
              :alt="training.name"
              v-if="training.image"
            />
            <h3
              v-if="!training.image"
              v-html="training.name"
              class="training-title font-32"
            ></h3>
          </div>

          <MDBBtn type="button" color="danger" class="w-100">{{
            $t("buttons.toDownload")
          }}</MDBBtn>
        </div>
      </router-link>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol, MDBBtn } from "mdb-vue-ui-kit";
import { computed } from "vue";
import i18n from "@/plugins/i18n";
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");

const trainings = computed(() => [
  {
    name: "Bridgestone",
    link: { name: "bridgestoneProduktbroschuren" },
    image: require("@/assets/img/bslogo2.png"),
    background: null,
  },
  {
    name: "Firestone",
    link: { name: "firestoneProduktbroschuren" },
    image: isGermany.value
      ? require("@/assets/img/firestoneLogo2.png")
      : require("@/assets/img/firestone_logo_nl.png"),
    background: null,
  },
  {
    name: i18n.global.t("pageName.technologien"),
    link: { name: "technologien" },
    background: require("@/assets/img/bg.png"),
  },
]);
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";

.boxContainer {
  display: flex;
  align-items: self-end;
  flex-wrap: wrap;
  height: 180px;
}
.brochure {
  min-height: 300px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.box-bg {
  background: #fff;
}

.img-bg {
  background-image: url("~@/assets/img/bg.png");
  background-size: cover;
}

.training-title {
  color: white;
}
</style>
