const dates = {
  parseDate: (date) => {
    if (!date) return;
    return new Date(date).toLocaleDateString("ro-RO");
  },
  compareSortValues: (key, order = "asc") => {
    return function innerSort(a, b) {
      if (key === "last_login" || key === "solved_at") {
        const varA = a[key];
        const varB = b[key];

        if (order === "desc") {
          return new Date(varA) - new Date(varB);
        }
        return new Date(varB) - new Date(varA);
      } else {
        const varA =
          a[key] !== null && typeof a[key] === "string"
            ? a[key].toUpperCase()
            : typeof a[key] === "number"
            ? a[key] // Handle numbers directly
            : ""; // Default to empty string for other types

        const varB =
          b[key] !== null && typeof b[key] === "string"
            ? b[key].toUpperCase()
            : typeof b[key] === "number"
            ? b[key]
            : "";

        let comparison = varA.localeCompare(varB);
        return order === "desc" ? comparison * -1 : comparison;
      }
    };
  },
};

export default dates;
