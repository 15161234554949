<template>
  <Germany v-if="isGermany || isSwitzerland" />
  <Netherland v-if="isDutch" />
</template>

<script setup>
import Germany from "@/components/User/Profile/Country/Germany.vue";
import Netherland from "@/components/User/Profile/Country/Netherland.vue";

import { computed } from "vue";
const LANG = localStorage.getItem("lang");
const isDutch = computed(() => LANG === "nl");
const isGermany = computed(() => LANG === "de");
const isSwitzerland = computed(() => LANG === "ch");
</script>
