<template>
  <div id="personal_data">
    <h4 class="mb-3 text-uppercase">{{ $t("profile.personalData") }}</h4>

    <label for="email" class="label px-3 text-uppercase">
      {{ $t("profile.email") }}*
    </label>
    <input
      type="email"
      class="form-control"
      :placeholder="$t('profile.yourEmailAddress')"
      :aria-label="$t('profile.yourEmailAddress')"
      aria-describedby="basic-addon1"
      v-model="models.email"
      disabled
      id="email"
    />
    <MDBRow>
      <MDBCol col="12">
        <label for="anrede" class="label px-3 text-uppercase">
          {{ $t("profile.formOfAddress") }}*
        </label>
        <select
          class="form-select"
          :aria-label="$t('profile.formOfAddress')"
          id="anrede"
          v-model="models.salutation"
        >
          <option disabled :value="null">{{ $t("profile.choose") }}</option>
          <option :value="$t('profile.mr')">{{ $t("profile.mr") }}</option>
          <option :value="$t('profile.mrs')">{{ $t("profile.mrs") }}</option>
          <option :value="$t('profile.diverse')" v-if="isGermany">
            {{ $t("profile.diverse") }}
          </option>
        </select>
      </MDBCol>
    </MDBRow>
    <label for="firstname" class="label px-3 text-uppercase">
      {{ $t("profile.firstname") }}*
    </label>
    <Field
      type="text"
      class="form-control"
      :placeholder="$t('profile.yourFirstName')"
      :aria-label="$t('profile.firstname')"
      aria-describedby="basic-addon1"
      v-model="models.forename"
      id="firstname"
      :rules="{ required: props.requredSection }"
      name="firstname"
      :label="$t('profile.firstname')"
    />
    <div>
      <ErrorMessage
        name="firstname"
        class="text-danger text-small text-end m-0 pt-1"
      />
    </div>
    <label for="surname" class="label px-3 text-uppercase">
      {{ $t("profile.surname") }}*
    </label>
    <Field
      type="text"
      class="form-control"
      :placeholder="$t('profile.yourLastName')"
      :aria-label="$t('profile.yourLastName')"
      aria-describedby="basic-addon1"
      v-model="models.name"
      :rules="{ required: props.requredSection }"
      name="name"
      :label="$t('profile.surname')"
    />
    <div>
      <ErrorMessage
        name="name"
        class="text-danger text-small text-end m-0 pt-1"
      />
    </div>
    <MDBRow>
      <MDBCol col="12">
        <label for="position" class="label px-3 text-uppercase">
          {{ $t("profile.position") }}*
        </label>
        <select
          class="form-select"
          :aria-label="$t('profile.positionLabel')"
          id="position"
          v-model="models.job_position_id"
        >
          <option disabled :value="null">
            {{ $t("profile.positionLabel") }}
          </option>
          <option
            :value="jobPosition.id"
            v-for="(jobPosition, index) in jobPositions"
            :key="index"
          >
            {{ jobPosition.name }}
          </option>
        </select>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { Field, ErrorMessage } from "vee-validate";
import { computed, ref, reactive, watch, defineExpose,defineProps } from "vue";
import { useStore } from "vuex";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");

const props = defineProps({
  requredSection: {type: Boolean, default: false},
});

const store = useStore();

const percentage = ref(0);

const jobPositions = computed(() => store.getters.getJobPositions);

const indexToPercentage = ["email", "salutation", "forename", "name"];

const models = reactive({
  email: null,
  salutation: null,
  forename: null,
  name: null,
  job_position_id: null,
  username: null,
  id: null,
  user_id: null,
});

watch(
  () => {
    return indexToPercentage.map((item) => models[item]);
  },
  (newVal) => {
    percentage.value =
      (newVal.filter((i) => i).length / indexToPercentage.length) * 100;
  }
);

watch(
  () => store.getters.getMe,
  (newVal) => {
    models.email = newVal.email;
    models.username = newVal.username;
    models.id = newVal.id;
  }
);

watch(
  () => store.getters.getProfile,
  (newVal) => {
    models.salutation = newVal.salutation;
    models.forename = newVal.forename;
    models.name = newVal.name;
    models.job_position_id = newVal.job_position_id;
    models.user_id = newVal.user_id;
  }
);

defineExpose({
  models,
  percentage,
});
</script>
