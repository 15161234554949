<template>
  <MDBRow>
    <MDBCol lg="4" md="6" class="mb-4" v-for="(productInfo, i) in productsInfo" :key="i"
      :class="{ 'd-none': !productInfo.show }">
      <Card :data="productInfo" minHeight="container-height" v-if="productInfo.show"
        :simpleLink="productInfo.simpleLink" />
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed } from "vue";
import Card from "@/components/General/Card/index.vue";
import i18n from "@/plugins/i18n";
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isSwitzerland = computed(() => LANG === "ch");
const isNetherland = computed(() => LANG === "nl");

const productsInfo = computed(() => [
  {
    name: i18n.global.t("productInfo.index.categories.productBrochures.title"),
    link: { name: "produktbroschuren" },
    description: i18n.global.t(
      "productInfo.index.categories.productBrochures.description"
    ),
    image: require(`@/assets/img/brochures/produktbroschuren_${LANG}.png`),
    show: isNetherland.value,
    simpleLink: false,
  },
  {
    name: i18n.global.t("productInfo.index.categories.bDrive.title"),
    link:
      isSwitzerland.value || isGermany.value
        ? { name: "bDrive" }
        : "https://www.b-drive.com/en.html",
    description: i18n.global.t(
      "productInfo.index.categories.bDrive.description"
    ),
    image: require("@/assets/img/ProduktabbildungenAndLogo.png"),
    show: isSwitzerland.value || isGermany.value || isNetherland.value,
    simpleLink: isNetherland.value,
  },
]);
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
</style>
