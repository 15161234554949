import { createRouter, createWebHistory } from "vue-router";
import BankStatement from "../views/BankStatement";
import Shop from "./Shop";
import Auth from "./Auth";
import Info from "./Info";
import ProductInfo from "./ProductInfo";
import Trainings from "./Trainings";
import Default from "../views/Default.vue";
import Dashboard from "@/components/Dashboard/index";
import ExampleProducts from "@/components/Dashboard/ExampleProducts";
import PageNotFound from "../views/404.vue";
import AccessDenied from "../views/AccessDenied.vue";
import User from "./User";
import store from "../store";
import TokenService from "@/services/storage.service";

const routes = [
  {
    path: "/bank_statement",
    name: "BankStatement",
    component: BankStatement,
    meta: {
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Default,
    props: {
      content: Dashboard,
      data: ExampleProducts,
    },
    meta: {
      requiredRole: ["dealer", "admin", "staff"],
      requiredLang: ["de", "nl", "ch"],
    },
  },
  ...Shop,
  ...Auth,
  ...Trainings,
  ...ProductInfo,
  ...User,
  ...Info,
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: PageNotFound,
  },
  {
    path: "/access-denied",
    name: "accessDenied",
    component: AccessDenied,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // Check required role
  let requiredRole = to.meta.requiredRole;
  let requiredLang = to.meta.requiredLang;
  let requiredPermission = to.meta.permission;
  const lang = localStorage.getItem("lang");

  if (requiredRole) {
    try {
      const response = await store.dispatch("me");
      let user = store.getters.getMe;
      if (
        (response.status === 401 && response.statusText === "Unauthorized") ||
        !user
      ) {
        next("/");
        return;
      }

      if (requiredPermission) {
        let haveAccess = TokenService.showElement(requiredPermission);

        if (!haveAccess) {
          next("/access-denied");
        }
      }

      let role = user.roles.find((item) => requiredRole.includes(item.name));
      if (!role || !requiredLang.includes(lang)) {
        next("/account/dashboard");
        return;
      }
    } catch (e) {
      console.log(e);
      next("/dashboard");
      return;
    }
  }

  next();
  return;
});

export default router;
