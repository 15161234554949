<template>
  <MDBCol col="12" md="4">
    <Loader v-if="loaders.overview" :show="loaders.overview" />
    <DashboardCard
      :title="$t('wholesaler.dashboard.overview.title')"
      :description="$t('wholesaler.dashboard.overview.description')"
    >
      <template v-if="overview" v-slot:table>
        <MDBTable borderless class="table-dashboard">
          <thead>
            <tr>
              <th>{{ $t("wholesaler.dashboard.overview.all") }}</th>
              <th>{{ overview.all }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t("wholesaler.dashboard.overview.month") }}</td>
              <td>{{ overview.per_month }}</td>
            </tr>
            <tr>
              <td>{{ $t("wholesaler.dashboard.overview.year") }}</td>
              <td>{{ overview.per_year }}</td>
            </tr>
          </tbody>
        </MDBTable>
      </template>
    </DashboardCard>
  </MDBCol>
  <MDBCol col="12" md="4">
    <Loader v-if="loaders.points" :show="loaders.points" />
    <DashboardCard
      :title="
        $t('wholesaler.dashboard.credits.title', {
          name: name,
        })
      "
      :description="$t('wholesaler.dashboard.credits.description')"
    >
      <template v-if="points" v-slot:table>
        <MDBTable borderless class="table-dashboard">
          <thead>
            <tr>
              <th>{{ $t("wholesaler.dashboard.credits.month") }}</th>
              <th>{{ points.month }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t("wholesaler.dashboard.credits.year") }}</td>
              <td>{{ points.year }}</td>
            </tr>
            <tr>
              <td>{{ $t("wholesaler.dashboard.credits.prev_year") }}</td>
              <td>{{ points.prev_year }}</td>
            </tr>
          </tbody>
        </MDBTable>
      </template>
    </DashboardCard>
  </MDBCol>
  <MDBCol col="12" md="4">
    <Loader v-if="loaders.lastImport" :show="loaders.lastImport" />
    <DashboardCard v-else>
      <template v-if="lastImport" v-slot:content>
        <div
          v-html="
            $t('wholesaler.dashboard.lastImport', {
              date: dates.parseDate(lastImport),
            })
          "
        ></div>
      </template>
    </DashboardCard>
  </MDBCol>
</template>

<script setup>
import { MDBCol, MDBTable } from "mdb-vue-ui-kit";

import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";

import { WholesalerService } from "@/services/wholesaler.service.js";

import DashboardCard from "@/components/User/Wholesaler/DashboardCard.vue";
import Loader from "@/components/General/Loader/index.vue";

import dates from "@/plugins/dates";

const store = useStore();

const me = computed(() => store.getters.getMe);

const name = computed(() => {
  return me.value.whs ? me.value.whs.wholesaler.name : me.value.name;
});

const overview = ref(null);
const points = ref(null);
const lastImport = ref(null);
const loaders = ref({
  overview: true,
  points: true,
  lastImport: true,
});

onMounted(async () => {
  let whsId = me.value.whs ? me.value.whs.wholesaler_id : me.value.id;

  overview.value = await WholesalerService.wholesalerOverview(whsId);
  loaders.value.overview = false;
  lastImport.value = await WholesalerService.wholesalerLastImport(whsId);
  loaders.value.lastImport = false;
  points.value = await WholesalerService.wholesalerPoints(whsId);
  loaders.value.points = false;
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/table_dashboard.scss";
</style>
