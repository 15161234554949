<template>
  <div class="number-input">
    <label v-if="props.label">{{ props.label }}</label>
    <div class="input-wrapper">
      <button class="action" @click="decrease" :disabled="loading">-</button>
      <div class="value">
        {{ val }}
      </div>
      <button class="action" @click="increase" :disabled="loading">+</button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ref, onMounted, watch, defineProps, defineEmits, computed } from "vue";

const val = ref(null);
const store = useStore();

const props = defineProps({
  label: String,
  modelValue: Number,
  step: {
    type: Number,
    required: true,
  },
  min: {
    type: Number,
    required: true,
  },
  max: Number,
});

const emit = defineEmits(["update:modelValue"]);
const loading = computed(() => store.getters.getPending);

const decrease = () => {
  if (val.value - props.step >= props.min) {
    val.value -= props.step;
    emit("update:modelValue", val.value);
  }
};

const increase = () => {
  if (props.max !== 0) {
    if (!props.max) {
      val.value += props.step;
    }
    if (props.max && val.value + props.step <= props.max) {
      val.value += props.step;
    }
    emit("update:modelValue", val.value);
  }
};

watch(props, (newVal) => {
  if (newVal.modelValue) {
    val.value = newVal.modelValue;
  }
});

onMounted(() => {
  val.value = props.modelValue;
});
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;
  height: 30px;
}

.input-wrapper .action,
.input-wrapper .value {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  background: #fff;
}

.input-wrapper .action {
  border: 1px solid rgb(217, 217, 217);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;
}

.input-wrapper .value {
  border-top: 1px solid rgb(217, 217, 217);
  border-bottom: 1px solid rgb(217, 217, 217);
}

.input-wrapper input {
  max-width: 80px;
  margin: 0;
  min-width: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
