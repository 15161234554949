<template>
  <CategoryTop
    small
    :title="$t('pobox.title')"
    :breadcrumbs-parent-routes="['account']"
    :description="$t('pobox.desc')"
  />

  <div class="mail-wrapper">
    <MDBRow class="mail-header justify-contend-between d-flex">
      <MDBCol col="1" class="checkbox-col">
        <input
          class="mb-0 form-check-input"
          @click="toggleCheckAll()"
          type="checkbox"
          name="checkbox"
        />
        <button type="button" class="btn font-18">
          <MDBIcon icon="ellipsis-v" size="1x" />
        </button>
      </MDBCol>
      <MDBCol col="3" class="sender-col">
        <p class="mb-0 fw-bold d-none d-md-block">
          {{ $t("pobox.message.sender") }}
        </p>
      </MDBCol>
      <MDBCol col="6" class="topic-col d-none d-md-block">
        <p class="mb-0 fw-bold">{{ $t("pobox.message.thread") }}</p>
      </MDBCol>
      <MDBCol col="2" class="date-col d-none d-md-block">
        <p class="mb-0 fw-bold">{{ $t("bankstatement.date") }}</p>
      </MDBCol>
    </MDBRow>

    <MDBRow
      v-for="(mail, key) in messages"
      v-bind:key="key"
      class="mail-row justify-contend-between d-flex"
    >
      <MDBCol md="1" xs="2" class="checkbox-col order-1">
        <input
          class="mb-0 form-check-input"
          v-model="checkboxes"
          :value="mail.id"
          type="checkbox"
          name="checkbox"
        />
      </MDBCol>
      <MDBCol
        md="3"
        xs="6"
        :class="`sender-col order-3 order-md-2 ${
          mail.unreaded ? 'fw-bold' : ''
        }`"
        @click="openMailReader(mail.id)"
      >
        {{ mail.sender }}
      </MDBCol>
      <MDBCol
        md="6"
        xs="6"
        :class="`topic-col order-4 order-md-3 ${
          mail.unreaded ? 'fw-bold' : ''
        }`"
        @click="openMailReader(mail.id)"
      >
        {{ mail.topic }}
      </MDBCol>
      <MDBCol
        md="2"
        xs="6"
        :class="`date-col order-2 order-md-4 ${mail.unreaded ? 'fw-bold' : ''}`"
        @click="openMailReader(mail.id)"
      >
        {{ mail.date }}
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol>
        <button class="btn btn-danger mt-4" @click="deleteSelected()">
          {{ $t("clear") }}
        </button>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";
import { ref, computed, toRaw } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import i18n from "@/plugins/i18n";
import CategoryTop from "@/components/General/CategoryTop/index.vue";

const store = useStore();
const router = useRouter();
const lang = computed(() => localStorage.getItem("lang"));

store.dispatch("loadMessages");

const messages = computed(() => {
  return store.getters.getMessages.map((item) => {
    return {
      id: item.id,
      sender: i18n.global.t(`pobox.sender.${item.threads.sender}`),
      topic: item.messages.title[lang.value],
      date: new Date(item.created_at).toLocaleDateString("ro-RO"),
      unreaded: !item.is_read,
    };
  });
});

const checkboxes = ref([]);

const toggleCheckAll = () => {
  if (
    checkboxes.value.length >= 0 &&
    checkboxes.value.length !== messages.value.length
  ) {
    checkboxes.value = [];
    checkboxes.value = messages.value.map((item) => item.id);
  } else {
    checkboxes.value = [];
  }
};

const openMailReader = (id) => {
  store.dispatch("setMessageIsRead", id);
  router.push({ name: "singleMailbox", params: { id: id } });
};

const deleteSelected = async () => {
  if (checkboxes.value.length > 0) {
    await store.dispatch("deleteMessages", toRaw(checkboxes.value));
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";

.mail-wrapper {
  font-size: 18px;
}
.checkbox-col {
  max-width: 100px;
  display: flex;
  align-items: center;
  button {
    margin: 0 auto;
    padding: 0;
  }
}

.date-col {
  max-width: 120px;
}

.mail-header {
  background: white;
  padding: 10px 5px;
  margin: 0;
  text-transform: uppercase;
}

.mail-row {
  margin: 0;
  background: $transitionBlue;
  padding: 5px;
  border-bottom: 1px solid darken($transitionBlue, 10%);
  transition: 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    background: darken($transitionBlue, 10%);
  }
}

.form-check-input[type="checkbox"] {
  margin-top: 0;
}

.mail-reader {
  background: white;
  padding: 25px;
  min-height: 250px;
}

.mail-reader-content {
  padding-top: 15px;
  border-top: 1px solid $borderLightGray;
}

.topic-col,
.sender-col {
  @media screen and (max-width: $mdBreakpoint) {
    margin-left: 40px;
  }
}

.buttons-container {
  max-width: 225px;
  position: absolute;
  top: 38px;
  right: 25px;

  @media screen and (max-width: $lgBreakpoint) {
    max-width: 120px;
  }

  button {
    @media screen and (max-width: $lgBreakpoint) {
      padding: 0 10px;
    }
  }
}

.sender-row,
.topic-row {
  padding-right: 250px;

  @media screen and (max-width: $lgBreakpoint) {
    padding-right: 150px;
  }
}
</style>
