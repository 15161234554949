<template>
  <div>
    <RegisterForm
      :training="training"
      :trainingTypes="trainingTypes"
      @refresh="refresh()"
    />
  </div>
</template>

<script setup>
import RegisterForm from "@/components/Trainings/SingleSchulungen/Form/Register.vue";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const route = useRoute();
const user = computed(() => store.getters.getMe);

const fetchTraining = () => {
  store.dispatch("loadBTrainingById", route.params.id);
};

const fetchTrainingCosts = () => {
  store.dispatch("loadBTrainingCosts");
};

const fetchTrainingDetails = () => {
  store.dispatch("loadBTrainingDetails", route.params.id);
};

const fetchTrainingTypes = () => {
  store.dispatch("loadBTrainingTypes");
};

const fetchTrainingResult = async () => {
  const data = { trainingId: route.params.id, userId: user.value.id };
  await store.dispatch("loadBTrainingResult", data);
};

const refresh = () => {
  fetchTraining();
  fetchTrainingDetails();
  fetchTrainingCosts();
  fetchTrainingTypes();
  store.dispatch("me");
  fetchTrainingResult();
};

onMounted(() => {
  refresh();
});

const training = computed(() => store.getters.getBTraining);
const trainingTypes = computed(() => store.getters.getBTrainingTypes);
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
</style>
