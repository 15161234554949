import ApiService from "./api.service";

const SliderService = {
  async sliders(userId) {
    try {
      return await ApiService.get(`userSlides/${userId}`);
    } catch (e) {
      console.log(e);
    }
  },

  async banners() {
    try {
      return await ApiService.get(`default-banners`);
    } catch (e) {
      console.log(e);
    }
  },

  async mainBoxes(userId) {
    try {
      return await ApiService.get(`main-boxes/${userId}`);
    } catch (e) {
      console.log(e);
    }
  },
};

export { SliderService };
