<template>
  <Loader :show="loading" v-if="loading"></Loader>
  <MDBRow v-else>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import Loader from "@/components/General/Loader/index";
import { MDBRow } from "mdb-vue-ui-kit";
import FileItem from "@/components/ProductInfo/FileItem.vue";

import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, onMounted } from "vue";

const store = useStore();
const route = useRoute();

const fetchPrices = async () => {
  try {
    await store.dispatch("loadSinglePriceList", route.params.slug);
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  fetchPrices();
});

const files = computed(() => store.getters.getSinglePriceList);
const loading = computed(() => store.getters.getPending);
</script>
