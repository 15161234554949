import ApiService from "./api.service";

const MaintenanceService = {
  async maintenance() {
    try {
      return await ApiService.get("maintenance");
    } catch (error) {
      console.log(error);
    }
  },
};

export { MaintenanceService };
