<template>
  <Loader :show="loading" v-if="loading"></Loader>
  <MDBRow v-else>
    <MDBCol col="12" v-if="productApi">
      <MDBCard>
        <MDBCardBody>
          <div v-html="productApi.description"></div>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import Loader from "@/components/General/Loader/index";
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed, } from "vue";
const store = useStore();

const productApi = computed(() => store.getters.getProduct);
const loading = computed(() => store.getters.getPending);

</script>

<style scoped lang="scss">
@import "@/styles/variables";

:deep(.product-image-wrap),
:deep(.product-main-data) {
  height: 100%;
}

@media screen and (min-width: $smBreakpoint) {
  :deep(.product-image) {
    max-height: 300px;
  }
}

@media screen and (min-width: $mdBreakpoint) {
  :deep(.product-image) {
    max-height: 300px;
  }
}

@media screen and (min-width: $lgBreakpoint) {
  :deep(.product-image) {
    max-height: 410px;
  }
}

@media screen and (min-width: $xlBreakpoint) {
  :deep(.product-image) {
    max-height: 330px;
  }
}

@media screen and (min-width: $xxlBreakpoint) {
  :deep(.product-image) {
    max-height: 371px;
  }
}

@media screen and (min-width: $xxxlBreakpoint) {
  :deep(.product-image) {
    max-height: 470px;
  }
}

.productBox {
  margin-bottom: 20px;

  @media screen and (min-width: $lgBreakpoint) {
    height: auto;
  }

  @media screen and (min-width: $xlBreakpoint) {
    height: 405px;
  }

  @media screen and (min-width: $xxlBreakpoint) {
    height: 445px;
  }

  @media screen and (min-width: $xxxlBreakpoint) {
    height: 545px;
  }
}

:deep(.product-image) {
  height: fit-content;
}
</style>
