import Auth from "../views/Auth.vue";
import Login from "@/components/MainPage/Login.vue";

export default [
  {
    path: "/",
    name: "login",
    component: Auth,
    props: {
      content: Login,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },
];
