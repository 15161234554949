import "mdb-vue-ui-kit/css/mdb.min.css";
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import API from "./config";
import axios from "axios";
import VueAxios from "vue-axios";
import ApiService from "./services/api.service";
import i18n from "./plugins/i18n";

import { defineRule, configure } from "vee-validate";
import AllRules from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import de from "@vee-validate/i18n/dist/locale/de.json";
import en from "@vee-validate/i18n/dist/locale/en.json";
import nl from "@vee-validate/i18n/dist/locale/nl.json";
import ch from "./i18n/ch_vee_validate.json";
import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

import Vue3Toastify, { toast } from "vue3-toastify";

configure({
  generateMessage: localize({
    de,
    nl,
    ch,
    en,
  }),
});
setLocale(API.LANG);

Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

ApiService.init(API.URL);

createApp(App)
  .use(VueAxios, axios)
  .use(router)
  .use(store)
  .use(i18n)
  .use(Vue3Toastify, {
    autoClose: 3000,
    position: toast.POSITION.TOP_RIGHT,
    transition: toast.TRANSITIONS.SLIDE,
  })
  .mount("#app");
