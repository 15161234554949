<template>
  <MDBCol
    v-if="props.show"
    :class="[
      props.small ? 'text-center small ' : 'text-center mt-3 mb-5',
      props.tiny ? 'tiny' : '',
    ]"
  >
    <img
      class="loader"
      :src="require(`../../../assets/img/${spinner}`)"
      alt="spinner"
    />
  </MDBCol>
</template>

<script setup>
import { MDBCol } from "mdb-vue-ui-kit";
import { defineProps } from "vue";

const props = defineProps({
  show: Boolean,
  small: Boolean,
  tiny: { type: Boolean, default: false },
  spinner: {
    type: String,
    default: "spinner-red.svg",
  },
});
</script>

<style scoped lang="scss">
@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: rotation infinite 10s linear reverse;
  max-height: 100%;
}

.small {
  height: 50px;
}
.tiny {
  height: 20px;
}
</style>
