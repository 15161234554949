<template>
    <div class="d-flex pb-5">
        <input v-model="searchText" :placeholder="$t('search')" @change="clearSearch()" aria-label="Search"
            aria-describedby="button-addon" :disabled="loader" class="form-control bg-white"
            @keyup.enter="searchItem" />
        <input type="number" :placeholder="$t('productSearchBox.from')" v-model="pointsFrom" class="form-control"
            @keyup.enter="searchItem" />

        <input type="number" :placeholder="$t('productSearchBox.to')" v-model="pointsTo" class="form-control"
            @keyup.enter="searchItem" />
        <MDBIcon icon="search" class="align-self-center ms-2 cursor--pointer" @click="searchItem()" />
    </div>
    <Loader :show="loader" v-if="loader" />
    <div v-else>
        <div v-if="searchProducts.length > 0" class="ps-2 pb-3 pt-3 mb-3 bg-white">
            <div v-for="(product, index) in searchProducts" :key="index" class="mb-2">
                <router-link :to="{ name: 'productDetails', params: { id: product.value } }" class="fw-bold ">
                    <div class="d-flex link-container">
                        <img :src="productImgSrc(product)" class="container__img" :alt="product.label" />
                        <p>{{
                            product.label }} -
                            {{ productPoints(product)
                            }} {{ $t('points') }}</p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>

</template>

<script setup>
import { MDBIcon } from "mdb-vue-ui-kit";
import Loader from "../General/Loader/index.vue";
import { ref, onMounted } from "vue";
import { ProductService } from "@/services/product.service";
import TokenService from "@/services/storage.service";

const searchText = ref(null)
const searchProducts = ref([])
const pointsFrom = ref(null)
const pointsTo = ref(null)
const loader = ref(false)

const clearSearch = () => {
    searchProducts.value = []
}
const searchItem = async () => {
    if (!searchText.value && !pointsFrom.value && !pointsTo.value) {
        return false
    }
    loader.value = true
    const text = searchText.value == '' ? null : searchText.value
    const pointsMin = pointsFrom.value == '' ? null : pointsFrom.value
    const pointsMax = pointsTo.value == '' ? null : pointsTo.value
    const response = await ProductService.getProductsByName(text, pointsMin, pointsMax)

    TokenService.setProductData(text, pointsFrom.value, pointsTo.value)
    if (response.length > 0) {
        searchProducts.value = response.map((item) => {
            return {
                label: item.name,
                value: item.id,
                points: item.points,
                images: item.imageUrl,
                variants: item.variants,
                type: item.type
            }
        })
    } else {
        searchProducts.value = []
    }

    loader.value = false
}

const productImgSrc = (data) => {
    return data.variants.length > 0
        ? data.variants[0]?.image
        : data.images;
};

const productPoints = (data) => {
    return data.variants.length > 0
        ? data.variants[0]?.points
        : data.points;
};

onMounted(async () => {
    searchText.value = TokenService.getProductName() == 'null' ? null : TokenService.getProductName()
    pointsFrom.value = TokenService.getProductPointsFrom()
    pointsTo.value = TokenService.getProductPointsTo()

    if (pointsTo.value == 'null' && pointsFrom.value == 'null' && !searchText.value) {
        return
    }

    if (pointsTo.value || pointsFrom.value || searchText.value) {
        await searchItem()
    }
})
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";

.container__img {
    max-width: 100px;

    flex: 0 0 auto;
    margin-right: 20px;
}

.link-container {
    &:hover {
        border: 1px solid $dangerRed;
        margin: 4px;
    }
}
</style>