<template>
  <MDBRow>
    <MDBCol col="9" class="order-1" v-if="props.loader">
      <Loader :show="props.loader" :small="true" />
    </MDBCol>
    <MDBCol lg="9" class="order-2 order-lg-1" v-else>
      <button v-if="categories && categories.length" type="button" @click="() => (categoriesOpen = !categoriesOpen)"
        class="d-block d-lg-none categories-toggle">
        <MDBIcon icon="filter" size="1x" />
      </button>
      <MDBNavbar expand="lg" :class="`categories mb-5 d-lg-flex ${categoriesOpen ? 'd-block' : 'd-none'
        }`">
        <MDBNavbarNav id="categoriesNavbar" collapse="categoriesNavbar"
          class="mb-2 mb-lg-0 align-items-stretch flex-wrap">
          <MDBNavbarItem v-for="(category, i) in categories" :key="i" class="text-center categories-link-wrap mb-2">
            <span @click="changeCategory(category.id)" :class="`cursor--pointer font-12 categories-link d-flex align-items-center ${category.id === route.params.id
              ? 'categories-link--active text-white'
              : 'text-dark'
              }`" v-html="category.name">
            </span>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBNavbar>
    </MDBCol>
    <MDBCol lg="3" class="order-1 order-lg-2 mb-3 mb-lg-0">
      <div class="sort-select-wrap mb-3">
        <select v-model="sortBy" class="sort-select font-12" @change="$emit('sortChanged', sortBy)">
          <option value="asc">{{ $t("shop.sort.asc") }}</option>
          <option value="desc">{{ $t("shop.sort.desc") }}</option>
        </select>
        <MDBIcon class="font-10 sort-icon" icon="chevron-down" size="1x" />
      </div>
      <div class="sort-select-wrap" v-if="isNetherland">
        <select v-model="perPage" class="sort-select font-12" @change="$emit('perPageChanged', perPage)">
          <option value="9">{{ $t('shop.perPgae') }}</option>
          <option value="9">9</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
        <MDBIcon class="font-10 sort-icon" icon="chevron-down" size="1x" />
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed, ref, defineEmits, defineProps } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import TokenService from "@/services/storage.service";
import Loader from "../General/Loader/index.vue";
defineEmits(["sortChanged"]);

const props = defineProps({
  loader: Boolean,
});
const LANG = localStorage.getItem("lang");
const isNetherland = computed(() => LANG === "nl");
const store = useStore();
const route = useRoute();
const router = useRouter();
const sortBy = ref("asc");
const categoriesOpen = ref(false);
const perPage = ref(9)

if (!store.getters.getCategories.length) {
  store.dispatch("loadCategories");
}

const categories = computed(() => {
  let category = store.state.categories.find(
    (item) => item.id == route.params.id
  );

  if (category?.parent_id == null && category?.children) {
    return [...category?.children].sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  } else {
    return [
      ...store.state.categories.filter(
        (item) => item.parent_id == category.parent_id
      ),
    ].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }
});

const changeCategory = (categoryId) => {
  TokenService.setProductPage(1)
  router.push({ name: 'shopCategory', params: { id: categoryId }, query: { page: 1 } })
}
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/sort_select.scss";

.categories {
  padding-top: 0;
  box-shadow: 0 0 0 0 black;
}

.categories-link {
  background: $secondaryGray;
  padding: 10px;
  margin-right: 5px;
  min-height: 100%;
  line-height: 140%;
}

.categories-link-wrap:last-child {
  .categories-link {
    margin-right: 0px;
  }
}

.categories-toggle {
  margin-top: -43px;
  margin-bottom: 25px;
  position: relative;
  z-index: 10;
  appearance: none;
  border: 0px solid $lightGray;
  padding: 0px 10px;
  font-size: 18px;
  background: transparent;
  color: $darkGray;
}

.categories-link--active {
  background: $dangerRed;
}
</style>
