import SingleColumn from "@/views/SingleColumn.vue";
import User from "@/components/User/index.vue";
import PointsOverview from "@/components/User/PointsOverview.vue";
import Stammdaten from "@/components/User/Stammdaten.vue";
import Index from "@/components/User/Mailbox/Index.vue";
import View from "@/components/User/Mailbox/View.vue";
import Dashboard from "@/components/User/Wholesaler/Dashboard.vue";
import SellerDashboard from "@/components/User/Seller/Dashboard.vue";
import StaffVouchers from "@/components/User/Seller/Voucher/index.vue";
import WholesalerVouchers from "@/components/User/Wholesaler/Voucher/index.vue";

export default [
  {
    path: "/account",
    name: "account",
    component: SingleColumn,
    props: {
      content: User,
    },
    meta: {
      requiredLang: ["de", "nl", "ch"],
    },
  },
  {
    path: "/account/points",
    name: "pointsOverview",
    component: SingleColumn,
    props: {
      content: PointsOverview,
    },
    meta: {
      requiredRole: ["dealer"],
      requiredLang: ["de", "nl", "ch"],
      permission: "accountStatement.see",
    },
  },
  {
    path: "/account/user-profile",
    name: "stammdaten",
    component: SingleColumn,
    props: {
      content: Stammdaten,
    },
    meta: {
      requiredRole: ["dealer"],
      requiredLang: ["de", "nl", "ch"],
      permission: "profilePage.see",
    },
  },
  {
    path: "/account/mailbox",
    name: "mailbox",
    component: SingleColumn,
    props: {
      content: Index,
    },
    meta: {
      requiredLang: ["de", "nl", "ch"],
    },
  },
  {
    path: "/account/mailbox/:id",
    name: "singleMailbox",
    component: SingleColumn,
    props: {
      content: View,
    },
    meta: {
      requiredLang: ["de", "nl", "ch"],
    },
  },
  {
    path: "/account/dashboard",
    name: "wholesaler",
    component: SingleColumn,
    props: {
      content: Dashboard,
    },
    meta: {
      requiredRole: ["wholesaler", "wholesalerStaff"],
      requiredLang: ["de", "nl", "ch"],
    },
  },
  {
    path: "/staff/dashboard",
    name: "staff",
    component: SingleColumn,
    props: {
      content: SellerDashboard,
    },
    meta: {
      requiredRole: ["staff"],
      requiredLang: ["de", "nl", "ch"],
    },
  },
  {
    path: "/staff/vouchers",
    name: "staffVouchers",
    component: SingleColumn,
    props: {
      content: StaffVouchers,
    },
    meta: {
      requiredRole: ["staff"],
      requiredLang: ["nl"],
    },
  },
  {
    path: "/wholesaler/vouchers",
    name: "wholesalerVouchers",
    component: SingleColumn,
    props: {
      content: WholesalerVouchers,
    },
    meta: {
      requiredRole: ["wholesaler", "wholesalerStaff"],
      requiredLang: ["nl"],
    },
  },
];
