<template>
  <MDBContainer class="p-0">
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle class="mb-5">{{
          $t("pageName.orderDelivery")
          }}</MDBCardTitle>
        <MDBCardText class="pb-0">
          <h6>{{ $t("shop.selectAddressP") }}</h6>
          <div v-if="addresses" class="addresses row mt-3">
            <div v-for="(address, id) in addresses" :key="`address_${id}`" class="address col-md-4">
              <div class="row gx-2">
                <div class="col-md-4">{{ $t("profile.company") }}:</div>
                <div class="col-md-8">
                  {{ address.company_name ?? profile.company }}
                </div>
              </div>
              <div class="row gx-2">
                <div class="col-md-4">
                  {{ $t("contact.form.contactPerson") }}:
                </div>
                <div class="col-md-8">
                  {{ address.first_name }} {{ address.last_name }}
                </div>
              </div>
              <div class="row gx-2">
                <div class="col-md-4">{{ $t("profile.streetHouseNr") }}:</div>
                <div class="col-md-8">{{ address.street }}</div>
              </div>
              <div class="row gx-2">
                <div class="col-md-4">{{ $t("profile.postcode") }}:</div>
                <div class="col-md-8">{{ address.postal_code }}</div>
              </div>
              <div class="row gx-2">
                <div class="col-md-4">{{ $t("profile.location") }}:</div>
                <div class="col-md-8">{{ address.city }}</div>
              </div>
              <div class="row gx-2">
                <div class="col-md-4">{{ $t("profile.country") }}:</div>
                <div class="col-md-8">
                  {{ $t(`profile.${address.country}`) }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 my-3">
                  <MDBBtn class="btn" :class="[
                    selectedAddress && selectedAddress.id == address.id
                      ? 'btn-primary'
                      : 'btn-outline-primary',
                  ]" @click="selectAddress(address)">
                    {{ $t("shop.selectAddressBtn") }}
                  </MDBBtn>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <NewAddress />
            </div>
          </div>
        </MDBCardText>
      </MDBCardBody>
      <MDBCardFooter class="text-muted">
        <MDBBtn color="success" :disabled="!selectedAddress || !overPoints" @click="toOrderSummary">{{
          $t("shop.toOrderSummary") }}</MDBBtn>
        <MDBBtn color="primary" @click="back">{{ $t("back") }}</MDBBtn>
      </MDBCardFooter>
    </MDBCard>
  </MDBContainer>
</template>

<script setup>
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn,
} from "mdb-vue-ui-kit";
import { computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import NewAddress from "@/components/Shop/Order/NewAddress.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import i18n from "@/plugins/i18n";

const router = useRouter();
const store = useStore();
const selectedAddress = ref(null);

const addresses = computed(() => store.getters.getUserAddresses);
const profile = computed(() => store.getters.getProfile);

const user = computed(() => store.getters.getMe);
const cart = computed(() => store.getters.getCart);
const overPoints = computed(() => {
  return user.value.points >= cart.value.total;
});

const selectAddress = (data) => {
  store.dispatch("setAddressSelected", data.id);
  selectedAddress.value = data;
};

const back = () => {
  router.go(-1);
};

const toOrderSummary = () => {
  if (
    !selectedAddress.value.first_name ||
    !selectedAddress.value.last_name ||
    !selectedAddress.value.company_name ||
    !selectedAddress.value.city ||
    !selectedAddress.value.country ||
    !selectedAddress.value.street ||
    !selectedAddress.value.postal_code
  ) {
    toast(i18n.global.t("toastMessages.incomplete_address"), {
      autoClose: 10000,
      type: "error",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } else {
    router.push({
      name: "orderSummary",
    });
  }
};

onMounted(() => {
  store.dispatch("me");
  store.dispatch("loadUnreadMessages");
  store.dispatch("loadUserAddresses");
  store.dispatch("fetchProfile");
  store.dispatch("fetchUserShopCart");
});
</script>

<style lang="scss" scoped></style>
