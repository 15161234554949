<template>
  <Loader :show="loading" v-if="loading"></Loader>
  <div v-else-if="newsFeeds.length">
    <MDBRow>
      <MDBCol col="12" class="mb-3">
        <NewsFeed
          v-for="(newsFeed, index) in newsFeeds"
          :key="index"
          :newsFeed="newsFeed"
          :index="index"
        />
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";

import NewsFeed from "@/components/NewsFeeds/Wrapper.vue";
import Loader from "@/components/General/Loader/index";
import { onMounted, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const fetchNewsFeeds = async () => {
  await store.dispatch("loadNewsFeeds");
};

onMounted(async () => {
  await fetchNewsFeeds();
});

const newsFeeds = computed(() => store.getters.getNewsFeeds);
const loading = computed(() => store.getters.getPending);
</script>
