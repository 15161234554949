<template>
  <MDBCard>
    <div
      class="w-100 d-flex justify-content-center"
      v-if="!isGermany || !isSwitzerland"
    >
      <img :src="require('@/assets/img/bs_logo_solution.svg')" alt="logo" />
    </div>
    <MDBCardBody>
      <MDBCardTitle class="text-start font-35 my-4">
        <div v-html="$t('maintenance.title')"></div>
      </MDBCardTitle>
      <MDBCardText class="pb-0">
        <div class="font-16" v-html="$t('maintenance.desc')"></div>
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>
</template>

<script setup>
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-vue-ui-kit";
import { computed } from "vue";

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isSwitzerland = computed(() => LANG === "ch");
</script>
