import ApiService from "./api.service";

const TextPageService = {
  async textPages() {
    try {
      return await ApiService.get("userTextPages");
    } catch (error) {
      return error.response;
    }
  },
  async textPage(id) {
    try {
      return await ApiService.get(`textPages/${id}/show/langs`);
    } catch (error) {
      console.log(error);
    }
  },
  async newsFeeds() {
    try {
      return await ApiService.get("userNewsFeeds");
    } catch (error) {
      return error.response;
    }
  },
  async textPageBySlug(slug) {
    try {
      return await ApiService.get(`textPages/slug/${slug}`);
    } catch (error) {
      console.log(error);
    }
  },
};

export { TextPageService };
