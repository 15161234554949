<template>
  <CategoryTop :title="$t('investment.title')" :description="newInvestment
      ? $t('investment.description')
      : $t('investment.description_old')
    " :breadcrumbs-parent-routes="['shop']" />
</template>

<script setup>
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const newInvestment = computed(() => store.getters.investmentSupportNew);
</script>
