<template>
  <div>
    <div v-html="$t('marketingModal.content')"></div>
    <ol class="pt-2">
      <li class="fw-bold">{{ $t("marketingModal.point1Title") }}</li>
    </ol>
    <div v-html="$t('marketingModal.point1Desc')"></div>
    <ol start="2" class="pt-2">
      <li class="fw-bold">{{ $t("marketingModal.point2Title") }}</li>
    </ol>
    <div v-html="$t('marketingModal.point2Desc')"></div>
    <div class="pt-2">
      <ol start="3">
        <li class="fw-bold">{{ $t("marketingModal.point3Title") }}</li>
      </ol>

      <div class="ps-3">
        <div class="fw-bold">3.1 {{ $t("marketingModal.point31Title") }}</div>
        <div v-html="$t('marketingModal.point31Desc')"></div>

        <div class="fw-bold">3.2 {{ $t("marketingModal.point32Title") }}</div>
        <div v-html="$t('marketingModal.point32Desc')"></div>
      </div>
    </div>
    <ol start="4" class="pt-2">
      <li class="fw-bold">{{ $t("marketingModal.point4Title") }}</li>
    </ol>
    <div>{{ $t("marketingModal.point4Desc") }}</div>
    <ol start="5" class="pt-2">
      <li class="fw-bold">{{ $t("marketingModal.point5Title") }}</li>
    </ol>
    <div>{{ $t("marketingModal.point5Desc") }}</div>
    <ol start="6" class="pt-2">
      <li class="fw-bold">{{ $t("marketingModal.point6Title") }}</li>
    </ol>
    <div>{{ $t("marketingModal.point6Desc") }}</div>

    <ol start="7" class="pt-2">
      <li class="fw-bold">{{ $t("marketingModal.point7Title") }}</li>
    </ol>
    <div v-html="$t('marketingModal.point7Desc')"></div>

    <ol start="8" class="pt-2">
      <li class="fw-bold">{{ $t("marketingModal.point8Title") }}</li>
    </ol>
    <div>{{ $t("marketingModal.point8Desc") }}</div>

    <ol start="9" class="pt-2">
      <li class="fw-bold">{{ $t("marketingModal.point9Title") }}</li>
    </ol>
    <div>{{ $t("marketingModal.point9Desc") }}</div>

    <ol start="10" class="pt-2">
      <li class="fw-bold">{{ $t("marketingModal.point10Title") }}</li>
    </ol>
    <div>{{ $t("marketingModal.point10Desc") }}</div>

    <ol start="11" class="pt-2">
      <li class="fw-bold">{{ $t("marketingModal.point11Title") }}</li>
    </ol>
    <div v-html="$t('marketingModal.point11Desc')"></div>

    <div class="pt-2">
      <ol start="12">
        <li class="fw-bold">{{ $t("marketingModal.point12Title") }}</li>
      </ol>

      <div class="ps-3">
        <div>12.1 {{ $t("marketingModal.point121Desc") }}</div>
        <div>12.2 {{ $t("marketingModal.point122Desc") }}</div>
        <div>12.3 {{ $t("marketingModal.point123Desc") }}</div>
        <div>12.4 {{ $t("marketingModal.point1234Desc") }}</div>
      </div>
    </div>

    <ol start="13" class="pt-2">
      <li class="fw-bold">{{ $t("marketingModal.point13Title") }}</li>
    </ol>
    <div v-html="$t('marketingModal.point13Desc')"></div>
  </div>
</template>

<script setup></script>
