<template>
  <MDBRow v-if="files.length">
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import { MDBRow } from "mdb-vue-ui-kit";
import FileItem from "@/components/ProductInfo/FileItem.vue";

import { useStore } from "vuex";
import { computed, onMounted } from "vue";

const store = useStore();

const fetchPrices = async () => {
  try {
    await store.dispatch("loadPrices");
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  fetchPrices();
});

const files = computed(() => store.getters.getPrices);
</script>
