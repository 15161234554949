import SubDefault from "@/views/SubDefault.vue";
import SingleColumn from "@/views/SingleColumn.vue";
import TextPage from "@/components/TextPage/index";
import Contact from "@/components/Contact/index";
import ContactList from "@/components/Contact/Content/List.vue";
import StrategicPartners from "@/components/Contact/StrategicPartners/index";
import StrategicPartnersList from "@/components/Contact/StrategicPartners/Content/List.vue";
import SaleSupport from "@/components/SaleSupport/index";
import SaleSupportList from "@/components/SaleSupport/Content/List.vue";
import SalePromotions from "@/components/SalePromotions/index";
import SalePromotionsList from "@/components/SalePromotions/Content/List.vue";
import ServiceNumber from "@/components/Contact/ServiceNumber/index";
import ServiceNumberList from "@/components/Contact/ServiceNumber/Content/ServiceNumber.vue";
import About from "@/components/About/index";
import AboutContent from "@/components/About/Content.vue";
import AboutFirstStop from "@/components/About/FirstStop.vue";
import NewsFeeds from "@/components/NewsFeeds/index";
import NewsFeedList from "@/components/NewsFeeds/Content/List.vue";
import CommitmentLetter from "@/components/CommitmentLetter/index";
import KickbackFee from "@/components/KickbackFee/Content.vue";
import TouchIncentive from "@/components/Contact/TouchIncentive/Content.vue";
import TopData from "@/components/Contact/TouchIncentive/TopData.vue";

import OnlineVisibility from "@/components/OnlineVisibility/index";
import OnlineVisibilityData from "@/components/OnlineVisibility/Content/Data.vue";

import Media from "@/components/Media/index";
import MediaData from "@/components/Media/Content/Data.vue";
import MediaLibrary from "@/components/MediaLibrary/index";
import MediaLibraryContent from "@/components/MediaLibrary/Content.vue";

export default [
  {
    path: "/text_page/:id/:slug",
    name: "text_page",
    component: SingleColumn,
    props: {
      content: TextPage,
      switchOrderMobile: true,
    },
    meta: {
      requiredLang: ["de", "nl", "ch"],
    },
  },
  {
    path: "/contact",
    name: "contact_service",
    component: SubDefault,
    props: {
      content: Contact,
      switchOrderMobile: true,
      data: ContactList,
    },
    meta: {
      requiredLang: ["de", "nl", "ch"],
    },
  },
  {
    path: "/contact/touch-incentive",
    name: "touch_incentive",
    component: SubDefault,
    props: {
      content: TopData,
      data: TouchIncentive,
      switchOrderMobile: true,
    },
    meta: {
      requiredLang: ["nl"],
    },
  },
  {
    path: "/contact/strategic-partners",
    name: "strategic_partners",
    component: SubDefault,
    props: {
      content: StrategicPartners,
      switchOrderMobile: true,
      data: StrategicPartnersList,
    },
    meta: {
      requiredLang: ["nl"],
    },
  },
  {
    path: "/sales-support",
    name: "sales_support",
    component: SubDefault,
    props: {
      content: SaleSupport,
      switchOrderMobile: true,
      data: SaleSupportList,
    },
    meta: {
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/sale-promotions",
    name: "salesPromotions",
    component: SubDefault,
    props: {
      content: SalePromotions,
      switchOrderMobile: true,
      data: SalePromotionsList,
    },
    meta: {
      requiredRole: ["dealer", "staff"],
      requiredLang: ["de", "ch"],
      permission: "salesPromotions.see",
    },
  },
  {
    path: "/service-number",
    name: "service_number",
    component: SubDefault,
    props: {
      content: ServiceNumber,
      switchOrderMobile: true,
      data: ServiceNumberList,
    },
    meta: {
      requiredLang: ["de", "nl", "ch"],
    },
  },
  {
    path: "/about",
    name: "about",
    component: SubDefault,
    props: {
      content: About,
      switchOrderMobile: true,
      data: AboutContent,
    },
    meta: {
      requiredRole: ["dealer", "staff"],
      requiredLang: ["de", "nl", "ch"],
      permission: "aboutPage.see",
    },
  },
  {
    path: "/news-feeds",
    name: "news_feeds",
    component: SubDefault,
    props: {
      content: NewsFeeds,
      switchOrderMobile: true,
      data: NewsFeedList,
    },
    meta: {
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/commitment-letter",
    name: "commitmentLetter",
    component: SingleColumn,
    props: {
      content: CommitmentLetter,
      switchOrderMobile: true,
    },
    meta: {
      requiredLang: ["nl"],
      permission: "commitmentLetterPage.see",
    },
  },
  {
    path: "/rewards-overview",
    name: "rewardsOverview",
    component: SingleColumn,
    props: {
      content: KickbackFee,
      switchOrderMobile: true,
    },
    meta: {
      requiredLang: ["nl"],
    },
  },

  {
    path: "/sales-support/online-visibility",
    name: "onlineVisibility",
    component: SubDefault,
    props: {
      content: OnlineVisibility,
      switchOrderMobile: true,
      data: OnlineVisibilityData,
    },
    meta: {
      requiredLang: ["de", "ch"],
      permission: "uberall.see",
    },
  },

  {
    path: "/sales-support/media-list",
    name: "media",
    component: SubDefault,
    props: {
      content: Media,
      switchOrderMobile: true,
      data: MediaData,
    },
    meta: {
      requiredLang: ["de", "ch"],
      permission: "mediaDownload.see",
    },
  },
  {
    path: "/medias",
    name: "medias",
    component: SubDefault,
    props: {
      content: MediaLibrary,
      switchOrderMobile: true,
      data: MediaLibraryContent,
    },
    meta: {
      requiredLang: ["de", "ch"],
      permission: "mediaDownload.see",
    },
  },
  {
    path: "/about/first-stop",
    name: "first_stop",
    component: SingleColumn,
    props: {
      content: AboutFirstStop,
      switchOrderMobile: true,
    },
    meta: {
      requiredLang: ["nl"],
    },
  },
];
