import de from "./de";
import nl from "./nl";
import ch from "./ch";
import en from "./en";
import fr from "./fr";
import it from "./it";

let lang = {};
const setUpLang = () => {
  const appLang = process.env.VUE_APP_LANG;
  if (localStorage.getItem("lang")) {
    localStorage.setItem("lang", localStorage.getItem("lang"));
  } else {
    localStorage.setItem("lang", appLang);
    lang = appLang;
  }

  if (!localStorage.getItem("currentLocale")) {
    localStorage.setItem("currentLocale", appLang);
  }

  return lang;
};

setUpLang();

export default { de, nl, ch, en, fr, it };
