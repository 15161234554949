<template>
  <MDBCol cols="12" md="5" v-if="!isSuccess"
    ><div class="fw-bold pb-2">{{ $t("externeTraining.form.title") }}</div>
    <VeeForm class="form user-form" @submit="onSubmit">
      <div>
        <label for="points" class="label px-3 text-uppercase">{{
          $t("points")
        }}</label>
        <Field
          type="number"
          class="form-control"
          :placeholder="$t('points')"
          :aria-label="$t('points')"
          aria-describedby="basic-addon1"
          v-model="setPoints"
          name="setPoints"
          :rules="{ min_value: 100, max_value: maxPoints, required: true }"
          :label="$t('points')"
          id="points"
        /><ErrorMessage
          name="setPoints"
          class="text-danger text-small text-end m-0 pt-1"
        />
      </div>
      <div>
        {{
          $t("externeTraining.form.thirdPartyInvoiceDesc", {
            calculatePriceDiscount: calculatePriceDiscount,
          })
        }}

        <div class="d-flex">
          <div class="w-100 pe-3">
            <label for="thirdPartyInvoice" class="label px-3 text-uppercase">{{
              $t("externeTraining.form.thirdPartyInvoice")
            }}</label>
            <Field
              type="file"
              class="form-control"
              placeholder="File"
              aria-label="File"
              aria-describedby="basic-addon1"
              name="thirdPartyInvoice"
              :rules="{ required: true }"
              :label="$t('externeTraining.form.thirdPartyInvoice')"
              :ref="thirdPartyInvoice"
              @change="addFile($event, 'thirdPart')"
              v-if="!thirdPartyInvoice"
              id="thirdPartyInvoice"
            /><ErrorMessage
              name="thirdPartyInvoice"
              class="text-danger text-small text-end m-0 pt-1"
            />
            <div v-if="thirdPartyInvoice" class="form-control">
              {{ $t("externeTraining.form.thirdPartyInvoice") }}
            </div>
          </div>
          <img
            src="@/assets/img/icons/trash.svg"
            alt="trash"
            class="icon-container"
            @click="removeFile('thirdPart')"
            v-if="thirdPartyInvoice"
          />
        </div>
      </div>
      <div>
        <p>{{ $t("externeTraining.form.uploadInvoice") }}:</p>
        <div class="d-flex">
          <div class="w-100 pe-3">
            <label for="userInvoice" class="label px-3 text-uppercase">{{
              $t("externeTraining.form.userInvoice")
            }}</label>
            <Field
              type="file"
              class="form-control"
              placeholder="File"
              aria-label="File"
              aria-describedby="basic-addon1"
              name="userInvoice"
              :rules="{ required: true }"
              :label="$t('externeTraining.form.userInvoice')"
              :ref="userInvoice"
              @change="addFile($event, 'user')"
              v-if="!userInvoice"
              id="userInvoice"
            /><ErrorMessage
              name="userInvoice"
              class="text-danger text-small text-end m-0 pt-1"
            />
            <div v-if="userInvoice" class="form-control">
              {{ $t("externeTraining.form.userInvoice") }}
            </div>
          </div>
          <img
            src="@/assets/img/icons/trash.svg"
            alt="trash"
            class="icon-container"
            @click="removeFile('user')"
            v-if="userInvoice"
          />
        </div>
      </div>
      <div>
        <label for="iban" class="label px-3 text-uppercase">IBAN</label>
        <Field
          type="text"
          class="form-control"
          placeholder="IBAN"
          aria-label="IBAN"
          aria-describedby="basic-addon1"
          v-model="bankAccount"
          name="bankAccount"
          :rules="{ required: true }"
          label="IBAN"
          id="iban"
        /><ErrorMessage
          name="bankAccount"
          class="text-danger text-small text-end m-0 pt-1"
        />
      </div>
      <div>
        <label for="bankAccountOwner" class="label px-3 text-uppercase">{{
          $t("externeTraining.form.bankAccountOwner")
        }}</label>
        <Field
          type="text"
          class="form-control"
          :placeholder="$t('externeTraining.form.bankAccountOwner')"
          :aria-label="$t('externeTraining.form.bankAccountOwner')"
          aria-describedby="basic-addon1"
          v-model="bankAccountOwner"
          name="bankAccountOwner"
          :rules="{ required: true }"
          :label="$t('externeTraining.form.bankAccountOwner')"
          id="bankAccountOwner"
        /><ErrorMessage
          name="bankAccountOwner"
          class="text-danger text-small text-end m-0 pt-1"
        />
      </div>
      <div class="pt-3">
        <MDBBtn
          type="submit"
          color="danger"
          :disabled="loading"
          class="d-flex justify-content-center align-items-center"
          ><span>{{ $t("buttons.sendRequest") }}</span>
          <Loader
            :show="loading"
            :small="true"
            spinner="spinner.svg"
            :tiny="true"
        /></MDBBtn>
      </div>
      <p
        v-if="apiError"
        class="text-danger text-small text-end m-0 pt-2 text-small"
      >
        {{ apiError }}
      </p></VeeForm
    ></MDBCol
  >
  <Success v-else />
</template>

<script setup>
import Loader from "@/components/General/Loader/index";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import Success from "./Success.vue";
import { MDBCol, MDBBtn } from "mdb-vue-ui-kit";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";

import { InvestmentSupportService } from "@/services/investmentSupport.service";
const store = useStore();

const user = computed(() => store.getters.getMe);
const maxPoints = computed(() => user.value.points);
const setPoints = ref(0);
const thirdPartyInvoice = ref(null);
const userInvoice = ref(null);
let thirdPartyInvoiceFile = ref(null);
let userInvoiceFile = ref(null);
const bankAccount = ref(null);
const bankAccountOwner = ref(null);
const isSuccess = ref(false);
const apiError = ref(null);

const loading = computed(() => store.getters.getPending);

const calculatePriceDiscount = computed(() => {
  return Math.round(setPoints.value * 0.25);
});

const onSubmit = async () => {
  apiError.value = null;
  const data = {
    user_id: user.value.id,
    points: setPoints.value,
    third_part_invoice: thirdPartyInvoiceFile.value,
    user_invoice: userInvoiceFile.value,
    bank_account: bankAccount.value,
    bank_account_owner: bankAccountOwner.value,
  };

  const response = await InvestmentSupportService.store(data);

  if (response?.status == 200) {
    await store.dispatch("me");
    isSuccess.value = true;
  } else {
    apiError.value = response.data.message;
  }
};

const addFile = async (event, type = "user") => {
  if (event.target.files.length > 0) {
    if (type === "user") {
      userInvoice.value = URL.createObjectURL(event.target.files[0]);
      userInvoiceFile.value = await fileToBase64(event.target.files[0]);
    } else {
      thirdPartyInvoice.value = URL.createObjectURL(event.target.files[0]);
      thirdPartyInvoiceFile.value = await fileToBase64(event.target.files[0]);
    }
  }
};

const fileToBase64 = async (file) => {
  apiError.value = null;
  return new Promise((resolve, reject) => {
    if (!file.type.match("application/pdf")) {
      apiError.value = i18n.global.t("wrongFileError");
      return reject(new Error("INVALID_FILE"));
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const removeFile = (type) => {
  if (type === "user") {
    userInvoice.value = null;
    userInvoiceFile.value = null;
  } else {
    thirdPartyInvoice.value = null;
    thirdPartyInvoiceFile.value = null;
  }
};
</script>
