<template>
  <MDBCard
    class="mailbox mailbox--main"
    :class="[
      `${
        user?.commitment_letter_accept && user?.intro == 1 ? 'intro__step' : ''
      }`,
    ]"
  >
    <MailBoxContent />
  </MDBCard>
</template>

<script setup>
import { MDBCard } from "mdb-vue-ui-kit";
import MailBoxContent from "../Dashboard/MailBoxContent.vue";
import { useStore } from "vuex";
import { computed, watch, ref } from "vue";

const store = useStore();

const user = computed(() => store.getters.getMe);

const isWholesaler = ref(false);
const isStaff = ref(false);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }
});
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/welcome_box.scss";
@import "@/styles/mailbox.scss";
</style>
