<template>
  <MDBCol cols="12" lg="5">
    <div class="textBox textBox--small">
      <p class="fw-bold">
        {{ $t("trainings.singleLecture.success.thanks") }}
      </p>
      <p>
        {{ $t("trainings.singleLecture.success.title") }}
      </p>
      <ul>
        <li v-html="$t('trainings.singleLecture.success.feedbackTime')"></li>
        <li
          v-html="
            $t('trainings.singleLecture.success.points', {
              points: result.used_points * -1,
            })
          "
        ></li>
        <li v-html="$t('trainings.singleLecture.success.refund')"></li>
        <li>
          {{ $t("trainings.singleLecture.success.queries") }}
          <a href="mailto:training@bridgestone.eu">training@bridgestone.eu</a>
        </li>
      </ul>
    </div>
  </MDBCol>
</template>

<script setup>
import { MDBCol } from "mdb-vue-ui-kit";
import { computed } from "vue";

import { useStore } from "vuex";
const store = useStore();

const result = computed(() => store.getters.getBTrainingResult);
</script>
