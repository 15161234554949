import ApiService from "./api.service";

const PromotionService = {
  async promotions() {
    try {
      return await ApiService.get("promotions");
    } catch (error) {
      return error.response;
    }
  },
};

export { PromotionService };
