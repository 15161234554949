<template>
  <MDBRow v-if="props.title">
    <MDBCol>
      <h3 class="my-3 text-uppercase">{{ props.title }}</h3>
    </MDBCol>
  </MDBRow>
  <MDBRow>
    <MDBCol md="6" v-if="!props.title">
      <div class="input-group mb-3">
        <input type="text" class="form-control" :placeholder="$t('contact.form.csNumberIfNeed')"
          :aria-label="$t('contact.form.csNumberIfNeed')" aria-describedby="basic-addon1"
          v-model="data.customer_number" />
      </div>
    </MDBCol>
    <MDBCol md="6">
      <div class="input-group mb-3">
        <Field :name="'salutation' + filedId" as="select" class="form-select mb-3" aria-label=".form-select-lg example"
          v-model="data.salutation_id" :rules="{ required: props.required }" :label="$t('profile.formOfAddress')"
          :id="'salutation' + filedId">
          <option selected value="">
            {{ $t("profile.formOfAddress") }}
          </option>
          <option :value="salutation.id" v-for="salutation in salutations" :key="salutation.id">
            {{ salutation.name }}
          </option>
        </Field>
        <div class="w-100">
          <ErrorMessage :name="'salutation' + filedId" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </div>
    </MDBCol>
    <MDBCol md="6">
      <div class="input-group mb-3">
        <Field type="text" class="form-control" :placeholder="$t('contact.form.nameFirma')"
          :aria-label="$t('contact.form.nameFirma')" aria-describedby="basic-addon1" v-model="data.name"
          :name="'name' + filedId" :rules="{ required: props.required }" :label="$t('contact.form.nameFirma')"
          :id="'name' + filedId" />
        <div class="w-100">
          <ErrorMessage :name="'name' + filedId" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </div>
    </MDBCol>

    <MDBCol md="6">
      <div class="input-group mb-3">
        <Field type="text" class="form-control" :placeholder="$t('contact.form.strHouseNr')"
          :aria-label="$t('contact.form.strHouseNr')" aria-describedby="basic-addon1" v-model="data.street"
          :name="'street' + filedId" :rules="{ required: props.required }" :label="$t('contact.form.strHouseNr')"
          :id="'street' + filedId" />
        <div class="w-100">
          <ErrorMessage :name="'street' + filedId" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </div>
    </MDBCol>
    <MDBCol md="6">
      <div class="input-group mb-3">
        <Field type="text" class="form-control" :placeholder="$t('profile.postcode')"
          :aria-label="$t('profile.postcode')" aria-describedby="basic-addon1" v-model="data.postal_code"
          :name="'postal_code' + filedId" :rules="{ required: props.required }" :label="$t('profile.postcode')"
          :id="'postal_code' + filedId" />
        <div class="w-100">
          <ErrorMessage :name="'postal_code' + filedId" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </div>
    </MDBCol>
    <MDBCol md="6">
      <div class="input-group mb-3">
        <Field type="text" class="form-control" :placeholder="$t('profile.location')"
          :aria-label="$t('profile.location')" aria-describedby="basic-addon1" v-model="data.city"
          :name="'city' + filedId" :rules="{ required: props.required }" :label="$t('profile.location')"
          :id="'city' + filedId" />
        <div class="w-100">
          <ErrorMessage :name="'city' + filedId" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </div>
    </MDBCol>
    <MDBCol md="6">
      <div class="input-group mb-3">
        <Field type="phone" class="form-control" :placeholder="$t('profile.phone')" :aria-label="$t('profile.phone')"
          aria-describedby="basic-addon1" v-model="data.phone" :name="'phone' + filedId"
          :rules="{ required: props.required }" :label="$t('profile.phone')" :id="'phone' + filedId" />
        <div class="w-100">
          <ErrorMessage :name="'phone' + filedId" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </div>
    </MDBCol>
    <MDBCol md="6">
      <div class="input-group mb-3">
        <Field type="text" class="form-control" :placeholder="$t('contact.form.contactPerson')"
          :aria-label="$t('contact.form.contactPerson')" aria-describedby="basic-addon1" v-model="data.contact_person"
          :name="'contact_person' + filedId" :rules="{ required: props.required }"
          :label="$t('contact.form.contactPerson')" :id="'contact_person' + filedId" />
        <div class="w-100">
          <ErrorMessage :name="'contact_person' + filedId" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { defineProps, defineExpose, reactive, computed } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
const store = useStore();
const salutations = computed(() => store.getters.getSalutations);

const props = defineProps({
  title: String,
  required: {
    type: Boolean,
    default: false,
  },
  filedId: Number,
});

const data = reactive({
  salutation_id: null,
  name: null,
  street: null,
  postal_code: null,
  city: null,
  phone: null,
  contact_person: null,
  customer_number: null,
});

defineExpose({
  data,
});
</script>
