<template>
  <Loader :show="loading" v-if="loading"></Loader>
  <MDBRow v-else>
    <MDBCol xl="9" class="mb-xl-3">
      <p class="pe-3 font-16" v-html="$t('trainings.online.content')"></p>
    </MDBCol>
    <MDBCol xl="3" class="sort-select-wrap mb-3 mb-xl-0">
      <select v-model="filterBy" class="sort-select font-12">
        <option value="all-season">{{ $t("trainings.seasons.all") }}</option>
        <option value="summer">{{ $t("trainings.seasons.summer") }}</option>
        <option value="winter">{{ $t("trainings.seasons.winter") }}</option>
        <option value="general">{{ $t("trainings.seasons.general") }}</option>
      </select>
      <MDBIcon class="font-10 sort-icon" icon="chevron-down" size="1x" />
    </MDBCol>

    <MDBCol
      lg="4"
      md="6"
      class="mb-4"
      v-for="(training, i) in filterTrainings"
      :key="i"
      :class="{ 'd-none': showTraining(training) }"
    >
      <router-link
        :to="`/training/${training.id}`"
        class="training text-center d-flex align-items-center justify-content-center"
        :style="{ backgroundImage: `url(${getImage(training.poster)})` }"
      >
      </router-link>
      <h3
        v-html="training.title"
        class="training-title font-20 font-lg-25 font-xl-30 font-xxl-20 pt-2"
      ></h3>
    </MDBCol>
    <MDBCol lg="4" md="6" class="mb-4">
      <p
        class="training bg-white d-flex align-items-center justify-content-center text-center p-2"
        v-html="$t('trainings.offer')"
      ></p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import Loader from "@/components/General/Loader/index";
import { MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

const loading = computed(() => store.getters.getPending);
const currentLocale = localStorage.getItem("currentLocale");

const filterBy = ref("all-season");

const getImage = (img) => {
  const image = img ? img : "";

  return `${image}`;
};
const trainings = computed(() => store.getters.getTrainings);
const filterTrainings = computed(() => {
  return filterBy.value == "all-season"
    ? trainings.value
    : trainings.value.filter((training) =>
        training.season.includes(filterBy.value)
      );
});

const showTraining = (data) => {
  return (
    data.locale_availability &&
    !data.locale_availability.includes(currentLocale)
  );
};

onMounted(() => {
  store.dispatch("loadTrainings");
});
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
@import "@/styles/sort_select.scss";

.training {
  min-height: 220px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media screen and (max-width: $xxxlBreakpoint) {
    min-height: 175px;
  }

  @media screen and (max-width: $xxlBreakpoint) {
    min-height: 140px;
  }

  @media screen and (max-width: $xlBreakpoint) {
    min-height: 155px;
  }

  @media screen and (max-width: $lgBreakpoint) {
    min-height: 179px;
  }

  @media screen and (max-width: $mdBreakpoint) {
    min-height: 298px;
  }

  @media screen and (max-width: $smBreakpoint) {
    min-height: 56vw;
  }
}

.training-title {
  color: black;
}
</style>
