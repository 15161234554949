<template>
  <MDBRow v-if="props.loader">
    <Loader :show="props.loader" />
  </MDBRow>

  <MDBRow v-else-if="props.products.length">
    <MDBCol
      lg="4"
      md="6"
      class="mb-4"
      v-for="product in props.products"
      :key="`product_${product.plainId}`"
    >
      <ProductItem :product="product" />
    </MDBCol>
    <Pagination @page-number-changed="(e) => passEvent(e)" />
  </MDBRow>

  <MDBRow v-else>
    <MDBCol class="text-center font-18 mt-3 mb-5">
      <p>{{ $t("shop.noProducts") }}</p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import ProductItem from "./ProductListItem.vue";
import Loader from "../General/Loader/index.vue";
import { defineProps, defineEmits } from "vue";
import Pagination from "./Pagination.vue";

const emit = defineEmits(["pageNumberChanged"]);

const passEvent = (e) => emit("pageNumberChanged", e);

const props = defineProps({
  products: Array,
  loader: Boolean,
});
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.banner {
  min-height: 282px;
  background: $accentBlue;
  position: relative;

  * {
    position: relative;
    z-index: 10;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 180px;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.24) 13%,
      rgba(0, 0, 0, 0.4) 27%,
      rgba(0, 0, 0, 0.55) 70%,
      rgba(0, 0, 0, 0.8) 100%
    );
    z-index: 1;
  }

  @media screen and (max-width: $xxxlBreakpoint) {
    min-height: 195px;
  }

  @media screen and (max-width: $xxlBreakpoint) {
    min-height: 190px;
  }

  @media screen and (max-width: $mdBreakpoint) {
    min-height: 106px;
  }

  @media screen and (max-width: $smBreakpoint) {
    min-height: 18vw;
  }
}
</style>
