<template>
  <div class="slider-wrapper">
    <div v-if="props.category" class="category">
      {{ props.category }}
    </div>
    <template v-if="slides.length && slides.length > 0">
      <swiper
        :slides-per-view="1"
        :space-between="0"
        :modules="[Navigation, Pagination, Autoplay]"
        loop
        :initial-slide="0"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        navigation
        :pagination="{
          clickable: true,
          type: 'custom',
          renderCustom: (swiper, current, total) =>
            `<div class='swiper-custom-pagination'>${current} ${$t(
              'of'
            )} ${total}</div>`,
        }"
      >
        <swiper-slide
          v-for="(product, key) in props.slides"
          :key="key"
          class="swiper-item"
        >
          <ProductItem
            :product="product"
            single
            v-bind="{
              affordable: settings?.affordable ? settings.affordable : null,
            }"
          />
        </swiper-slide>
      </swiper>
    </template>
    <template v-else>
      <ProductItem
        :empty="true"
        link="/shop"
        :product="null"
        single
      ></ProductItem>
    </template>
  </div>
</template>

<script setup>
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import ProductItem from "@/components/Shop/ProductListItem.vue";
import "swiper/swiper-bundle.css";
import { defineProps } from "vue";
const props = defineProps({
  slides: Object,
  category: String,
  settings: Object,
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.slider-wrapper {
  position: relative;
}
.category {
  position: absolute;
  z-index: 2;
  font-size: 18px;
  top: 0;
  left: 0;
  padding: 6px 1.5rem;
  min-width: 172px;
  background: #000;
  color: white;
}

.category:after {
  content: "";
  width: 13px;
  right: -5px;
  top: 1px;
  height: 46px;
  background: white;
  position: absolute;
  transform: rotate(19deg);
}

:deep(.swiper-custom-pagination) {
  color: $gray;
}

:deep(.swiper-button-prev) {
  color: $gray;
}

:deep(.swiper-button-next) {
  color: $gray;
}

:deep(.card.with-category) {
  min-height: 300px;
}
:deep(.product-image) {
  position: absolute;
  top: 50px;
  right: 0px;
  width: 50%;
  height: 100px;
  z-index: 1;
}
</style>
