<template>
  <MDBRow v-if="hunters.length > 0">
    <MDBCol lg="4" class="mb-4" v-for="(data, i) in hunters" :key="i">
      <Card :data="data" />
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import Card from "@/components/General/Card/index.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { onMounted, ref } from "vue";
import { HunterService } from "@/services/hunter.service";

const hunters = ref([]);

onMounted(async () => {
  const response = await HunterService.hunters();

  if (response) {
    let mappedData = response.data.data.map((item) => {
      return {
        name: item.title,
        description: item.short_description,
        link: { name: "hunter", params: { id: item.id } },
        image: item.poster,
      };
    });

    hunters.value = mappedData;
  }
});
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
</style>
