import TokenService from "./storage.service";
import router from "../router";
import axios from "axios";
import store from "@/store/index";
const LANG = localStorage.getItem("currentLocale");
const country = localStorage.getItem("lang");

const ApiService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    // axios.defaults.withCredentials = true
    axios.defaults.headers.common["Accept-Language"] = country;
    axios.defaults.headers.common["GW-Language"] = LANG;
    axios.interceptors.response.use(
      (response) => {
        store.dispatch("addPending", false);
        return response;
      },
      function (error) {
        store.dispatch("addPending", false);
        if (error.response.status === 401) {
          router.push("/error");
        }
        return Promise.reject(error);
      }
    );

    axios.interceptors.request.use((config) => {
      store.dispatch("addPending", true);
      config.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;

      return config;
    });
  },

  setHeader() {
    axios.defaults.headers.common["Content-Type"] = "application/json";

    axios.defaults.withCredentials = true;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get("/api/" + LANG + "/" + resource);
  },

  csrfCookie() {
    return axios.get("/sanctum/csrf-cookie");
  },

  post(resource, data, headers) {
    return axios.post("/api/" + LANG + "/" + resource, data, headers);
  },

  put(resource, data) {
    return axios.put("/api/" + LANG + "/" + resource, data);
  },

  delete(resource, params = undefined) {
    return axios.delete("/api/" + LANG + "/" + resource, params);
  },

  customRequest(data) {
    return axios(data);
  },
};

export default ApiService;
