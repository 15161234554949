<template>
  <MDBCard class="border-0">
    <CategoryTop
      small
      :title="$t('profile.title')"
      :breadcrumbs-parent-routes="['account']"
      :description="$t('profile.description')"
      content-class="px-4 mt-4"
      left-col="6"
      right-col="6"
    >
      <template #right>
        <MDBRow>
          <MDBCol col="12" class="mb-5">
            <div class="progress">
              <div
                class="progress-bar bg-danger progress-bar-striped progress-bar-animated"
                role="progressbar"
                :style="'width:' + fillPercent + '%'"
                :aria-valuenow="fillPercent"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ fillPercent }}%
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </template>
    </CategoryTop>
    <VeeForm class="form user-form" @submit="onSubmit">
      <MDBRow>
        <MDBCol xl="4" md="6" class="mb-4">
          <PersonalData ref="personalData" :requredSection="true"></PersonalData>
        </MDBCol>

        <MDBCol xl="4" md="6" class="mb-4">
          <OperatingData
            ref="operatingData"
            @diffAddress="diffAddress"
            :requredSection="true"
          ></OperatingData>
        </MDBCol>

        <MDBCol xl="3" md="6" class="mb-4">
          <div
            class="switch-wrapper d-flex flex-column justify-content-center pt-4"
          >
            <div class="form-check form-switch">
              <input
                v-model="diffDeliveryAddress"
                class="form-check-input"
                type="checkbox"
                role="switch"
                name="switch_addr"
              />
              <label class="form-check-label font-12" for="switch_addr">{{
                $t("profile.diffDeliveryAddres")
              }}</label>
            </div>
          </div>
          <DeliveryData
            v-show="diffDeliveryAddress"
            ref="deliveryData"
          ></DeliveryData>
        </MDBCol>
      </MDBRow>
      <MDBRow v-if="!isWholesaler && !isStaff && !isWholesalerStaff">
        <MDBCol cols="12" class="mb-4">
          <ClientNumbers></ClientNumbers>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol col="12" class="mb-4">
          <IndicatorsData ref="indicatorsData"></IndicatorsData>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol col="12" class="text-start">
          <div
            v-html="$t('profile.newCustomerNumbersInfo')"
            class="pb-3"
            v-if="!isWholesaler && !isStaff"
          ></div>
          <div
            v-if="me.salespersons_dealers && me.salespersons_dealers.length > 0"
            class="pb-4"
          >
            <div
              v-for="(seller, index) in me.salespersons_dealers"
              :key="index"
            >
              <div v-if="!seller.is_internal">
                <span class="fw-bold"
                  >{{ $t("contact.form.contactPerson") }}:</span
                >
                {{ seller.name }}
                <a :href="'mailto:' + seller.email">{{ seller.email }}</a>
              </div>
            </div>
          </div>
          <MDBBtn
            type="submit"
            color="danger"
            :disabled="loading"
            class="d-flex justify-content-center align-items-center"
            ><span>{{ $t("buttons.sendButton") }}</span>
            <Loader
              :show="loading"
              :small="true"
              spinner="spinner.svg"
              :tiny="true"
          /></MDBBtn>
          <p
            v-if="apiError"
            class="text-danger text-small text-end m-0 pt-2 text-small"
          >
            {{ apiError }}
          </p>
        </MDBCol>
      </MDBRow>
    </VeeForm>
  </MDBCard>
</template>

<script setup>
import { Form as VeeForm } from "vee-validate";
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import { MDBRow, MDBCol, MDBCard, MDBBtn } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { ref, onMounted, computed, toRaw, watch } from "vue";
import PersonalData from "@/components/User/ProfileForms/PersonalData.vue";
import OperatingData from "@/components/User/ProfileForms/OperatingData.vue";
import DeliveryData from "@/components/User/ProfileForms/DeliveryData.vue";
import IndicatorsData from "@/components/User/ProfileForms/IndicatorsData.vue";
import ClientNumbers from "@/components/User/ProfileForms/ClientNumbers.vue";
import Loader from "@/components/General/Loader/index.vue";

import "vue3-toastify/dist/index.css";

import { ProfileService } from "@/services/profile.service";

import { fetchUserProfile, afterSuccess } from "@/composables/profile.js";

const store = useStore();

const personalData = ref(null);
const operatingData = ref(null);
const deliveryData = ref(null);
const indicatorsData = ref(null);
const diffDeliveryAddress = ref(false);
const apiError = ref(null);
const conditions = ref(false);
const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isStaff = ref(false);
const loading = computed(() => store.getters.getPending);
const profile = computed(() => store.getters.getProfile);
const me = computed(() => store.getters.getMe);

const diffAddress = (bool) => {
  diffDeliveryAddress.value = bool;
};

const onSubmit = async () => {
  const { email, username, id, ...personal } = personalData.value.models;
  const { ...operating } = operatingData.value.models;
  const { lang, ...mainAddress } = operating;

  let args = {
    id,
    user: {
      username,
      email,
      country: operating.country,
      lang,
      vat: operating.vat,
      is_active: true,
    },
    profile: {
      user_id: id,
      ...personal,
      ...mainAddress,
      ...indicatorsData.value.models,
      conditions: conditions.value
    },
    addresses: [mainAddress],
  };

  if (diffDeliveryAddress.value) {
    args.addresses.push(toRaw(deliveryData.value.models));
  }

  if (!diffDeliveryAddress.value && deliveryData.value.models.id) {
    deliveryData.value.models.to_remove = true;
    args.addresses.push(toRaw(deliveryData.value.models));
  }

  const response = await store.dispatch("updateUser", args);

  await ProfileService.storeStaffToDealer({
    dealer_id: me.value.plainId,
    postal_code: operating.postal_code,
  });

  if (response.status === 200) {
    await fetchUserProfile(store);

    await afterSuccess(
      id,
      profile.value.id,
      canGetExtraPoints.value,
      me.value.plainId,
      store
    );

    apiError.value = null;
  } else {
    apiError.value = response.data.message;
  }
};

const fillPercent = computed(() => {
  let percentages = [
    personalData.value?.percentage,
    operatingData.value?.percentage,
    !diffDeliveryAddress.value ? 100 : deliveryData.value?.percentage,
    indicatorsData.value?.percentage,
  ];

  let length = percentages.length;

  return Math.round(percentages.reduce((acc, a) => acc + a, 0) / length);
});

const canGetExtraPoints = computed(() => {
  let percentages = [
    personalData.value?.percentage,
    operatingData.value?.percentage,
  ];

  let length = percentages.length;

  return Math.round(percentages.reduce((acc, a) => acc + a, 0) / length);
});

watch(me, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isStaff.value = true;
  }
  if (canGetExtraPoints.value == 100) {
    await ProfileService.storeFullProfilePoints(me.value.plainId);
  }
});

watch(diffDeliveryAddress, (newVal) => {
  diffAddress(newVal);
});

onMounted(async () => {
  await fetchUserProfile(store);
  const { id } = personalData.value.models;

  const data = {
    user_id: id,
    profile_id: profile.value.id,
    percentage: canGetExtraPoints.value,
  };

  await store.dispatch("storeProfilePercentage", data);
});
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
@import "@/styles/profile.scss";
</style>
