<template>
  <MDBRow>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import FileItem from "@/components/ProductInfo/FileItem.vue";
import { MDBRow } from "mdb-vue-ui-kit";
import { computed, ref } from "vue";
import i18n from "@/plugins/i18n";

const LANG = localStorage.getItem("lang");
const currentLocale = localStorage.getItem("currentLocale");
const isGermany = computed(() => LANG === "de");
const isNetherlands = computed(() => LANG === "nl");
const germanFiles = ref([
  {
    file: "broschuren/bridgestone/BS Blizzak LM005_Testergebnisse W22_2pager_Ansicht.pdf",
    name: "BLIZZAK LM005",
    description: `${i18n.global.t("bridgestoneProductBrochures.files.desc1")}`,
    date: `${i18n.global.t("testResult")} 2022`,
    type: "pdf",
    simpleLink: true,
    directory: "broschuren/bridgestone/",
  },
  {
    file: "broschuren/bridgestone/BS Blizzak DM V3_2020_2pager_Ansicht.pdf",
    name: "BLIZZAK DM-V3",
    description: "SUV/4x4 - Winter",
    date: `${i18n.global.t("productOverview")} 2020`,
    type: "pdf",
    simpleLink: true,
    directory: "broschuren/bridgestone/",
  },
  {
    file: "broschuren/bridgestone/BS Blizzak LM005_Testergebnisse W22_2pager_Ansicht.pdf",
    name: "DURAVIS ALL SEASON",
    description: "Transporter - All Season",
    date: `${i18n.global.t("productOverview")} 2020`,
    type: "pdf",
    simpleLink: true,
    directory: "broschuren/bridgestone/",
  },
  {
    file: "broschuren/bridgestone/BS Potenza Race_Dimensionen 2022_2pager_Ansicht.pdf",
    name: "POTENZA RACE",
    description: `PKW - ${i18n.global.t("trainings.seasons.summer")} Sport`,
    date: `${i18n.global.t("productOverview")} 2020`,
    type: "pdf",
    simpleLink: true,
    directory: "broschuren/bridgestone/",
  },
  {
    file: "broschuren/bridgestone/BS Potenza Race_Testergebnis 2022_2pager_Ansicht.pdf",
    name: "POTENZA RACE",
    description: `PKW - ${i18n.global.t("trainings.seasons.summer")} Sport`,
    date: `${i18n.global.t("testResult")} 2022`,
    type: "pdf",
    simpleLink: true,
    directory: "broschuren/bridgestone/",
  },
  {
    file: "broschuren/bridgestone/BS Potenza Sport_2022_2pager_Ansicht.pdf",
    name: "POTENZA SPORT",
    description: `PKW, SUV - ${i18n.global.t(
      "trainings.seasons.summer"
    )} Sport`,
    date: `${i18n.global.t("productOverview")} 2020`,
    type: "pdf",
    simpleLink: true,
    directory: "broschuren/bridgestone/",
  },
  {
    file: "broschuren/bridgestone/BS Turanza T005 und Potenza Sport_Testergebnis S22_2pager_Ansicht.pdf",
    name: "TURANZA T005",
    description: `PKW, SUV - ${i18n.global.t(
      "trainings.seasons.summer"
    )} Sport`,
    date: `${i18n.global.t("testResult")} 2022`,
    type: "pdf",
    simpleLink: true,
    directory: "broschuren/bridgestone/",
  },
]);

const netherlandFiles = ref([
  {
    file: "Bridgestone Blizzak LM005_Sales tool.pdf",
    name: "Bridgestone",
    description: "Blizzak LM005_Sales tool",
    date: null,
    type: "pdf",
    simpleLink: true,
    directory: "broches_nl/",
  },
  {
    file: "Bridgestone Dueler AT002_Sales tool.pdf",
    name: "Bridgestone",
    description: "Dueler AT002_Sales tool",
    date: null,
    type: "pdf",
    simpleLink: true,
    directory: "broches_nl/",
  },
  {
    file: "Bridgestone Duravis AS_Sales tool.pdf",
    name: "Bridgestone",
    description: "Duravis AS_Sales tool",
    date: null,
    type: "pdf",
    simpleLink: true,
    directory: "broches_nl/",
  },
  {
    file: "Bridgestone Duravis VAN_Sales tool.pdf",
    name: "Bridgestone",
    description: "Duravis Duravis VAN_Sales tool",
    date: null,
    type: "pdf",
    simpleLink: true,
    directory: "broches_nl/",
  },
  {
    file: "Bridgestone Potenza Race_Sales tool.pdf",
    name: "Bridgestone",
    description: "Potenza Race_Sales tool",
    date: null,
    type: "pdf",
    simpleLink: true,
    directory: "broches_nl/",
  },
  {
    file: "Bridgestone Potenza Sport_Sales tool.pdf",
    name: "Bridgestone",
    description: "Potenza Sport_Sales tool",
    date: null,
    type: "pdf",
    simpleLink: true,
    directory: "broches_nl/",
  },
  {
    file: "Bridgestone Turanza 6_Dealerbrochure.pdf",
    name: "Bridgestone",
    description: "Turanza 6_Dealerbrochure",
    date: null,
    type: "pdf",
    simpleLink: true,
    directory: "broches_nl/",
  },
  {
    file: "Bridgestone Turanza 6_Sales tool.pdf",
    name: "Bridgestone",
    description: "Turanza 6_Sales tool",
    date: null,
    type: "pdf",
    simpleLink: true,
    directory: "broches_nl/",
  },
  {
    file: "Bridgestone Turanza AS6_Sales tool.pdf",
    name: "Bridgestone",
    description: "Turanza AS6_Sales tool",
    date: null,
    type: "pdf",
    simpleLink: true,
    directory: "broches_nl/",
  },
]);

const files = computed(() => {
  if (isGermany.value) {
    return germanFiles.value;
  } else if (isNetherlands.value) {
    return netherlandFiles.value;
  } else {
    const data = {
      ch: [
        {
          file: "Bridge catalogue 2024 SCHWEIZ-111223.pdf",
          name: "Bridge catalogue",
          description: "2024 SCHWEIZ-111223",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "Bridgestone Rasentraktor-Reifen.pdf",
          name: "Bridgestone",
          description: "Rasentraktor-Reifen",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "BS_AG_VT-COMBINE.pdf",
          name: "BS_AG_VT",
          description: "COMBINE",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "BS_AG_VT-TRACTOR.pdf",
          name: "BS_AG_VT",
          description: "TRACTOR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "BS_AG_VT-TRACTOR.pdf",
          name: "BS_AG_VT",
          description: "TRACTOR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "BS_AS_John Deere Broschüre.pdf",
          name: "BS_AS_John",
          description: "Deere Broschüre",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "BS_OTR_Multi Block T 2024.pdf",
          name: "BS_OTR_Multi",
          description: "Block T 2024",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "BS_Premium EM_Reifen für den Unter_Tage_Einsatz.pdf",
          name: "BS_Premium",
          description: "EM_Reifen für den Unter_Tage_Einsatz",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "BS_Premiumreifen für Erdbewegungsmaschinen.pdf",
          name: "BS_Premiumreifen",
          description: "für Erdbewegungsmaschinen",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "BS_TBR_DURAVIS-R002_BROCH_2020_GERMAN_BSSZ-dl_03.pdf",
          name: "BS_TBR_DURAVIS",
          description: "R002_BROCH 2020 GERMAN BSSZ-dl_03",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "BS_TBR_Range Brochure_2024_BSDL_LR.pdf",
          name: "BS_TBR_Range",
          description: "Brochure 2024 BSDL LR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "BS_TBR_RW-STEER-DRIVE 001_Product Sheet_A4_BSDL_LR_2022.pdf",
          name: "BS_TBR_RW-STEER-DRIVE",
          description: "001_Product Sheet_A4_BSDL_LR",
          date: "2022",
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "BS-20-1153_TBR_U-AP 002_BROCH_Product launch_A4_BSSZ-dl_library.pdf",
          name: "BS-20-1153_TBR_U-AP",
          description: "002 BROCH_Product launch_A4_BSSZ-dl_library",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
      ],
      fr: [
        {
          file: "Bridge catalogue 2024 SUISSE-111223.pdf",
          name: "Bridge catalogue",
          description: "2024 SUISSE-111223",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_fr/",
        },
        {
          file: "BS_S24_Overview-Leaflet_SZFR.pdf",
          name: "BS_S24",
          description: "Overview-Leaflet_SZFR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_fr/",
        },
        {
          file: "BS_S24_RangeBrochure_Car_PC_inMB-Long_SZFR-crr.pdf",
          name: "BS_S24_RangeBrochure",
          description: "Car_PC inMB Long_SZFR-crr",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_fr/",
        },
        {
          file: "BS_TBR_Range Brochure_2024_BSFR_LR.pdf",
          name: "BS_TBR_Range",
          description: "Brochure_2024_BSFR_LR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_fr/",
        },
        {
          file: "BS_TBR_RW-STEER-DRIVE 001_Product Sheet_A4_BSFR_LR_2022.pdf",
          name: "BS_TBR_RW-STEER-DRIVE",
          description: "001_Product Sheet_A4_BSFR_LR",
          date: "2022",
          type: "pdf",
          simpleLink: true,
          directory: "brochures_fr/",
        },
        {
          file: "BS-20-1153_TBR_U-AP 002_BROCH_Product launch_A4_BSSZ-fr_library.pdf",
          name: "BS-20-1153_TBR_U-AP",
          description: "002_BROCH_Product launch_A4_BSSZ-fr_library",
          date: "2022",
          type: "pdf",
          simpleLink: true,
          directory: "brochures_fr/",
        },
        {
          file: "BS-URL-QR-document_Blizzak-6_SZFR.pdf",
          name: "BS-URL-QR",
          description: "document_Blizzak-6_SZFR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_fr/",
        },
      ],
      it: [
        {
          file: "Bridge catalogue 2024 SVIZZERA - 111223.pdf",
          name: "Bridge catalogue",
          description: "2024 SVIZZERA - 111223",
          date: "2024",
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
        {
          file: "BS_S24_Overview-Leaflet_SZIT.pdf",
          name: "BS_S24_Overview",
          description: "Leaflet_SZIT",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
        {
          file: "BS_S24_RangeBrochure_Car_PC_inMB-Long_SZIT-crr.pdf",
          name: "BS S24 RangeBrochure",
          description: "Car PC inMB Long SZIT crr",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
        {
          file: "BS_TBR_DURAVIS-R002_BROCH_2020_ITALIAN_BSSZ-it_03 (1).pdf",
          name: "BS TBR DURAVIS",
          description: "R002_BROCH 2020 ITALIAN BSSZ it",
          date: "2020",
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
        {
          file: "BS_TBR_Range Brochure_2024_BSIT_LR.pdf",
          name: "BS TBR Range",
          description: "Brochure BSIT_LR",
          date: "2024",
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
        {
          file: "BS-20-1153_TBR_U-AP 002_BROCH_Product launch_A4_BSSZ-it_library.pdf",
          name: "BS-20-1153 TBR U-AP",
          description: "002_BROCH_Product launch_A4_BSSZ it_library",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
        {
          file: "BS-URL-QR-document_Blizzak-6_SZIT.pdf",
          name: "BS-URL-QR",
          description: "document Blizzak 6 SZIT",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
        {
          file: "CVR Twopager_CH_IT_klein_Korrektur2.pdf",
          name: "CVR Twopager_CH_IT",
          description: "klein_Korrektur2",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
      ],
    };

    return data[currentLocale];
  }
});
</script>
