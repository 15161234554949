<template>
  <Loader :show="loading" v-if="loading"></Loader>
  <MDBRow v-else>
    <MDBCol md="9" class="mb-0 mb-md-3">
      <p>{{ $t("trainings.lectures.content") }}</p>
    </MDBCol>
    <MDBCol md="3" class="sort-select-wrap mb-3 mb-md-0">
      <select v-model="filterBy" class="sort-select font-12">
        <option value="all-season">{{ $t("trainings.seasons.all") }}</option>
        <option value="summer">{{ $t("trainings.seasons.summer") }}</option>
        <option value="winter">{{ $t("trainings.seasons.winter") }}</option>
        <option value="general">{{ $t("trainings.seasons.general") }}</option>
      </select>
      <MDBIcon class="font-10 sort-icon" icon="chevron-down" size="1x" />
    </MDBCol>

    <MDBCol
      lg="4"
      md="6"
      class="mb-4"
      v-for="(training, i) in filterTrainings"
      :key="i"
    >
      <router-link
        :to="{
          name: 'bridgestoneSingleSchulungen',
          params: { id: training.id },
        }"
        class="training text-center d-flex align-items-center justify-content-center"
        :style="{ backgroundImage: `url(${getImage(training.poster)})` }"
      >
      </router-link>
      <p v-html="training.title" class="p-4 font-16"></p>
    </MDBCol>
    <MDBCol lg="4" md="6" class="mb-4">
      <p
        class="training bg-white d-flex align-items-center justify-content-center text-center p-2"
        v-html="$t('trainings.offer')"
      ></p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import Loader from "@/components/General/Loader/index";
import { MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";
import { computed, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const filterBy = ref("all-season");
const getImage = (img) => {
  const image = img ? img : "";

  return `${image}`;
};

const fetchTrainings = () => {
  store.dispatch("loadBTrainings");
};

fetchTrainings();

const trainings = computed(() => store.getters.getBTrainings);
const loading = computed(() => store.getters.getPending);
const filterTrainings = computed(() => {
  return filterBy.value == "all-season"
    ? trainings.value
    : trainings.value.filter((training) =>
        training.season.includes(filterBy.value)
      );
});
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
@import "@/styles/sort_select.scss";

.training {
  min-height: 220px;
  background-size: cover;
  background-position: center center;
}
</style>
