<template>
  <MDBCard class="py-0 border-0">
    <MDBCardBody>
      <div class="overview-header">
        <h2 class="font-20 font-xxxl-40 mb-4">Profile Download</h2>
      </div>
      <MDBRow>
        <MDBCol cols="6">
          <h4>{{ $t("profile.personalData") }}</h4>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.email") }}</span>
              <input
                type="checkbox"
                value="email"
                :label="$t('profile.email')"
                @click="addItem('email', 'user')"
              />
            </label>
          </div>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.formOfAddress") }}</span>

              <input
                type="checkbox"
                value="salutation"
                :label="$t('profile.formOfAddress')"
                @click="addItem('salutation', 'profile')"
              />
            </label>
          </div>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100"> {{ $t("profile.firstname") }}</span>

              <input
                type="checkbox"
                value="forename"
                :label="$t('profile.firstname')"
                @click="addItem('forename', 'profile')"
              />
            </label>
          </div>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.surname") }}</span>

              <input
                type="checkbox"
                value="name"
                :label="$t('profile.surname')"
                @click="addItem('name', 'profile')"
              />
            </label>
          </div>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.position") }}</span>
              <input
                type="checkbox"
                value="job_position_id"
                :label="$t('profile.job_position_id')"
                @click="addItem('job_position_id', 'profile')"
              />
            </label>
          </div>
        </MDBCol>

        <MDBCol cols="6">
          <h4>{{ $t("pageName.orderDelivery") }}</h4>

          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.company") }}</span>
              <input
                type="checkbox"
                value="company"
                :label="$t('profile.company')"
                @click="addItem('company', 'profile')"
              />
            </label>
          </div>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.street") }}</span>
              <input
                type="checkbox"
                value="street"
                :label="$t('profile.street')"
                @click="addItem('street', 'profile')"
              />
            </label>
          </div>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.location") }}</span>
              <input
                type="checkbox"
                value="city"
                :label="$t('profile.city')"
                @click="addItem('city', 'profile')"
              />
            </label>
          </div>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.postcode") }}</span>
              <input
                type="checkbox"
                value="postal_code"
                :label="$t('profile.postcode')"
                @click="addItem('postal_code', 'profile')"
              />
            </label>
          </div>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.mobile") }}</span>
              <input
                type="checkbox"
                value="mobile_phone"
                :label="$t('profile.mobile')"
                @click="addItem('mobile_phone', 'profile')"
              />
            </label>
          </div>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.iban_number") }}</span>
              <input
                type="checkbox"
                value="iban_number"
                :label="$t('profile.iban_number')"
                @click="addItem('iban_number', 'profile')"
              />
            </label>
          </div>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.btw_number") }}</span>
              <input
                type="checkbox"
                value="vat"
                :label="$t('profile.btw_number')"
                @click="addItem('vat', 'user')"
              />
            </label>
          </div>
          <div class="form-check">
            <label
              class="form-check-label d-flex justify-content-start align-items-center"
            >
              <span class="w-100">{{ $t("profile.diffDeliveryAddres") }}</span>
              <input
                type="checkbox"
                value="extraAddress"
                :label="$t('profile.diffDeliveryAddres')"
                @click="addItem('extraAddress', 'extra_address')"
              />
            </label>
          </div>
        </MDBCol>

        <MDBCol cols="6"
          ><h4>{{ $t("profile.operatingData") }}</h4>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("bankstatement.customer_number") }}</span>
            <input
              type="checkbox"
              value="customer_number"
              :label="$t('message.customer_number')"
              @click="addItem('customer_number', 'wholesaler_dealer')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("profile.tireSale") }}</span>
            <input
              type="checkbox"
              value="tireSalesNumber"
              :label="$t('message.tireSalesNumber')"
              @click="addItem('tireSalesNumber', 'profile')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("profile.inches") }}</span>
            <input
              type="checkbox"
              value="overEighteenInch"
              :label="$t('message.overEighteenInch')"
              @click="addItem('overEighteenInch', 'profile')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("profile.premBudget") }}</span>
            <input
              type="checkbox"
              value="budgetPremium"
              :label="$t('message.budgetPremium')"
              @click="addItem('budgetPremium', 'profile')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("profile.midBudget") }}</span>
            <input
              type="checkbox"
              value="budgetMid"
              :label="$t('message.budgetMid')"
              @click="addItem('budgetMid', 'profile')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("profile.budBudget") }}</span>
            <input
              type="checkbox"
              value="budgetLow"
              :label="$t('message.budgetLow')"
              @click="addItem('budgetLow', 'profile')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("summerIn") }}</span>
            <input
              type="checkbox"
              value="distributionSummer"
              :label="$t('message.distributionSummer')"
              @click="addItem('distributionSummer', 'profile')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("winterIn") }}</span>
            <input
              type="checkbox"
              value="distributionWinter"
              :label="$t('message.distributionWinter')"
              @click="addItem('distributionWinter', 'profile')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("allSeasonIn") }}</span>
            <input
              type="checkbox"
              value="distributionAll"
              :label="$t('distributionAll')"
              @click="addItem('distributionAll', 'profile')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("current_tyres_amount") }}</span>
            <input
              type="checkbox"
              value="current_tyres_amount"
              :label="$t('current_tyres_amount')"
              @click="addItem('current_tyres_amount', 'user')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("current_points") }}</span>
            <input
              type="checkbox"
              value="current_points"
              :label="$t('current_points')"
              @click="addItem('current_points', 'user')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("current_kickbacks") }}</span>
            <input
              type="checkbox"
              value="current_kickbacks"
              :label="$t('current_kickbacks')"
              @click="addItem('current_kickbacks', 'user')"
            />
          </label>

          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("group") }}</span>
            <input
              type="checkbox"
              value="group_id"
              @click="addItem('group_id', 'profile')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("major") }}</span>
            <input
              type="checkbox"
              value="major"
              @click="addItem('major', 'profile')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{
              $t("contact.form.firstRegistration")
            }}</span>
            <input
              type="checkbox"
              value="first_login"
              @click="addItem('first_login', 'user')"
            />
          </label>
          <label
            class="form-check-label d-flex justify-content-start align-items-center"
          >
            <span class="w-100">{{ $t("profile.lastLogIn") }}</span>
            <input
              type="checkbox"
              value="last_login"
              @click="addItem('last_login', 'user')"
            />
          </label>
        </MDBCol>
      </MDBRow>

      <button
        @click="onSubmit"
        class="btn btn-danger text-uppercase px-5"
        type="button"
      >
        Download Excel
      </button>
    </MDBCardBody>
  </MDBCard>
</template>

<script setup>
import config from "@/config";
import { MDBCard, MDBCardBody, MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { ref } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import i18n from "@/plugins/i18n";

const models = ref({
  user: [],
  profile: [],
  wholesaler_dealer: [],
  extra_address: [],
});

const onSubmit = () => {
  if (
    !models.value.user.length &&
    !models.value.profile.length &&
    !models.value.wholesaler_dealer.length &&
    !models.value.extra_address.length
  ) {
    toast.clearAll();

    toast(i18n.global.t("toastMessages.selectOneField"), {
      type: "error",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.TOP_RIGHT,
    });
    return false;
  }
  let query = "";

  if (models.value.user.length) {
    query += "&user=" + models.value.user.join(",");
  }
  if (models.value.profile.length) {
    query += "&profile=" + models.value.profile.join(",");
  }
  if (models.value.wholesaler_dealer.length) {
    query += "&wholesaler_dealer=" + models.value.wholesaler_dealer.join(",");
  }

  if (models.value.extra_address.length) {
    query += "&extra_address=" + models.value.extra_address.join(",");
  }

  const finalQuery = replaceFirstChar(query, "?");

  window.open(`${config.URL}/api/de/user-profile-data${finalQuery}`);

  models.value.user = [];
  models.value.profile = [];
  models.value.wholesaler_dealer = [];
  models.value.extra_address = [];
};

function replaceFirstChar(str, newChar) {
  if (str.length === 0) return str;
  return newChar + str.slice(1);
}

const addItem = (item, model) => {
  if (!models.value[model].includes(item)) {
    models.value[model].push(item);
  } else {
    const filteredItems = models.value[model].filter(
      (existingItem) => existingItem !== item
    );
    models.value[model] = filteredItems;
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
@import "@/styles/_forms.scss";
</style>
