<template>
  <div>
    <Germany v-if="isGermany" />
    <Netherland v-if="isDutch" />
    <Switzerland v-if="isSwitzerland" />
  </div>
</template>

<script setup>
import Germany from "@/components/Contact/ServiceNumber/Country/Germany.vue";
import Netherland from "@/components/Contact/ServiceNumber/Country/Netherland.vue";
import Switzerland from "@/components/Contact/ServiceNumber/Country/Switzerland.vue";
import { computed } from "vue";

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isDutch = computed(() => LANG === "nl");
const isSwitzerland = computed(() => LANG === "ch");
</script>
