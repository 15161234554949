<template>
  <MDBRow>
    <MDBCol md="7"
      ><MDBCard v-if="!loading">
        <MDBCardBody
          ><div class="fw-bold pb-2">{{ $t("hunter.specification") }}</div>
          <div v-html="hunter.description"></div>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <Register />
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdb-vue-ui-kit";
import Register from "@/components/Hunter/Form/Register";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();

const loading = computed(() => store.getters.getPending);
const hunter = computed(() => store.getters.getSingleHunter);

const fetchSingleHunter = async () => {
  try {
    await store.dispatch("loadHunterById", route.params.id);
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  fetchSingleHunter();
});
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
</style>
