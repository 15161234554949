<template>
  <button
    type="button"
    role="button"
    :class="['button-medium', buttonState.property1, className]"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @click="handleClick"
    :disabled="disableState"
  >
    <div :class="[divClassName]">{{ text }}</div>
  </button>
</template>

<script>
export default {
  name: "ButtonMedium",
  props: {
    property1: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["click", "hover", "default"].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "",
    },
    divClassName: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "Anmelden",
    },
    disableState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttonState: {
        property1: this.property1,
      },
    };
  },
  methods: {
    handleMouseEnter() {
      this.buttonState.property1 = "hover";
    },
    handleMouseLeave() {
      this.buttonState.property1 = "default";
    },
    handleClick() {
      this.buttonState.property1 = "click";
    },
  },
};
</script>

<style lang="scss" scoped>
.button-medium {
  background-size: 100% 100%;
  position: relative;
  .loginBtn {
    font-family: "DIN Pro-Bold", Helvetica;
    font-weight: 700;
    left: 12px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 5px;
  }
  &.hover {
    .loginBtn {
      color: #ffffff;
    }
  }
  &.default {
    .loginBtn {
      color: #ffffff;
    }
  }
  &.click {
    .loginBtn {
      color: #000000;
    }
  }
}
</style>
