<template>
  <div v-if="props.newsFeed" class="main__container">
    <div class="feed_container" :class="{ reverse: props.index % 2 }">
      <div class="image">
        <img :src="props.newsFeed.poster" />
      </div>
      <div
        class="py-2 mt-3 content"
        :class="[{ 'px-3': props.index % 2 }, { 'px-4': !(props.index % 2) }]"
      >
        <div>
          <h4 class="title fw-bold">
            {{ props.newsFeed.title }}
          </h4>
          <h6>{{ props.newsFeed.short_description }}</h6>
        </div>

        <button
          v-if="!showDetails && currentOpenId != props.index"
          type="button"
          class="btn btn-danger text-uppercase mb-2 btn--max"
          @click="openDetail"
        >
          {{ $t("buttons.readNow") }}
        </button>
      </div>
    </div>
    <div
      class="px-4 py-2"
      v-if="showDetails || currentOpenId == index"
      v-html="props.newsFeed.description"
    ></div>
    <div class="px-4 py-1" v-if="showDetails || currentOpenId == index">
      <div
        class="fw-bold"
        v-if="props.newsFeed.action_url && props.newsFeed.action_text"
      >
        {{ $t("newsFeed.more") }}:
      </div>
      <div v-if="props.newsFeed.action_url && props.newsFeed.action_text">
        <a :href="props.newsFeed.action_url" target="_blank">{{
          props.newsFeed.action_text
        }}</a>
      </div>

      <MDBRow>
        <MDBCol col="12">
          <div
            class="pt-2 pb-2 slider-wrap"
            v-if="props.newsFeed.sliders.length > 0"
          >
            <swiper
              class="news-slider"
              :slides-per-view="1"
              :space-between="0"
              :modules="[Navigation, Pagination, Autoplay]"
              loop
              :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
              }"
              :navigation="true"
              :initial-slide="0"
              :pagination="{
                clickable: false,
              }"
            >
              <swiper-slide
                v-for="slide in props.newsFeed.sliders"
                :key="slide.id"
                :class="`swiper-item ${
                  slide.action_url ? 'cursor--pointer' : ''
                }`"
                :style="`background: url(${slide.poster[LANG]}); background-size: contain; background-repeat: no-repeat; width: 100%; background-position: center;`"
                @click="redirect(slide.action_url[LANG])"
              >
                <div class="swiper-content swiper-content--height">&nbsp;</div>
              </swiper-slide>
            </swiper>
          </div>
        </MDBCol>
      </MDBRow>

      <span @click="closeDetail" class="cursor--pointer pb-3">
        <i class="arrow up"></i>
        <a
          ><span class="px-2 text-lowercase">{{ $t("close") }}</span></a
        >
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { MDBCol, MDBRow } from "mdb-vue-ui-kit";
import "swiper/swiper-bundle.css";

import "@/components/General/TextSlider/slider.scss";

const store = useStore();
const LANG = computed(() => localStorage.getItem("lang"));

const showDetails = ref(false);

const props = defineProps({
  newsFeed: Object,
  index: Number,
});

const redirect = (link) => {
  if (!link) return;
  window.open(link, "_blank");
};

const openDetail = () => {
  showDetails.value = !showDetails.value;
  store.dispatch("addCurrentNewsFeed", props.index);
};

const closeDetail = () => {
  showDetails.value = !showDetails.value;
  store.dispatch("addCurrentNewsFeed", null);
};

const currentOpenId = computed(() => store.getters.getCurrentNewsFeed);

watch(currentOpenId, (newVal) => {
  if (newVal != props.index && showDetails.value) {
    showDetails.value = false;
  }
});
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.main__container {
  max-width: 761px;
  background-color: $backgroundWhite;
  margin-bottom: 45px;
}
.feed_container {
  display: flex;
  justify-content: left;
  @media screen and (max-width: $mdBreakpoint) {
    flex-direction: column;
  }
}

.swiper,
.swiper-wrapper {
  height: 100%;
}
.swiper-item {
  height: 100%;
}

.swiper-content--height {
  height: 500px;

  @media screen and (max-width: $mdBreakpoint) {
    height: 250px;
  }
}

.image {
  max-width: 320px;
  width: 100%;
}

.title {
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 4px;
    background: #ed1c24;
    transform: rotate(110deg);
    margin: 7px 0;
  }

  @media screen and (max-width: $mdBreakpoint) {
    font-size: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reverse {
  flex-flow: row-reverse;
}

.arrow {
  border: solid #ec1c24;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.btn--max {
  max-width: 160px;
}
</style>
