import { useStore } from "vuex";
import { useRoute } from "vue-router";

export const getCategoriesData = () => {
  const store = useStore();
  const route = useRoute();
  let currentCategory = null;
  let parentCategory = null;

  if (store && store.getters.getCategories.length) {
    store.getters.getCategories.forEach((category) => {
      category.id === route.params.id ? (currentCategory = category) : null;

      category.children.forEach((childCategory) => {
        if (childCategory.id === route.params.id) {
          currentCategory = childCategory;
          parentCategory = category;
        }
      });
    });
  }

  return { current: currentCategory, parent: parentCategory };
};
