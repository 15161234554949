<template>
  <div>
    <MDBTabs v-model="activeTabId1">
      <!-- Tabs navs -->
      <MDBTabNav>
        <MDBTabItem tabId="ex1-1" href="ex1-1" class="contactTabs text-capitalize">{{ $t('contact.teams.tyre') }}
        </MDBTabItem>
        <MDBTabItem tabId="ex1-2" href="ex1-2" class="contactTabs text-capitalize">{{ $t('contact.teams.car') }}
        </MDBTabItem>
      </MDBTabNav>
      <!-- Tabs navs -->
      <!-- Tabs content -->
      <MDBTabContent>
        <MDBTabPane tabId="ex1-1">
          <MDBRow class="pb-4">
            <MDBCol lg="8" cols="12">
              <div class="bg-white px-4 py-3">
                <h5 class="pb-5">{{ $t("serviceNumber.subtitle") }}</h5>

                <MDBRow>
                  <MDBCol md="6" cols="12">
                    <div class="d-flex flex-column justify-content-end h-100">
                      <div class="fw-bold text-danger text-uppercase">
                        {{ $t("serviceNumber.manager") }}
                      </div>
                      <h5>Harald Schumann</h5>
                      <div>
                        <span class="fw-bold">E-Mail:</span>&nbsp;
                        <a href="mailto:harald.schumann@bridgestone.eu">Harald.Schumann@bridgestone.eu</a>
                      </div>
                      <div>
                        <span class="fw-bold">Mobil:</span>&nbsp;
                        <a href="tel:+49175-9314310">+49 175 - 9314310</a>
                      </div>

                      <div class="d-grid gap-2 pt-4">
                        <a href="mailto:harald.schumann@bridgestone.eu"
                          class="btn btn-danger text-uppercase text-white">{{
                            $t("buttons.makeContact") }}</a>
                      </div>
                    </div>
                  </MDBCol>
                  <MDBCol md="6" cols="12" class="d-flex justify-content-end">
                    <img class="pt-1 cto" src="@/assets/img/contact/sellers_de/harald_schumann.png" alt="" />
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
            <MDBCol lg="4" cols="12">
              <div class="bg-white px-3 py-3">
                <h5 class="pb-4">{{ $t("serviceNumber.generalCustomerService") }}</h5>
                <div class="pb-3">
                  <div class="fw-bold text-danger text-uppercase">
                    {{ $t("serviceNumber.salesTerritory") }}
                    {{ $t("directions.west") }}
                  </div>
                  <span class="fw-bold">Tel.</span>&nbsp;0800 0266 - 300
                </div>
                <div class="pb-3">
                  <div class="fw-bold text-danger text-uppercase">
                    {{ $t("serviceNumber.salesTerritory") }}
                    {{ $t("directions.south") }}
                  </div>
                  <span class="fw-bold">Tel.</span>&nbsp;0800 0266 - 001
                </div>
                <div class="pb-3">
                  <div class="fw-bold text-danger text-uppercase">
                    {{ $t("serviceNumber.salesTerritory") }}
                    {{ $t("directions.north") }}-{{ $t("directions.east") }}
                  </div>
                  <span class="fw-bold">Tel.</span>&nbsp;0800 0266 - 002
                </div>
                <div class="pb-3">
                  <div class="fw-bold text-danger text-uppercase">
                    {{ $t("serviceNumber.carDealer") }}
                  </div>
                  <span class="fw-bold">Tel.</span>&nbsp;0800 0266 - 003
                </div>
                <div class="py-4">
                  <div class="fw-bold text-danger text-uppercase">
                    {{ $t("serviceNumber.centralEmail") }}
                  </div>
                  <span class="fw-bold">E-Mail</span>&nbsp;<a
                    href="mailto:customerservice@bridgestone.eu">customerservice@bridgestone.eu</a>
                </div>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol lg="4" class="mb-4" v-for="(data, i) in salesmenDataDe" :key="i">
              <Card :data="data" :linkText="data.linkText" :simpleLink="true" target="_blank" />
            </MDBCol>
          </MDBRow>
        </MDBTabPane>
        <MDBTabPane tabId="ex1-2">
          <MDBRow class="pb-4">
            <MDBCol cols="12">
              <div class="bg-white px-4 py-3">
                <h5 class="pb-2">{{ $t("contact.carDealers.title") }}<br /> TEAM AUTOHAUS</h5>
                <MDBRow>
                  <MDBCol lg="4" class="mb-4" v-for="(data, i) in mainCarSales" :key="i">
                    <Card :data="data" :linkText="data.linkText" :simpleLink="true" target="_blank" />
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol lg="4" class="mb-4" v-for="(data, i) in carSaleStaff" :key="i">
              <Card :data="data" :linkText="data.linkText" :simpleLink="true" target="_blank" />
            </MDBCol>
          </MDBRow>
        </MDBTabPane>
      </MDBTabContent>
      <!-- Tabs content -->
    </MDBTabs>
  </div>
</template>

<script setup>
import { MDBRow, MDBCol, MDBTabs, MDBTabNav, MDBTabItem, MDBTabContent, MDBTabPane } from "mdb-vue-ui-kit";
import Card from "@/components/General/Card/index.vue";
import i18n from "@/plugins/i18n";
import { ref } from 'vue';

const activeTabId1 = ref('ex1-1');

const salesmenDataDe = [
  {
    name: "Dirk Lehmann",
    link: "mailto:Dirk.Lehmann@bridgestone.eu",
    description: "<b>E-Mail:</b> Dirk.Lehmann@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314221",
    image: require("@/assets/img/contact/sellers_de/dirk_lehmann.jpg"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Torsten Främk",
    link: "mailto:Torsten.Fraemk@bridgestone.eu",
    description: "<b>E-Mail:</b> Torsten.Fraemk@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314220",
    image: require("@/assets/img/contact/sellers_de/torsten_frank.jpg"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Jörn Linne",
    link: "mailto:Joern.Linne@bridgestone.eu",
    description: "<b>E-Mail:</b> Joern.Linne@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314299",
    image: require("@/assets/img/contact/sellers_de/jorn_linne.jpg"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Jörg Heinold",
    link: "mailto:Joerg.Heinold@bridgestone.eu",
    description: "<b>E-Mail:</b> Joerg.Heinold@bridgestone.eu<br/><b>Mobil:</b> +49 172 - 4913535",
    image: require("@/assets/img/contact/sellers_de/jorg_reinhold.jpg"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Thomas Rieger",
    link: "mailto:Thomas.Rieger@bridgestone.eu",
    description: "<b>E-Mail:</b> Thomas.Rieger@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314192",
    image: require("@/assets/img/contact/sellers_de/thomas_rieger.jpg"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Joachim Gauster",
    link: "mailto:Joachim.Gauster@bridgestone.eu",
    description: "<b>E-Mail:</b> Joachim.Gauster@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314131",
    image: require("@/assets/img/contact/sellers_de/joachim_gauster.jpg"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Thomas Mohr",
    link: "mailto:Thomas.Mohr@bridgestone.eu",
    description: "<b>E-Mail:</b> Thomas.Mohr@bridgestone.eu<br/><b>Mobil:</b> +49 172 - 4913407",
    image: require("@/assets/img/contact/sellers_de/thomas_mohr.jpg"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Sven Gottwald",
    link: "mailto:Sven.Gottwald@bridgestone.eu",
    description: "<b>E-Mail:</b> Sven.Gottwald@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314324",
    image: require("@/assets/img/contact/sellers_de/sven_gottwald.jpg"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Stella Schaller",
    link: "mailto:stella.schaller@bridgestone.eu",
    description: "<b>E-Mail:</b> Stella.Schaller@bridgestone.eu<br/><b>Mobil:</b> +49 1525 2328654",
    image: require("@/assets/img/contact/sellers_de/stella_schaller.jpg"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Lars Skibba",
    link: "mailto:Lars.Skibba@bridgestone.eu",
    description: "<b>E-Mail:</b> Lars.Skibba@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314159",
    image: require("@/assets/img/contact/sellers_de/lars_skibba.jpg"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Josef Bigelmaier",
    link: "mailto:Josef.Bigelmaier@bridgestone.eu",
    description: "<b>E-Mail:</b> Josef.Bigelmaier@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314283",
    image: require("@/assets/img/contact/sellers_de/josef_bigelmaier.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
];

const mainCarSales = [
  {
    name: `<div class="font-12 font-xxl-16 text-danger text-uppercase">${i18n.global.t(
      "serviceNumber.manager"
    )}</div>Jens Hopp`,
    link: "mailto:Jens.Hopp@bridgestone.eu",
    description: "<b>E-Mail:</b> Jens.Hopp@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314 141",
    image: require("@/assets/img/contact/sellers_de/car/jens_hopp.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },

  {
    name: `<div class="font-12 font-xxl-16 text-danger text-uppercase">KEY ACCOUNT MANAGER DEUTSCHLAND</div>Marc Löchtermann`,
    link: "mailto:Marc.Loechtermann@bridgestone.eu",
    description: "<b>E-Mail:</b> Marc.Loechtermann@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314 320",
    image: require("@/assets/img/contact/sellers_de/car/marc_lochtermann.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
]

const carSaleStaff = [
  {
    name: "Claus Neuhaus",
    link: "mailto:Claus.Neuhausn@bridgestone.eu",
    description: "<b>E-Mail:</b> Claus.Neuhaus@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314194",
    image: require("@/assets/img/contact/sellers_de/car/claus_neuhaus.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Thomas Fonk",
    link: "mailto:Thomas.Fonk@bridgestone.eu",
    description: "<b>E-Mail:</b> Thomas.Fonk@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314256",
    image: require("@/assets/img/contact/sellers_de/car/thomas_fonk.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Günther Kolvenbach",
    link: "mailto:Guenther.Kolvenbach@bridgestone.eu",
    description: "<b>E-Mail:</b> Guenther.Kolvenbach@bridgestone.eu<br/><b>Mobil:</b> +49 172 - 4913549",
    image: require("@/assets/img/contact/sellers_de/car/gunther_kolvenbach.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Christian Fritz",
    link: "mailto:Christian.Fritz@bridgestone.eu",
    description: "<b>E-Mail:</b> Christian.Fritz@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314149",
    image: require("@/assets/img/contact/sellers_de/car/christian_fritz.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Jürgen Heberle",
    link: "mailto:Juergen.Heberle@bridgestone.eu",
    description: "<b>E-Mail:</b> Juergen.Heberle@bridgestone.eu<br/><b>Mobil:</b> +49 175 - 9314148",
    image: require("@/assets/img/contact/sellers_de/car/jurgen_heberle.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Moritz-Alexander Jacobs",
    link: "mailto:Moritz.Jacobs@bridgestone.eu",
    description: "<b>E-Mail:</b> Moritz.Jacobs@bridgestone.eu<br/><b>Mobil:</b> +49 172 - 9842303",
    image: require("@/assets/img/contact/sellers_de/car/moritz_alexander_jacobs.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Anne Dehnert",
    link: "mailto:anne.dehnert@bridgestone.eu",
    description: "<b>E-Mail:</b> Anne.Dehnert@bridgestone.eu<br/><b>Mobil:</b> +49 175 931 41 42",
    image: require("@/assets/img/contact/sellers_de/car/anne_dehnert.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Daniel Günther",
    link: "mailto:Daniel.Guenther@bridgestone.eu",
    description: "<b>E-Mail:</b> Daniel.Guenther@bridgestone.eu<br/><b>Mobil:</b> +49 172 - 4913572",
    image: require("@/assets/img/contact/sellers_de/car/daniel_gunther.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Ralf Thomä",
    link: "mailto:ralf.thomae@bridgestone.eu",
    description: "<b>E-Mail:</b> Ralf.Thomae@bridgestone.eu<br/><b>Mobil:</b> +49 175 9314174",
    image: require("@/assets/img/contact/sellers_de/car/ralf_thoma.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: "Florian Gramüller",
    link: "mailto:Florian.Gramueller@bridgestone.eu",
    description: "<b>E-Mail:</b> Florian.Gramueller@bridgestone.eu<br/><b>Mobil:</b> +49 172 - 9842322",
    image: require("@/assets/img/contact/sellers_de/car/florian_gramuller.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
];
</script>

<style lang="scss">
@import "@/styles/contact";
</style>
