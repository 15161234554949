<template>
  <Loader :show="loading" v-if="loading"></Loader>
  <div v-else-if="isFirstStopGroup">
    <CategoryTop
      :title="$t('pageName.first_stop')"
      :description="$t('firstStop.description')"
      :breadcrumbs-parent-routes="['about']"
    />
    <MDBRow class="py-3"
      ><MDBCol col="12">
        <div>
          <img src="@/assets/img/24m-garantie-banner-nl.jpg" alt="logo" />
        </div>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol col="12"
        ><h4>
          <span class="text-danger">/</span>
          {{ $t("firstStop.section1.title") }}
        </h4></MDBCol
      >
      <MDBCol col="12"
        ><div class="pb-4">
          {{ $t("firstStop.section1.description") }}
        </div></MDBCol
      >
    </MDBRow>
    <MDBRow>
      <FileItem v-for="(file, i) in sectionOneFiles" :key="i" :file="file" />
    </MDBRow>
    <MDBRow>
      <MDBCol col="12"
        ><h4>
          <span class="text-danger">/</span>
          {{ $t("firstStop.section2.title") }}
        </h4></MDBCol
      >
      <MDBCol col="12"
        ><div class="pb-2">
          {{ $t("firstStop.section2.description") }}
        </div>
        <div class="pb-4">
          {{ $t("firstStop.section2.footer") }}
        </div></MDBCol
      >
    </MDBRow>
    <MDBRow>
      <FileItem v-for="(file, i) in sectionTwoFiles" :key="i" :file="file" />
    </MDBRow>
  </div>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import FileItem from "@/components/ProductInfo/FileItem.vue";
import Loader from "@/components/General/Loader/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import i18n from "@/plugins/i18n";

const sectionOneFiles = computed(() => {
  return [
    {
      file: "/files/first_stop/Claims Form First Stop Bandenwaarborg Nederland.pdf",
      name: "Algemene voorwaarden en aangifteformulier",
      description: "",
      date: null,
      type: "pdf",
      simpleLink: false,
    },
  ];
});

const sectionTwoFiles = computed(() => {
  return [
    {
      file: "/files/first_stop/Procedure garantie vanaf 01 maart 2024.pdf",
      name: "Procedure garantie vanaf 1 maart 2024",
      description: "",
      date: null,
      type: "pdf",
      simpleLink: false,
    },
    {
      file: "/files/first_stop/Algemene voorwaarden vanaf 1 maart 2024.pdf",
      name: "Algemene voorwaarden<br/>vanaf 1 maart 2024",
      description: "",
      date: null,
      type: "pdf",
      simpleLink: false,
    },
    {
      file: "/files/first_stop/Claimformulier vanaf 1 maart 2024.pdf",
      name: "Claimformulier<br/>vanaf 1 maart 2024",
      description: "",
      date: null,
      type: "pdf",
      simpleLink: false,
    },
  ];
});
const loading = computed(() => store.getters.getPending);
const router = useRouter();

const store = useStore();

const groups = computed(() => store.getters.getGroups);
const profile = computed(() => store.getters.getProfile);
const isFirstStopGroup = ref(false);

onMounted(async () => {
  await store.dispatch("fetchProfile");
  await store.dispatch("loadGroups");

  const firstStopId = groups.value.filter((item) => {
    return item.gka === "FSP";
  })[0].id;

  if (firstStopId === profile.value.group_id) {
    isFirstStopGroup.value = true;
  } else {
    toast(i18n.global.t("toastMessages.accessDenied"), {
      type: "error",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    router.push({
      name: "about",
    });
  }
});
</script>
